import styled from "styled-components";

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;
