import styled from "styled-components";

export const ShippingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  height: auto;
  border-radius: 10px;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    width: 95vw;
    padding: 1vh 4vw 2vh 4vw;
    margin-bottom: calc(2vh + 20vh);
  }
`;

export const ShippingTitle = styled.div`
  font-weight: 600;
  font-size: clamp(16px, 1vw, 20px);
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 480px) {
    padding: 1vh 0;
  }
`;

export const ShippingForm = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 30px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    row-gap: 1.5vh;
  }
`;

export const InputLabel = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

export const ShippingInput = styled.input`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 6vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 10px;

  @media (max-width: 480px) {
    width: 100%;
    font-size: 14px;
    height: 5vh;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-evenly;
  width: 95%;
  height: 25vh;
  margin-top: 5vh;

  @media (max-width: 480px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 20vh;
    padding: 1vh 8vw 0 8vw;
    row-gap: 0.5vh;
    background-color: white;
    box-shadow: 0 -6px 6px -1px rgba(0, 0, 0, 0.2),
      0 -3px 4px -1px rgba(0, 0, 0, 0.1);
  }
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20vw;

  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);

  @media (max-width: 480px) {
    width: 100%;
    font-size: 15px;
  }
`;

export const InfoData = styled.text`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

export const InfoWallet = styled.text`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);

  @media (max-width: 480px) {
    font-weight: 600;
    font-size: 15px;
  }
`;

export const InfoTotal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20vw;

  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  border-top: 2px solid #eeeeee;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const TotalData = styled.text`
  font-weight: 600;
  font-size: clamp(18px, 1.2vw, 22px);
  color: #a41e4d;
`;

export const CartMainButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: clamp(14px, 1vw, 18px);
  width: 11vw;
  height: 5vh;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #a41e4d;
  cursor: pointer;
  margin: 20px 0;

  @media (max-width: 480px) {
    font-size: 16px;
    width: 40vw;
    margin: 1vh 0 2vh 0;
  }
`;
