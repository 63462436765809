import { CustomIconProps } from "./types";

export const KYCIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 141 141" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.4987 105.709C72.4938 105.709 74.1662 105.034 75.5159 103.684C76.8655 102.334 77.5404 100.662 77.5404 98.6668C77.5404 96.6717 76.8655 94.9993 75.5159 93.6497C74.1662 92.3 72.4938 91.6252 70.4987 91.6252C68.5036 91.6252 66.8312 92.3 65.4815 93.6497C64.1319 94.9993 63.457 96.6717 63.457 98.6668C63.457 100.662 64.1319 102.334 65.4815 103.684C66.8312 105.034 68.5036 105.709 70.4987 105.709ZM63.457 77.5418H77.5404V35.2918H63.457V77.5418ZM70.4987 140.917C60.7577 140.917 51.6036 139.068 43.0362 135.372C34.4688 131.675 27.0164 126.657 20.6789 120.32C14.3414 113.982 9.32422 106.53 5.62734 97.9627C1.93047 89.3953 0.0820312 80.2411 0.0820312 70.5002C0.0820312 60.7592 1.93047 51.605 5.62734 43.0377C9.32422 34.4703 14.3414 27.0179 20.6789 20.6804C27.0164 14.3429 34.4688 9.32568 43.0362 5.62881C51.6036 1.93193 60.7577 0.0834961 70.4987 0.0834961C80.2397 0.0834961 89.3938 1.93193 97.9612 5.62881C106.529 9.32568 113.981 14.3429 120.318 20.6804C126.656 27.0179 131.673 34.4703 135.37 43.0377C139.067 51.605 140.915 60.7592 140.915 70.5002C140.915 80.2411 139.067 89.3953 135.37 97.9627C131.673 106.53 126.656 113.982 120.318 120.32C113.981 126.657 106.529 131.675 97.9612 135.372C89.3938 139.068 80.2397 140.917 70.4987 140.917Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
