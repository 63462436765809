import { useState } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  CloseButton,
  InputWrap,
  ModalLabel,
  TitleStar,
  ModalInput,
  SubText,
  MainButton,
  NoteWrap,
  NoteHeader,
  NoteList,
  NotePoints,
} from "../reusable/modalStyle";
import Icon from "../../core/Icons";
import styled from "styled-components";
import ShowPassword from "../reusable/showPassword";

const ConversionAmountContainer = styled.div`
  width: 100%;
  height: 5.5vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin: 2px 0;

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const ConversionInputWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConversionOrigins = styled.div`
  width: 84%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

const ConversionButtons = styled.button`
  background: none;
  border: none;
  width: 4vw;
  height: 5.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const MathIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2vw;
  height: auto;

  @media (max-width: 480px) {
    width: 15px;
  }
`;

const ConversionInput = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  text-align: center;
  background: none;
  border: none;
  outline: none;
  width: 80%;
  height: 5.5vh;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

type ConversionProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function ConversionModal({
  isVisible,
  onClose,
}: ConversionProps) {
  const [amount, setAmount] = useState(1);
  const [inputValue, setInputValue] = useState("1");

  const handleIncrease = () => {
    const newAmount = amount + 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString());
  };

  const handleDecrease = () => {
    const newAmount = amount > 1 ? amount - 1 : 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 1) {
      setAmount(numericValue);
      setInputValue(value);
    } else {
      setInputValue(value);
    }
  };

  const handleFocus = () => {
    setInputValue("");
  };

  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Convert</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <ConversionOrigins>
          <InputWrap>
            <ModalLabel>
              From
              <TitleStar>*</TitleStar>
            </ModalLabel>
            <ModalInput type="text" placeholder="Bonus Wallet" />
          </InputWrap>
          <InputWrap>
            <ModalLabel>
              To
              <TitleStar>*</TitleStar>
            </ModalLabel>
            <ModalInput type="text" placeholder="Register Wallet" />
          </InputWrap>
        </ConversionOrigins>

        <InputWrap>
          <ModalLabel>
            Conversion Amount (USD)
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ConversionAmountContainer>
            <ConversionInputWrap>
              <ConversionButtons onClick={handleDecrease}>
                <MathIcon>
                  <Icon name="minus" fill="" />
                </MathIcon>
              </ConversionButtons>
              <ConversionInput
                type="text"
                value={inputValue}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <ConversionButtons onClick={handleIncrease}>
                <MathIcon>
                  <Icon name="plus" fill="" />
                </MathIcon>
              </ConversionButtons>
            </ConversionInputWrap>
          </ConversionAmountContainer>
          <SubText>
            Wallet Balance: USD
            <SubText>4.8</SubText>
          </SubText>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Remarks
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ModalInput type="text" placeholder="Remarks" />
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Security Password
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ShowPassword placeholder="Security Password" type="security" />
        </InputWrap>

        <NoteWrap>
          <NoteHeader>Note:</NoteHeader>
          <NoteList>
            <NotePoints>Minimum amount for conversion is 10 USD.</NotePoints>
            <NotePoints>Maximum amount for conversion is -1 USD.</NotePoints>
          </NoteList>
        </NoteWrap>

        <MainButton>Next</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
