import NavBar from "../../components/nav/navBar";
import {
  LeftSection,
  LoginContainer,
  MainText,
  PageBody,
  RightSection,
  InputBox,
  GreetText,
  MainButton,
  InputWrap,
  ButtonWrap,
  RegisterLink,
  ResetButton,
  SubWrap,
  RememberCheckbox,
  PasswordText,
  UserText,
  AlignInput,
  CheckboxWrap,
  HiddenCheckbox,
  RememberLabel,
} from "./loginStyle";
import FooterBar from "../../components/footer/footer";

export default function LoginPage() {
  return (
    <PageBody>
      <NavBar />

      <LoginContainer>
        <LeftSection>
          <MainText>Login</MainText>
          <GreetText>Welcome Back, please login to continue.</GreetText>
        </LeftSection>
        <RightSection>
          <AlignInput>
            <InputWrap>
              <UserText>Username *</UserText>
              <InputBox type="text" placeholder="Username" />
            </InputWrap>

            <InputWrap>
              <SubWrap>
                <PasswordText>Password *</PasswordText>
                <ResetButton to="/reset">Forgot Password</ResetButton>
              </SubWrap>

              <InputBox type="password" placeholder="Password" />
            </InputWrap>

            <CheckboxWrap>
              <HiddenCheckbox />
              <RememberCheckbox />
              <RememberLabel>Remember Me</RememberLabel>
            </CheckboxWrap>
          </AlignInput>

          <ButtonWrap>
            <MainButton>Login</MainButton>
            <RegisterLink to="/register">Create Account</RegisterLink>
          </ButtonWrap>
        </RightSection>
      </LoginContainer>

      <FooterBar />
    </PageBody>
  );
}
