import { CustomIconProps } from "./types";

export const BorderListIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 29.75C14.4958 29.75 14.9115 29.5823 15.2469 29.2469C15.5823 28.9115 15.75 28.4958 15.75 28C15.75 27.5042 15.5823 27.0885 15.2469 26.7531C14.9115 26.4177 14.4958 26.25 14 26.25C13.5042 26.25 13.0885 26.4177 12.7531 26.7531C12.4177 27.0885 12.25 27.5042 12.25 28C12.25 28.4958 12.4177 28.9115 12.7531 29.2469C13.0885 29.5823 13.5042 29.75 14 29.75ZM14 22.75C14.4958 22.75 14.9115 22.5823 15.2469 22.2469C15.5823 21.9115 15.75 21.4958 15.75 21C15.75 20.5042 15.5823 20.0885 15.2469 19.7531C14.9115 19.4177 14.4958 19.25 14 19.25C13.5042 19.25 13.0885 19.4177 12.7531 19.7531C12.4177 20.0885 12.25 20.5042 12.25 21C12.25 21.4958 12.4177 21.9115 12.7531 22.2469C13.0885 22.5823 13.5042 22.75 14 22.75ZM14 15.75C14.4958 15.75 14.9115 15.5823 15.2469 15.2469C15.5823 14.9115 15.75 14.4958 15.75 14C15.75 13.5042 15.5823 13.0885 15.2469 12.7531C14.9115 12.4177 14.4958 12.25 14 12.25C13.5042 12.25 13.0885 12.4177 12.7531 12.7531C12.4177 13.0885 12.25 13.5042 12.25 14C12.25 14.4958 12.4177 14.9115 12.7531 15.2469C13.0885 15.5823 13.5042 15.75 14 15.75ZM19.25 29.75H29.75V26.25H19.25V29.75ZM19.25 22.75H29.75V19.25H19.25V22.75ZM19.25 15.75H29.75V12.25H19.25V15.75ZM8.75 36.75C7.7875 36.75 6.96354 36.4073 6.27813 35.7219C5.59271 35.0365 5.25 34.2125 5.25 33.25V8.75C5.25 7.7875 5.59271 6.96354 6.27813 6.27813C6.96354 5.59271 7.7875 5.25 8.75 5.25H33.25C34.2125 5.25 35.0365 5.59271 35.7219 6.27813C36.4073 6.96354 36.75 7.7875 36.75 8.75V33.25C36.75 34.2125 36.4073 35.0365 35.7219 35.7219C35.0365 36.4073 34.2125 36.75 33.25 36.75H8.75ZM8.75 33.25H33.25V8.75H8.75V33.25Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
