import styled from "styled-components";

interface ComponentButtonProps {
  isActive?: boolean;
}

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 9vh;
  gap: 10vw;
`;

export const IconWrap = styled.div`
  width: 6.5vw;
  height: auto;
  margin-right: 8px;
`;

export const ComponentButton = styled.button<ComponentButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ isActive }) => (isActive ? "#A41E4D" : "white")};
  color: ${({ isActive }) => (isActive ? "white" : "black")};
  border: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 36vw;
  height: 5vh;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;

  ${IconWrap} {
    fill: ${({ isActive }) => (isActive ? "white" : "black")};
  }
`;
