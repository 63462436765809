import styled from "styled-components";

export const BannerContainer = styled.div`
  width: 75vw;
  height: 6vh;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: "Inter", sans-serif;

  @media (max-width: 768px) {
    height: 90px;
  }

  @media (max-width: 480px) {
    height: 130px;
  }
`;

export const AlignContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;

  @media (max-width: 480px) {
    height: 60%;
    align-items: end;
    justify-content: center;
  }
`;

export const IconWrap = styled.div`
  width: 1.8vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 480px) {
    width: 30px;
    margin-bottom: -6px;
    position: absolute;
    right: 10%;
  }
`;
