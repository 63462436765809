import { useState, useRef } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  CloseButton,
  InputWrap,
  ModalLabel,
  TitleStar,
  ModalInput,
  SubText,
  FileInput,
  FileWrap,
  CloudIcon,
  FileButton,
  SelectedFileName,
  MainButton,
  NoteHeader,
  NoteList,
  NotePoints,
  FileDescription,
} from "../reusable/modalStyle";
import Icon from "../../core/Icons";
import styled from "styled-components";
import ShowPassword from "../reusable/showPassword";

const DepositNoteWrap = styled.div`
  padding: 1vh 0;
  width: 80%;
`;

const CalendarWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CalendarInput = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9vw;
  width: 100%;
  height: 5vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 10px;
  margin-top: 2px;
  padding-right: 10px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

type DepositProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function DepositModal({ isVisible, onClose }: DepositProps) {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0].name);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0].name);
    }
  };

  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Convert</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <InputWrap>
          <ModalLabel>
            Deposit To
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ModalInput type="text" placeholder="User Username" />
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Deposit Amount (USD)
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ModalInput type="text" placeholder="Deposit Amount (USD)" />
          <SubText>USD 1 = MYR 4.5</SubText>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Payment Date
            <TitleStar>*</TitleStar>
            <CalendarWrap>
              <CalendarInput
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                placeholder="yyyy/mm/dd"
              />
            </CalendarWrap>
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Referrence ID
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ModalInput type="text" placeholder="Referrence ID" />
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Upload payment slip
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <FileInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
          />
          <FileWrap onDragOver={handleDragOver} onDrop={handleDrop}>
            <CloudIcon>
              <Icon name="cloud" fill="#737373" />
            </CloudIcon>
            <FileDescription>
              Drag and drop here or
              <FileButton onClick={() => fileInputRef.current?.click()}>
                Browse
              </FileButton>
            </FileDescription>
            {selectedFile && (
              <SelectedFileName>{selectedFile}</SelectedFileName>
            )}
          </FileWrap>
          <FileDescription>
            PDF, JPG, and PNG format only (max, 4mb)
          </FileDescription>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Security Password
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ShowPassword placeholder="Security Password" type="security" />
        </InputWrap>

        <DepositNoteWrap>
          <NoteHeader>Note:</NoteHeader>
          <NoteList>
            <NotePoints>
              Please allow 1-3 working days for processing.
            </NotePoints>
            <NotePoints>
              Please make sure you have deposited to the correct bank, with the
              accurate account number and account holder entered.
            </NotePoints>
            <NotePoints>Minimum USD 100, Maximum USD 2000.</NotePoints>
          </NoteList>
        </DepositNoteWrap>

        <MainButton>Submit</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
