import React from "react";
import styled from "styled-components";
import { IconType } from "../../core/Icons/types";
import Icon from "../../core/Icons";
import { useNavigate } from "react-router-dom";

const MobileNavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  padding: 10px 15px 15px 15px;
  box-shadow: 0 -6px 6px -1px rgba(0, 0, 0, 0.2),
    0 -3px 4px -1px rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
`;

const NavItem = styled.button<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => (props.isActive ? "#a41e4d" : "black")};
  font-size: 12px;
`;

const IconWrap = styled.div`
  width: 40px;
  height: auto;
`;

const NavTitle = styled.text`
  font-weight: 400;
  font-size: 14px;
`;

type MobileNavigationProps = {
  activeSection: string;
  onLinkClick: (linkName: string) => void;
};

const mobileLinks: { name: string; icon: IconType; redirectTo: string }[] = [
  { name: "Home", icon: "grid", redirectTo: "/home" },
  { name: "Order", icon: "borderlist", redirectTo: "/mobileOrder" },
  { name: "Product", icon: "box", redirectTo: "/mobileProduct" },
  { name: "Package", icon: "crown", redirectTo: "/mobilePackage" },
  { name: "Profile", icon: "profile", redirectTo: "/mobileProfile" },
];

export default function MobileNavigation({
  activeSection,
  onLinkClick,
}: MobileNavigationProps) {
  const navigate = useNavigate();

  const handleNavClick = (link: (typeof mobileLinks)[0]) => {
    onLinkClick(link.name);
    navigate(link.redirectTo);
  };

  return (
    <MobileNavContainer>
      {mobileLinks.map((link) => (
        <NavItem
          key={link.name}
          isActive={activeSection === link.name}
          onClick={() => handleNavClick(link)}
        >
          <IconWrap>
            <Icon
              name={link.icon}
              fill={activeSection === link.name ? "#a41e4d" : "black"}
            />
          </IconWrap>
          <NavTitle>{link.name}</NavTitle>
        </NavItem>
      ))}
    </MobileNavContainer>
  );
}
