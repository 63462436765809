import styled from "styled-components";

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;

export const MobileOrderContainer = styled.div`
  width: 95%;
`;

export const OrderProductWrap = styled.div`
  width: 100%;
  height: auto;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  border-radius: 10px;
  background-color: white;
`;

export const OrderProductHeader = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OrderButtons = styled.button`
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: none;
  position: relative;
  cursor: pointer;
`;

export const SubOrderButtons = styled.button`
  width: 90%;
  height: auto;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: none;
  position: relative;
  cursor: pointer;
`;

export const ButtonLabel = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px;
`;

export const SubButtonLabel = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
`;

export const OrderIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vw;
  height: auto;
`;

export const ButtonArrowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5%;
  width: 3.5vw;
  height: auto;
`;
