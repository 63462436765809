import styled from "styled-components";

export const SideContainer = styled.div`
  width: 17vw;
  height: 85vh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: "Inter", sans-serif;
`;

export const StyledLogo = styled.div`
  width: 100%;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlignSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
`;

export const IconWrap = styled.span`
  width: clamp(20px, 1.4vw, 25px);
  height: auto;
  margin-right: 1vw;
`;

export const ExtraIconWrap = styled(IconWrap)<{ isOpen?: boolean }>`
  position: absolute;
  left: 14%;
  width: 1vw;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;
`;

export const LinksBox = styled.div`
  margin-top: 1vw;
  width: 100%;
  padding: 0 1vw;
`;

export const LinkContent = styled.div``;

export const SideLinks = styled.button<{
  isActive?: boolean;
  isDropdownLink?: boolean;
}>`
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 2.5vw;
  border-radius: 10px;
  font-weight: 400;
  font-size: clamp(15px, 1vw, 17px);
  width: 100%;
  height: 6.2vh;
  background-color: white;
  color: black;
  fill: black;
  border: none;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? "#A41E4D" : "white")};
  color: ${({ isActive }) => (isActive ? "white" : "black")};
  ${IconWrap} {
    fill: ${({ isActive }) => (isActive ? "white" : "black")};
  }

  &:hover {
    background-color: #edc5d3;
    color: black;

    ${IconWrap} {
      fill: black;
    }
  }
`;

export const DropdownMenu = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  flex-direction: column;
`;

export const OptionText = styled.div`
  text-align: left;
  width: 80%;
`;

export const LogOut = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;
  margin: 0 auto;
  padding-left: 2.5vw;
  border-radius: 12px;
  font-weight: 400;
  font-size: clamp(15px, 1vw, 17px);
  width: 15vw;
  height: 6vh;
  margin-top: auto;
  cursor: pointer;

  &:hover {
    background-color: #edc5d3;
    color: black;
  }
`;

export const DropdownItem = styled.button<{
  isActive?: boolean;
  selected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  height: 5.2vh;
  width: 10vw;
  border: none;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? "#A41E4D" : "white")};
  color: ${({ isActive }) => (isActive ? "white" : "black")};
  ${IconWrap} {
    fill: ${({ isActive }) => (isActive ? "white" : "black")};
  }

  &:hover {
    background-color: #edc5d3;
    color: black;
    ${IconWrap} {
      fill: black;
    }
  }

  &:focus {
    outline: none;
    background-color: #a41e4d;
    color: white;
  }
`;
