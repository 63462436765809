import { useState, useRef } from "react";
import Icon from "../../core/Icons";
import {
  CloseButton,
  InputWrap,
  MainButton,
  ModalContent,
  ModalHeader,
  ModalInput,
  ModalLabel,
  ModalOverlay,
  ModalTitle,
  TitleStar,
  FileInput,
  FileWrap,
  CloudIcon,
  FileButton,
  SelectedFileName,
  FileDescription,
} from "../reusable/modalStyle";
import DropdownMenu from "../reusable/dropdown";

const documentOptions = ["passport", "IC", "Birth Certificate"];

type KycProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function KycModal({ isVisible, onClose }: KycProps) {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0].name);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0].name);
    }
  };

  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Identity Verification (KYC)</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <InputWrap>
          <ModalLabel>
            Full Name
            <TitleStar>*</TitleStar>
            <ModalInput type="text" placeholder="Enter Full Name" />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Document Type
            <TitleStar>*</TitleStar>
            <DropdownMenu
              placeholder="Select Document Type"
              options={documentOptions}
            />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            National ID
            <TitleStar>*</TitleStar>
            <ModalInput type="text" placeholder="Enter National ID" />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Front Side of ID
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <FileInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
          />
          <FileWrap onDragOver={handleDragOver} onDrop={handleDrop}>
            <CloudIcon>
              <Icon name="cloud" fill="#737373" />
            </CloudIcon>
            <FileDescription>
              Drag and drop here or
              <FileButton onClick={() => fileInputRef.current?.click()}>
                Browse
              </FileButton>
            </FileDescription>
            {selectedFile && (
              <SelectedFileName>{selectedFile}</SelectedFileName>
            )}
          </FileWrap>
          <FileDescription>
            PDF, JPG, and PNG format only (max, 4mb)
          </FileDescription>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Back Side of ID
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <FileInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
          />
          <FileWrap onDragOver={handleDragOver} onDrop={handleDrop}>
            <CloudIcon>
              <Icon name="cloud" fill="#737373" />
            </CloudIcon>
            <FileDescription>
              Drag and drop here or
              <FileButton onClick={() => fileInputRef.current?.click()}>
                Browse
              </FileButton>
            </FileDescription>
            {selectedFile && (
              <SelectedFileName>{selectedFile}</SelectedFileName>
            )}
          </FileWrap>
          <FileDescription>
            PDF, JPG, and PNG format only (max, 4mb)
          </FileDescription>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Upload photo of your selfie while holding your ID
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <FileInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
          />
          <FileWrap onDragOver={handleDragOver} onDrop={handleDrop}>
            <CloudIcon>
              <Icon name="cloud" fill="#737373" />
            </CloudIcon>
            <FileDescription>
              Drag and drop here or
              <FileButton onClick={() => fileInputRef.current?.click()}>
                Browse
              </FileButton>
            </FileDescription>
            {selectedFile && (
              <SelectedFileName>{selectedFile}</SelectedFileName>
            )}
          </FileWrap>
          <FileDescription>
            PDF, JPG, and PNG format only (max, 4mb)
          </FileDescription>
        </InputWrap>

        <MainButton>Submit</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
