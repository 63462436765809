import styled from "styled-components";

export const RegisterGenealogyPage = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 2vh 2vw 4vh 2vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vh 0 2vh 0;
    padding: 1vh 4vw 2vh 4vw;
    width: 100%;
  }
`;

export const BackButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const BackIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  height: auto;
`;

export const BackButtonText = styled.text`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  padding: 0 5px;
`;

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 0;

  @media (max-width: 480px) {
    width: 90%;
    padding: 1.5vh 0 2vh 0;
  }
`;

export const TitleAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  width: 2.4vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 4px;
  }
`;

export const HeaderTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  padding: 0 5px;
`;

export const RegisterDetailPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding-top: 4vh;

  @media (max-width: 480px) {
    padding: 1vh 0 0 0;
  }
`;

export const TitleStar = styled.text`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 16px);
  color: red;
  margin-left: 5px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const RegisterNameWrap = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

export const RegisterName = styled.input`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 60px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 10px;

  @media (max-width: 480px) {
    font-size: 14px;
    height: 5vh;
  }
`;

export const RegisterGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px 40px;
  padding: 2vh 0;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh 0;
    padding: 1vh 0;
  }
`;

export const RegisterLabel = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);

  @media (max-width: 480px) {
    width: 100%;
    font-size: 15px;
  }
`;

export const RegisterInput = styled.input`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 60px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 10px;

  @media (max-width: 480px) {
    font-size: 14px;
    height: 5vh;
  }
`;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #eeeeee;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const MobileInput = styled.input`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 60px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;

  @media (max-width: 480px) {
    font-size: 14px;
    height: 5vh;
  }
`;

export const CountryCodeWrap = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const DropdownIcon = styled.div`
  width: 0.9vw;
  position: absolute;
  right: 0;
  pointer-events: none;

  @media (max-width: 480px) {
    width: 4vw;
  }
`;

export const CountryCodeSelector = styled.select`
  width: 5vw;
  height: auto;
  border: none;
  outline: none;
  background-color: #eeeeee;
  font-size: clamp(16px, 1vw, 20px);
  text-align: center;
  cursor: pointer;
  appearance: none;

  @media (max-width: 480px) {
    width: 20vw;
    font-size: 16px;
  }
`;

export const CountryCodeOption = styled.option`
  font-size: clamp(16px, 1vw, 20px);
  background-color: white;
  color: black;
`;

export const RegisterOptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4vh 0;
`;

export const RegisterOptionsTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1vw, 20px);
  width: 100%;
  padding-bottom: 2vh;

  @media (max-width: 480px) {
    font-size: 17px;
  }
`;

export const RegisterOptionsPart = styled.div`
  width: 100%;
  padding-bottom: 3vh;
`;

export const UserOption = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5vw;
  font-size: clamp(14px, 1vw, 16px);
  cursor: pointer;

  input[type="radio"] {
    display: none;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const OptionInput = styled.input.attrs({ type: "radio" })`
  display: none;
`;

export const OptionCircle = styled.div`
  width: 1.5vw;
  height: 1.5vw;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  ${OptionInput}:checked + & {
    background-color: #a41e4d;
  }

  @media (max-width: 480px) {
    width: 6vw;
    height: 6vw;
    margin-right: 1vw;
  }
`;

export const InnerOptionCircle = styled.div`
  width: 0.8vw;
  height: 0.8vw;
  background-color: #d9d9d9;
  border-radius: 50%;
  transition: background-color 0.2s ease;

  ${OptionInput}:checked + ${OptionCircle} & {
    background-color: white;
  }

  @media (max-width: 480px) {
    width: 3vw;
    height: 3vw;
  }
`;

export const PlacementTypeWrap = styled.div`
  display: flex;
  gap: 2vw;
  align-items: center;
  margin-top: 1vh;
`;

export const PackageTypeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2vw;
  margin-top: 1vh;
`;

export const PositionTypeWrap = styled.div`
  display: flex;
  gap: 2vw;
  align-items: center;
  margin-top: 1vh;
`;

export const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
`;

export const PackageCostWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PackageCostLabel = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;
  padding-right: 2px;

  @media (max-width: 480px) {
    font-size: 14px;
    padding-right: 1vw;
  }
`;

export const PackageAmount = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);

  @media (max-width: 480px) {
    font-weight: 400;
    font-size: 16px;
  }
`;

export const IdInputWrap = styled.div`
  width: 100%;
  padding: 2vh 0;
`;

export const VerifyInputWrap = styled.div`
  position: relative;
  width: 100%;
`;

export const VerifyInput = styled.input`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 60px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 10px;

  @media (max-width: 480px) {
    height: 5vh;
    font-size: 14px;
  }
`;

export const VerifyButton = styled.button`
  position: absolute;
  right: 2vw;
  bottom: 2vh;
  border: none;
  background: none;
  font-weight: 400;
  font-size: clamp(16px, 1vw, 18px);
  color: #a41e4d;
  cursor: pointer;

  @media (max-width: 480px) {
    transform: translateY(20%);
    right: 4vw;
    font-size: 14px;
  }
`;

export const SubmitButton = styled.button`
  font-weight: 400;
  font-size: clamp(16px, 1vw, 18px);
  width: 14vw;
  height: auto;
  padding: 20px 0;
  border-radius: 5px;
  border: none;
  background: none;
  color: white;
  background-color: #a41e4d;

  @media (max-width: 480px) {
    width: 60vw;
    font-size: 18px;
    font-weight: 600;
    padding: 2vh 0;
  }
`;
