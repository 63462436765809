import { useState } from "react";
import Icon from "../../../core/Icons";
import {
  AlignTotalBox,
  BackButton,
  BackButtonText,
  BackIconWrap,
  CardData,
  CardInfo,
  CardLabel,
  CardTitle,
  FirstTreeCard,
  GenealogyRank,
  HeaderTitle,
  HeaderWrap,
  IconBackground,
  PlacementTree,
  RegisterButton,
  TreePageContainer,
  TitleAlign,
  TotalBox,
  TotalBoxAmount,
  TotalBoxTitle,
  TreeCards,
  TreeRow,
  SearchWrapper,
  SearchIcon,
  SearchInput,
} from "./placementStyle";
import RegisterGenealogy from "./registerGenealogy";

export interface TreeRowProps {
  numberOfCards: number;
}

interface PlacementTreeContainerProps {
  onBackClick: () => void;
}

export default function PlacementTreeContainer({
  onBackClick,
}: PlacementTreeContainerProps) {
  const [activeComponent, setActiveComponent] = useState<string | null>(null);

  const handleRegisterClick = () => {
    setActiveComponent("register");
  };

  const renderActiveScreen = () => {
    if (activeComponent === "register") {
      return <RegisterGenealogy setActiveComponent={setActiveComponent} />;
    }
    return null;
  };

  return (
    <>
      {activeComponent !== "register" && (
        <TreePageContainer>
          <BackButton onClick={onBackClick}>
            <BackIconWrap>
              <Icon name="back" fill="#737373" />
            </BackIconWrap>

            <BackButtonText>Back to Rewards</BackButtonText>
          </BackButton>

          <HeaderWrap>
            <TitleAlign>
              <IconBackground>
                <Icon name="branch" fill="#a41e4d" />
              </IconBackground>
              <HeaderTitle>Placement Tree</HeaderTitle>
            </TitleAlign>

            <SearchWrapper>
              <SearchIcon>
                <Icon name="search" fill="#737373" />
              </SearchIcon>
              <SearchInput placeholder="Search User" />
            </SearchWrapper>
          </HeaderWrap>

          <AlignTotalBox>
            <TotalBox>
              <TotalBoxTitle>Total Left BV</TotalBoxTitle>
              <TotalBoxAmount>1,400.00</TotalBoxAmount>
            </TotalBox>
            <TotalBox>
              <TotalBoxTitle>Total Right BV</TotalBoxTitle>
              <TotalBoxAmount>0.00</TotalBoxAmount>
            </TotalBox>
          </AlignTotalBox>

          <PlacementTree>
            <TreeRow numberOfCards={1}>
              <FirstTreeCard>
                <CardTitle>Business</CardTitle>
                <GenealogyRank src="/images/purple-rank.png" />
                <CardInfo>
                  <CardLabel>Member ID</CardLabel>
                  <CardData>MY8192392</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Email Address</CardLabel>
                  <CardData>tester22@gmail.com</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Joined Date</CardLabel>
                  <CardData>07 October 2024</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Total Product Purchased</CardLabel>
                  <CardData>0</CardData>
                </CardInfo>
              </FirstTreeCard>
            </TreeRow>

            <TreeRow numberOfCards={2}>
              <TreeCards>
                <CardTitle>Business</CardTitle>
                <GenealogyRank src="/images/purple-rank.png" />
                <CardInfo>
                  <CardLabel>Member ID</CardLabel>
                  <CardData>MY8192392</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Email Address</CardLabel>
                  <CardData>tester22@gmail.com</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Joined Date</CardLabel>
                  <CardData>07 October 2024</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Total Product Purchased</CardLabel>
                  <CardData>0</CardData>
                </CardInfo>
              </TreeCards>

              <RegisterButton onClick={handleRegisterClick}>
                Register
              </RegisterButton>
            </TreeRow>

            <TreeRow numberOfCards={2}>
              <TreeCards>
                <CardTitle>Business</CardTitle>
                <GenealogyRank src="/images/purple-rank.png" />
                <CardInfo>
                  <CardLabel>Member ID</CardLabel>
                  <CardData>MY8192392</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Email Address</CardLabel>
                  <CardData>tester22@gmail.com</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Joined Date</CardLabel>
                  <CardData>07 October 2024</CardData>
                </CardInfo>
                <CardInfo>
                  <CardLabel>Total Product Purchased</CardLabel>
                  <CardData>0</CardData>
                </CardInfo>
              </TreeCards>

              <RegisterButton onClick={handleRegisterClick}>
                Register
              </RegisterButton>
            </TreeRow>

            <TreeRow numberOfCards={2}>
              <RegisterButton onClick={handleRegisterClick}>
                Register
              </RegisterButton>

              <RegisterButton onClick={handleRegisterClick}>
                Register
              </RegisterButton>
            </TreeRow>

            {renderActiveScreen()}
          </PlacementTree>
        </TreePageContainer>
      )}

      {renderActiveScreen()}
    </>
  );
}
