import { CustomIconProps } from "./types";

export const ArIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 38 23" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0936 15.1037L32.8529 23H38L30.8065 14.6007L31.3922 14.3664C34.6375 13.1399 36.6564 10.4182 36.6564 7.25554C36.6564 3.04554 32.9425 0 27.8506 0H13.4568L0 23H5.26419L7.9583 18.3766H18.0181V23H22.2005V15.0692H26.0591L26.0867 15.0968L26.0936 15.1037ZM18.0181 14.1666H10.4112L18.0181 1.17825V14.1666ZM22.2005 4.18934H27.8437C31.5024 4.18934 32.4671 6.20821 32.4671 7.26243C32.4671 9.74985 29.8901 10.8592 27.4923 10.8592H22.2005V4.18934Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
