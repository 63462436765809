import styled from "styled-components";

export const PackagesContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 50vw;
  height: auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    width: 95%;
    margin-bottom: 100px;
  }
`;

export const HeaderWrap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 20px 0;
`;

export const ContainerTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  margin-left: 10px;

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  width: 2.2vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 1.2vw;
  }
`;

export const CardWrap = styled.div`
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 40px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
`;

export const PackageCard = styled.div<{ backgroundImage?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 21vw;
  height: auto;
  background-color: #eeeeee;
  border-radius: 10px;
  padding-top: 25%;
  padding-bottom: 20px;
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : "none"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 480px) {
    width: 80%;
    padding: 0;
  }
`;

export const PackageIcon = styled.img`
  width: 8vw;
  height: auto;

  @media (max-width: 480px) {
    width: 35vw;
    padding: 60px 0 10px 0;
  }
`;

export const PackageLabel = styled.span<{ color?: string }>`
  font-weight: 600;
  font-size: clamp(18px, 1.4vw, 22px);
  color: ${(props) => props.color || "black"};
  padding: 10px 0;

  @media (max-width: 480px) {
    font-size: 22px;
    padding: 5px 0;
  }
`;

export const PackageDescription = styled.text`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 18px);
  padding: 10px 0;

  @media (max-width: 480px) {
    font-size: 15px;
    padding: 5px 0;
  }
`;

export const PriceLabel = styled.text`
  font-weight: 600;
  font-size: clamp(18px, 1.2vw, 22px);
  color: #a41e4d;
  padding: 10px 0;

  @media (max-width: 480px) {
    font-size: 20px;
    padding: 5px;
  }
`;

export const PriceTag = styled.text`
  font-weight: 600;
  font-size: clamp(20px, 1.2vw, 26px);
  color: #a41e4d;
  margin-left: 10px;

  @media (max-width: 480px) {
    font-size: 26px;
  }
`;

export const BuyButton = styled.button`
  width: 80%;
  height: 4vh;
  color: white;
  background-color: #a41e4d;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 10px 0;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
