import styled from "styled-components";

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;

export const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  background-color: white;
  border-radius: 10px;
  padding: 1vh 5vw;
  margin: 2vh 0 1vh 0;
`;

export const PackageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const PackageIcon = styled.div`
  width: 5vw;
  height: 5vw;
  padding: 3px;
  border-radius: 100%;
  background-color: #edc5d3;
  margin-right: 1vw;
`;

export const SecondaryTitle = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
`;

export const RankIcon = styled.img`
  width: 22vw;
  height: auto;
`;

export const RankText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #7c3bff;
`;

export const RankDescription = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 1.5vh;
`;

export const UpgradeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 3vw;
  padding-top: 1.5vh;
  border-top: 1px solid #d9d9d9;
`;

export const SubRankIcon = styled.img`
  width: 9vw;
  height: auto;
`;

export const UpgradeInfo = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  width: 30vw;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: center;
`;

export const UpgradeCost = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #a41e4d;
  padding-left: 1vw;
`;

export const UpgradeRankButton = styled.button`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  background-color: white;
  color: #a41e4d;
  border: 1px solid #a41e4d;
  border-radius: 5px;
  padding: 0.4vh 3vw;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 2vh 5vw;
  margin-top: 1vh;
  margin-bottom: calc(2vh + 80px);
`;

export const InfoIcon = styled.div`
  width: 5vw;
  height: 5vw;
  padding: 3px;
  border-radius: 100%;
  background-color: #edc5d3;
  margin-right: 1vw;
`;

export const InfoHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2vh;
`;

export const TitleAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InformationTitle = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
`;

export const EditButton = styled.button`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #a41e4d;
  border: none;
  background: none;
`;

export const AccountInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1vh;
`;

export const InfoLabelTitle = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
`;

export const InfoLabels = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 45vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoData = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 4vw;
`;

export const EditButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 2vh 0;
  row-gap: 1vh;
`;

export const EditInfoButton = styled.button`
  border: none;
  background: none;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #a41e4d;
`;
