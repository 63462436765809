import styled from "styled-components";
import Icon from "../../core/Icons";
import { useState, useEffect } from "react";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 3vh 0;
  border-radius: 10px;
  width: 24vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    width: 80%;
    height: 40%;
  }
`;

const ModalHeader = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

const ModalTitle = styled.text`
  font-weight: 600;
  font-size: 1.1vw;
  margin-bottom: 0.5vh;

  @media (max-width: 480px) {
    font-size: 17px;
    margin-bottom: 0;
  }
`;

const ModalDescription = styled.text`
  width: 85%;
  font-weight: 400;
  font-size: 0.8vw;
  margin-bottom: 2vh;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const InputLabel = styled.div`
  font-weight: 400;
  font-size: 0.9vw;
  width: 84%;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const TitleStar = styled.text`
  font-weight: 600;
  font-size: 0.9vw;
  color: red;
  margin-left: 5px;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const ModalInput = styled.input`
  font-weight: 400;
  font-size: 0.9vw;
  width: 85%;
  height: 5.5vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 2px;

  @media (max-width: 480px) {
    height: 4vh;
    font-size: 14px;
  }
`;

const CloseButton = styled.button`
  width: 1vw;
  height: auto;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 4vw;
  }
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 3vh 0;
`;

const NoteHeader = styled.text`
  font-weight: 600;
  font-size: 0.9vw;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const ReferralInfoWrap = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
`;

const ReferralInfo = styled.div`
  display: flex;
  align-items: start;
  justify-content: end;
  text-align: end;
  font-weight: 600;
  font-size: 1vw;
  width: 40%;

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

const SubText = styled.text`
  font-weight: 400;
  font-size: 0.8vw;
  color: #737373;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const TickIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vw;
  height: auto;
  border-radius: 100%;
  padding: 8vh 0 3vh 0;

  @media (max-width: 480px) {
    width: 24vw;
    padding: 5vh 0 3vh 0;
  }
`;

const MainButton = styled.button`
  font-weight: 400;
  font-size: 1vw;
  height: 5.5vh;
  width: 84%;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #a41e4d;
  margin-top: auto;

  @media (max-width: 480px) {
    height: 4vh;
    font-size: 16px;
    border-radius: 5px;
  }
`;

type ReferralModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function ReferralModal({
  onClose,
  isVisible,
}: ReferralModalProps) {
  const [steps, setSteps] = useState(0);

  const handleSteps = () => {
    if (steps < 3) {
      setSteps(steps + 1);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setSteps(0);
    }
  }, [isVisible]);

  if (!isVisible) return null;

  const referralContent = () => {
    if (steps === 0) {
      return (
        <>
          <ModalHeader>
            <ModalTitle>Referral Code</ModalTitle>
            <CloseButton onClick={onClose}>
              <Icon name="close" fill="black" />
            </CloseButton>
          </ModalHeader>
          <ModalDescription>
            Create a unique referral code and share it with your friends to earn
            exclusive rewards!
          </ModalDescription>

          <InputLabel>
            Referral Code
            <TitleStar>*</TitleStar>
          </InputLabel>

          <ModalInput placeholder="" type="string" />

          <NoteContainer>
            <NoteHeader>Note:</NoteHeader>
            <SubText></SubText>
          </NoteContainer>

          <MainButton onClick={handleSteps}>Next</MainButton>
        </>
      );
    }
    if (steps === 1) {
      return (
        <>
          <ModalHeader>
            <ModalTitle>Referral Code Preview</ModalTitle>
            <CloseButton onClick={onClose}>
              <Icon name="close" fill="black" />
            </CloseButton>
          </ModalHeader>

          <ReferralInfoWrap>
            <SubText>Referral Code</SubText>
            <ReferralInfo>82197882</ReferralInfo>
          </ReferralInfoWrap>

          <ReferralInfoWrap>
            <SubText>Referral Link</SubText>
            <ReferralInfo>artech4u.com/register?ref=82197882</ReferralInfo>
          </ReferralInfoWrap>

          <NoteContainer>
            <NoteHeader>Note:</NoteHeader>
            <SubText>Once code is saved, it cannot be modified.</SubText>
          </NoteContainer>

          <MainButton onClick={handleSteps}>Submit</MainButton>
        </>
      );
    }
    if (steps === 2) {
      return (
        <>
          <TickIcon>
            <Icon name="tick" fill="#27B934" />
          </TickIcon>

          <ModalTitle>Create Referral Link Successful</ModalTitle>
          <SubText>You have successfully created your referral link</SubText>
          <MainButton onClick={onClose}>Back</MainButton>
        </>
      );
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {referralContent()}
      </ModalContent>
    </ModalOverlay>
  );
}
