import NavBar from "../../components/nav/navBar";
import {
  LeftSection,
  MainText,
  PageBody,
  RightSection,
  SubText,
  InputBox,
  GreetText,
  InputWrap,
  ButtonWrap,
  RegisterContainer,
  LoginLink,
  MainButton,
  AlignInput,
  TermsWrap,
  HiddenCheckbox,
  TermsCheckbox,
  TermsLabel,
  AlignTerms,
} from "./signupStyle";
import FooterBar from "../../components/footer/footer";

export default function SignupPage() {
  return (
    <PageBody>
      <NavBar />

      <RegisterContainer>
        <LeftSection>
          <MainText>Register</MainText>
          <GreetText>
            Welcome to Artech. Please create an account before continuing your
            journey with us.
          </GreetText>
        </LeftSection>
        <RightSection>
          <AlignInput>
            <InputWrap>
              <SubText>Username</SubText>
              <InputBox type="text" placeholder="Username" />
            </InputWrap>

            <InputWrap>
              <SubText>IC Number</SubText>
              <InputBox type="text" placeholder="IC Number" />
            </InputWrap>

            <InputWrap>
              <SubText>Mobile Number</SubText>
              <InputBox type="text" placeholder="Mobile Number" />
            </InputWrap>

            <InputWrap>
              <SubText>Country</SubText>
              <InputBox type="text" placeholder="IC Number" />
            </InputWrap>

            <InputWrap>
              <SubText>Email Address</SubText>
              <InputBox type="email" placeholder="Email Address" />
            </InputWrap>

            <InputWrap>
              <SubText>Password</SubText>
              <InputBox type="password" placeholder="Password" />
            </InputWrap>

            <InputWrap>
              <SubText>Confirm Password</SubText>
              <InputBox type="password" placeholder="Confirm Password" />
            </InputWrap>

            <InputWrap>
              <SubText>Referral Code</SubText>
              <InputBox type="text" placeholder="Referral Code" />
            </InputWrap>

            <InputWrap>
              <SubText>Security Password</SubText>
              <InputBox type="password" placeholder="Security Password" />
            </InputWrap>

            <InputWrap>
              <SubText>Confirm Security Password</SubText>
              <InputBox
                type="password"
                placeholder="Confirm Security Password"
              />
            </InputWrap>

            <AlignTerms>
              <SubText>Terms</SubText>
              <TermsWrap>
                <HiddenCheckbox />
                <TermsCheckbox />
                <TermsLabel>
                  I accept the Terms and Conditions and Privacy Policy
                </TermsLabel>
              </TermsWrap>
            </AlignTerms>
          </AlignInput>

          <ButtonWrap>
            <MainButton>Register</MainButton>
            <LoginLink to="/">Login</LoginLink>
          </ButtonWrap>
        </RightSection>
      </RegisterContainer>

      <FooterBar />
    </PageBody>
  );
}
