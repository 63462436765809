import {
  ContainerNavigation,
  StatusCategory,
  StatusContainer,
  StatusLabels,
  StatusLabelWrap,
  PageTitle,
  ContainerWrap,
} from "./orderStatusStyle";

export default function OrderStatus() {
  return (
    <ContainerWrap>
      <PageTitle>Order Status</PageTitle>

      <StatusContainer>
        <ContainerNavigation>
          <StatusCategory>All</StatusCategory>
          <StatusCategory>Pending</StatusCategory>
          <StatusCategory>Processing</StatusCategory>
          <StatusCategory>Completed</StatusCategory>
        </ContainerNavigation>

        <StatusLabelWrap>
          <StatusLabels>Order ID</StatusLabels>
          <StatusLabels>Purchase Date & Time</StatusLabels>
          <StatusLabels>Total Amount (PV)</StatusLabels>
          <StatusLabels>Delivery Status</StatusLabels>
          <StatusLabels>Action</StatusLabels>
        </StatusLabelWrap>
      </StatusContainer>
    </ContainerWrap>
  );
}
