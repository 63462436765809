import {
  ButtonContainer,
  ComponentButton,
  IconWrap,
  PageBody,
} from "./mobilePackageStyle";
import MobileNavigation from "../../../components/mobile/mobileNavigation";
import PackageSelection from "../../../components/package/packageSelection";
import Icon from "../../../core/Icons";
import RewardsCard from "../../../components/rewards/rewardsCard";
import { useState } from "react";
import PlacementTreeContainer from "../../../components/rewards/genealogy/placementTreeComponent";
import DirectSponsorBonus from "../../../components/rewards/commission/directSponsorBonus";
import RollUpBonus from "../../../components/rewards/commission/rollUpBonus";
import ProductPointBonus from "../../../components/rewards/commission/productPointBonus";
import LevelPairingBonus from "../../../components/rewards/commission/levelPairingBonus";
import PairingBonus from "../../../components/rewards/commission/pairingBonus";
import MatchingBonus from "../../../components/rewards/commission/matchingBonus";
import MatrixBonus from "../../../components/rewards/commission/matrixBonus";
import SponsorContainer from "../../../components/rewards/genealogy/sponsorComponent";
import MobileHeader from "../../../components/mobile/mobileHeader";

export default function MobilePackagePage() {
  const [activeSection, setActiveSection] = useState("Package");
  const [showPlacementTree, setShowPlacementTree] = useState(false);
  const [activeRewardComponent, setActiveRewardComponent] = useState<
    string | null
  >(null);

  const onGoToPlacementTree = () => {
    setShowPlacementTree(true);
  };

  const handleBackClick = () => {
    setShowPlacementTree(false);
    setActiveRewardComponent(null);
  };

  const renderActiveRewardComponent = () => {
    switch (activeRewardComponent) {
      case "DirectSponsorBonus":
        return (
          <DirectSponsorBonus setActiveComponent={setActiveRewardComponent} />
        );
      case "RollUpBonus":
        return <RollUpBonus setActiveComponent={setActiveRewardComponent} />;
      case "ProductPointBonus":
        return (
          <ProductPointBonus setActiveComponent={setActiveRewardComponent} />
        );
      case "LevelPairingBonus":
        return (
          <LevelPairingBonus setActiveComponent={setActiveRewardComponent} />
        );
      case "PairingBonus":
        return <PairingBonus setActiveComponent={setActiveRewardComponent} />;
      case "MatchingBonus":
        return <MatchingBonus setActiveComponent={setActiveRewardComponent} />;
      case "MatrixBonus":
        return <MatrixBonus setActiveComponent={setActiveRewardComponent} />;
      case "Sponsor":
        return (
          <SponsorContainer setActiveComponent={setActiveRewardComponent} />
        );
      default:
        return null;
    }
  };

  return (
    <PageBody>
      <MobileHeader title="Package" onBackClick={handleBackClick} />

      {activeRewardComponent !== "Sponsor" && !showPlacementTree && (
        <MobileNavigation activeSection="Package" onLinkClick={() => {}} />
      )}

      {!activeRewardComponent && !showPlacementTree && (
        <>
          <ButtonContainer>
            <ComponentButton
              isActive={activeSection === "Package"}
              onClick={() => setActiveSection("Package")}
            >
              <IconWrap>
                <Icon name="crown" fill="" />
              </IconWrap>
              Package
            </ComponentButton>
            <ComponentButton
              isActive={activeSection === "Rewards"}
              onClick={() => setActiveSection("Rewards")}
            >
              <IconWrap>
                <Icon name="gift" fill="" />
              </IconWrap>
              Rewards
            </ComponentButton>
          </ButtonContainer>
        </>
      )}

      {showPlacementTree ? (
        <PlacementTreeContainer onBackClick={handleBackClick} />
      ) : activeRewardComponent ? (
        renderActiveRewardComponent()
      ) : activeSection === "Package" ? (
        <PackageSelection />
      ) : (
        <RewardsCard
          onGoToPlacementTree={onGoToPlacementTree}
          setActiveRewardComponent={setActiveRewardComponent}
        />
      )}
    </PageBody>
  );
}
