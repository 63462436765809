import { useState } from "react";
import Icon from "../../../core/Icons";
import DropdownMenu from "../../reusable/dropdown";
import {
  BackButton,
  BackButtonText,
  BackIconWrap,
  HeaderTitle,
  HeaderWrap,
  IconBackground,
  RegisterDetailPart,
  RegisterGenealogyPage,
  RegisterGrid,
  RegisterInput,
  RegisterLabel,
  RegisterName,
  RegisterNameWrap,
  TitleAlign,
  TitleStar,
  PhoneContainer,
  MobileInput,
  CountryCodeWrap,
  DropdownIcon,
  CountryCodeSelector,
  CountryCodeOption,
  UserOption,
  OptionInput,
  OptionCircle,
  InnerOptionCircle,
  PlacementTypeWrap,
  PackageTypeWrap,
  PositionTypeWrap,
  RegisterOptionsPart,
  FlexBetween,
  PackageCostLabel,
  PackageAmount,
  PackageCostWrap,
  VerifyInputWrap,
  VerifyInput,
  VerifyButton,
  IdInputWrap,
  SubmitButton,
  RegisterOptionsTitle,
  RegisterOptionsWrap,
} from "./registerGenealogyStyle";

interface RegisterGenealogyProps {
  setActiveComponent: (component: string | null) => void;
}

export default function RegisterGenealogy({
  setActiveComponent,
}: RegisterGenealogyProps) {
  const [placementType, setPlacementType] = useState("");
  const [packageType, setPackageType] = useState("");
  const [positionType, setPositionType] = useState("");

  const handleBackClick = () => {
    setActiveComponent(null);
  };

  const dropdownOptions = {
    country: ["Country 1", "Country 2", "Country 3"],
  };
  return (
    <RegisterGenealogyPage>
      <BackButton onClick={handleBackClick}>
        <BackIconWrap>
          <Icon name="back" fill="#737373" />
        </BackIconWrap>

        <BackButtonText>Back</BackButtonText>
      </BackButton>

      <HeaderWrap>
        <TitleAlign>
          <IconBackground>
            <Icon name="profile" fill="#a41e4d" />
          </IconBackground>
          <HeaderTitle>Account Information</HeaderTitle>
        </TitleAlign>
      </HeaderWrap>

      <RegisterDetailPart>
        <RegisterNameWrap>
          Full Name
          <TitleStar>*</TitleStar>
          <RegisterName placeholder="Enter Full Name" />
        </RegisterNameWrap>

        <RegisterGrid>
          <RegisterLabel>
            Email Address
            <TitleStar>*</TitleStar>
            <RegisterInput placeholder="Enter email address" />
          </RegisterLabel>

          <RegisterLabel>
            IC Name
            <TitleStar>*</TitleStar>
            <RegisterInput placeholder="Enter IC name" />
          </RegisterLabel>

          <RegisterLabel>
            Mobile Number
            <TitleStar>*</TitleStar>
            <PhoneContainer>
              <CountryCodeWrap>
                <CountryCodeSelector>
                  <CountryCodeOption value="+60">+60</CountryCodeOption>
                  <CountryCodeOption value="+1">+1</CountryCodeOption>
                  <CountryCodeOption value="+44">+44</CountryCodeOption>
                  <CountryCodeOption value="+91">+91</CountryCodeOption>
                </CountryCodeSelector>
                <DropdownIcon>
                  <Icon name="arrow" fill="" />
                </DropdownIcon>
              </CountryCodeWrap>
              <MobileInput type="tel" placeholder="Mobile Number" />
            </PhoneContainer>
          </RegisterLabel>

          <RegisterLabel>
            Country
            <TitleStar>*</TitleStar>
            <DropdownMenu
              placeholder="Select Country"
              options={dropdownOptions.country}
            />
          </RegisterLabel>

          <RegisterLabel>
            Password
            <TitleStar>*</TitleStar>
            <RegisterInput placeholder="Password" />
          </RegisterLabel>

          <RegisterLabel>
            Confirm Password
            <TitleStar>*</TitleStar>
            <RegisterInput placeholder="Confirm Password" />
          </RegisterLabel>

          <RegisterLabel>
            Security Password
            <TitleStar>*</TitleStar>
            <RegisterInput placeholder="Security Password" />
          </RegisterLabel>

          <RegisterLabel>
            Confirm Security Password
            <TitleStar>*</TitleStar>
            <RegisterInput placeholder="Confirm Security Password" />
          </RegisterLabel>
        </RegisterGrid>

        <RegisterOptionsWrap>
          <RegisterOptionsTitle>Package Purchase</RegisterOptionsTitle>
          <RegisterOptionsPart>
            <RegisterLabel>
              Placement Type
              <TitleStar>*</TitleStar>
              <PlacementTypeWrap>
                <UserOption>
                  <OptionInput
                    name="placementType"
                    value="auto"
                    checked={placementType === "auto"}
                    onChange={() => setPlacementType("auto")}
                  />
                  <OptionCircle>
                    <InnerOptionCircle />
                  </OptionCircle>
                  Auto
                </UserOption>
                <UserOption>
                  <OptionInput
                    name="placementType"
                    value="manual"
                    checked={placementType === "manual"}
                    onChange={() => setPlacementType("manual")}
                  />
                  <OptionCircle>
                    <InnerOptionCircle />
                  </OptionCircle>
                  Manual
                </UserOption>
              </PlacementTypeWrap>
            </RegisterLabel>
          </RegisterOptionsPart>

          <RegisterOptionsPart>
            <RegisterLabel>
              Package
              <TitleStar>*</TitleStar>
              <PackageTypeWrap>
                <FlexBetween>
                  <UserOption>
                    <OptionInput
                      name="packageType"
                      value="premium"
                      checked={packageType === "premium"}
                      onChange={() => setPackageType("premium")}
                    />
                    <OptionCircle>
                      <InnerOptionCircle />
                    </OptionCircle>
                    Premium
                  </UserOption>
                  <PackageCostWrap>
                    <PackageCostLabel>Cost:</PackageCostLabel>
                    <PackageAmount>100 USD</PackageAmount>
                  </PackageCostWrap>
                </FlexBetween>
                <FlexBetween>
                  <UserOption>
                    <OptionInput
                      name="packageType"
                      value="family"
                      checked={packageType === "family"}
                      onChange={() => setPackageType("family")}
                    />
                    <OptionCircle>
                      <InnerOptionCircle />
                    </OptionCircle>
                    Family
                  </UserOption>
                  <PackageCostWrap>
                    <PackageCostLabel>Cost:</PackageCostLabel>
                    <PackageAmount>500 USD</PackageAmount>
                  </PackageCostWrap>
                </FlexBetween>
                <FlexBetween>
                  <UserOption>
                    <OptionInput
                      name="packageType"
                      value="business"
                      checked={packageType === "business"}
                      onChange={() => setPackageType("business")}
                    />
                    <OptionCircle>
                      <InnerOptionCircle />
                    </OptionCircle>
                    Business
                  </UserOption>
                  <PackageCostWrap>
                    <PackageCostLabel>Cost:</PackageCostLabel>
                    <PackageAmount>1,000 USD</PackageAmount>
                  </PackageCostWrap>
                </FlexBetween>
                <FlexBetween>
                  <UserOption>
                    <OptionInput
                      name="packageType"
                      value="entrepreneurs"
                      checked={packageType === "entrepreneurs"}
                      onChange={() => setPackageType("entrepreneurs")}
                    />
                    <OptionCircle>
                      <InnerOptionCircle />
                    </OptionCircle>
                    Entrepreneurs
                  </UserOption>
                  <PackageCostWrap>
                    <PackageCostLabel>Cost:</PackageCostLabel>
                    <PackageAmount>2,000 USD</PackageAmount>
                  </PackageCostWrap>
                </FlexBetween>
              </PackageTypeWrap>
            </RegisterLabel>
          </RegisterOptionsPart>
          <IdInputWrap>
            <RegisterLabel>
              Placement ID
              <VerifyInputWrap>
                <VerifyInput placeholder="Enter placement ID" />
                <VerifyButton>Verify</VerifyButton>
              </VerifyInputWrap>
            </RegisterLabel>
          </IdInputWrap>

          <IdInputWrap>
            <RegisterLabel>
              Referrer
              <VerifyInputWrap>
                <VerifyInput placeholder="Enter referrer ID" />
                <VerifyButton>Verify</VerifyButton>
              </VerifyInputWrap>
            </RegisterLabel>
          </IdInputWrap>

          <RegisterOptionsPart>
            <RegisterLabel>
              Position
              <TitleStar>*</TitleStar>
              <PositionTypeWrap>
                <UserOption>
                  <OptionInput
                    name="positionType"
                    value="left"
                    checked={positionType === "left"}
                    onChange={() => setPositionType("left")}
                  />
                  <OptionCircle>
                    <InnerOptionCircle />
                  </OptionCircle>
                  Left
                </UserOption>
                <UserOption>
                  <OptionInput
                    name="positionType"
                    value="right"
                    checked={positionType === "right"}
                    onChange={() => setPositionType("right")}
                  />
                  <OptionCircle>
                    <InnerOptionCircle />
                  </OptionCircle>
                  Right
                </UserOption>
              </PositionTypeWrap>
            </RegisterLabel>
          </RegisterOptionsPart>
        </RegisterOptionsWrap>
      </RegisterDetailPart>

      <SubmitButton>Submit</SubmitButton>
    </RegisterGenealogyPage>
  );
}
