import Icon from "../../../core/Icons";
import {
  BackButton,
  BackButtonText,
  BackIconWrap,
  HeaderWrap,
  IconBackground,
  HeaderTitle,
  BigContainer,
  TitleAlign,
} from "./commissionStyle";

interface CommissionProps {
  setActiveComponent: (component: string | null) => void;
}

export default function MatchingBonus({ setActiveComponent }: CommissionProps) {
  const handleBackClick = () => {
    setActiveComponent(null);
  };

  return (
    <BigContainer>
      <BackButton onClick={handleBackClick}>
        <BackIconWrap>
          <Icon name="back" fill="#737373" />
        </BackIconWrap>

        <BackButtonText>Back</BackButtonText>
      </BackButton>

      <HeaderWrap>
        <TitleAlign>
          <IconBackground>
            <Icon name="cash" fill="#a41e4d" />
          </IconBackground>
          <HeaderTitle>Matching Bonus</HeaderTitle>
        </TitleAlign>
      </HeaderWrap>
    </BigContainer>
  );
}
