import styled from "styled-components";

export const MainContainer = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    width: 95%;
    margin-bottom: 100px;
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CommissionHeaderWrap = styled.div`
  width: 100%;
  padding: 3vh 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 480px) {
    padding: 3vh 7vw;
  }
`;

export const HeaderText = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  margin-left: 8px;
`;

export const RedirectButtons = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  background: none;
  background-color: #eeeeee;
  border-radius: 10px;
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
  width: 100%;
  height: auto;
  padding: 16px 20px;
  cursor: pointer;
`;

export const SideArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8vw;
  height: auto;

  @media (max-width: 480px) {
    width: 3vw;
  }
`;

export const ReferralBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10vh;
  background-color: white;
  border-radius: 10px;
  padding: 0 2vw;
  margin-bottom: 2vh;

  @media (max-width: 480px) {
    height: 8vh;
    padding: 3vh 7vw;
  }
`;

export const ReferralIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4vw;
  height: auto;
  padding: 6px 10px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    height: 3.8vh;
    padding: 7px;
  }
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 4px;
  }
`;

export const CreateReferralButton = styled.button`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 18px);
  width: 6.5vw;
  padding: 10px 0;
  height: auto;
  color: #a41e4d;
  background: none;
  border: 2px solid #a41e4d;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 25vw;
    height: 4vh;
    padding: 0;
  }
`;

export const CommissionBox = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 25px;
  margin-bottom: 2vh;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CommissionButtons = styled.div`
  width: 100%;
  padding: 0 2vw;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 15px;

  @media (max-width: 480px) {
    width: 85%;
    display: flex;
    flex-direction: column;
  }
`;

export const GenealogyBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-bottom: 25px;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 480px) {
    align-items: center;
  }
`;

export const GenealogyInnerWrap = styled.div`
  width: 100%;
  padding: 3vh 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 3vh 7vw;
  }
`;

export const GenealogyButton = styled.div`
  width: 100%;
  padding: 0 2vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    width: 85%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
`;

export const GenealogyCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GenealogyText = styled.text`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  margin-left: 20px;
`;

export const GenealogyReferrals = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
  margin-left: 10px;
`;

export const GenealogyActive = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
  margin-left: 10px;
`;
