import { CustomIconProps } from "./types";

export const BoxIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.25 33.9938V22.0063L8.75 15.925V27.9125L19.25 33.9938ZM22.75 33.9938L33.25 27.9125V15.925L22.75 22.0063V33.9938ZM19.25 38.0187L7 30.975C6.44583 30.6542 6.01562 30.2313 5.70938 29.7063C5.40313 29.1813 5.25 28.5979 5.25 27.9563V14.0437C5.25 13.4021 5.40313 12.8188 5.70938 12.2937C6.01562 11.7688 6.44583 11.3458 7 11.025L19.25 3.98125C19.8042 3.66042 20.3875 3.5 21 3.5C21.6125 3.5 22.1958 3.66042 22.75 3.98125L35 11.025C35.5542 11.3458 35.9844 11.7688 36.2906 12.2937C36.5969 12.8188 36.75 13.4021 36.75 14.0437V27.9563C36.75 28.5979 36.5969 29.1813 36.2906 29.7063C35.9844 30.2313 35.5542 30.6542 35 30.975L22.75 38.0187C22.1958 38.3396 21.6125 38.5 21 38.5C20.3875 38.5 19.8042 38.3396 19.25 38.0187ZM28 14.9187L31.3687 12.9938L21 7L17.5875 8.96875L28 14.9187ZM21 18.9875L24.4125 17.0188L14.0437 11.025L10.6312 12.9938L21 18.9875Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
