import styled from "styled-components";

export const ContainerWrap = styled.div`
  width: 100%;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 66vh;
  border-radius: 10px;

  @media (max-width: 480px) {
    border-radius: 0;
    width: 100vw;
    height: calc(100% - 4px - 6vh);
  }
`;

export const PageTitle = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 16px;
    background-color: white;
    width: 100vw;
    height: 6vh;
    margin: 2px 0;
  }
`;

export const ContainerNavigation = styled.div`
  width: 95%;
  height: 25px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 480px) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const StatusCategory = styled.button`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  color: #737373;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  padding-bottom: 5px;

  &:hover {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  &:focus {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }
`;

export const StatusLabelWrap = styled.div`
  width: 95%;
  border-radius: 10px;
  height: auto;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 20px;
`;

export const StatusLabels = styled.div`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
