import { CustomIconProps } from "./types";

export const ReferralIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 4C8.45 4 7.97917 3.80417 7.5875 3.4125C7.19583 3.02083 7 2.55 7 2C7 1.45 7.19583 0.979167 7.5875 0.5875C7.97917 0.195833 8.45 0 9 0C9.55 0 10.0208 0.195833 10.4125 0.5875C10.8042 0.979167 11 1.45 11 2C11 2.55 10.8042 3.02083 10.4125 3.4125C10.0208 3.80417 9.55 4 9 4ZM6 19V7C5 6.91667 3.98333 6.79167 2.95 6.625C1.91667 6.45833 0.933333 6.25 0 6L0.5 4C1.8 4.35 3.18333 4.60417 4.65 4.7625C6.11667 4.92083 7.56667 5 9 5C10.4333 5 11.8833 4.92083 13.35 4.7625C14.8167 4.60417 16.2 4.35 17.5 4L18 6C17.0667 6.25 16.0833 6.45833 15.05 6.625C14.0167 6.79167 13 6.91667 12 7V19H10V13H8V19H6ZM5 24C4.71667 24 4.47917 23.9042 4.2875 23.7125C4.09583 23.5208 4 23.2833 4 23C4 22.7167 4.09583 22.4792 4.2875 22.2875C4.47917 22.0958 4.71667 22 5 22C5.28333 22 5.52083 22.0958 5.7125 22.2875C5.90417 22.4792 6 22.7167 6 23C6 23.2833 5.90417 23.5208 5.7125 23.7125C5.52083 23.9042 5.28333 24 5 24ZM9 24C8.71667 24 8.47917 23.9042 8.2875 23.7125C8.09583 23.5208 8 23.2833 8 23C8 22.7167 8.09583 22.4792 8.2875 22.2875C8.47917 22.0958 8.71667 22 9 22C9.28333 22 9.52083 22.0958 9.7125 22.2875C9.90417 22.4792 10 22.7167 10 23C10 23.2833 9.90417 23.5208 9.7125 23.7125C9.52083 23.9042 9.28333 24 9 24ZM13 24C12.7167 24 12.4792 23.9042 12.2875 23.7125C12.0958 23.5208 12 23.2833 12 23C12 22.7167 12.0958 22.4792 12.2875 22.2875C12.4792 22.0958 12.7167 22 13 22C13.2833 22 13.5208 22.0958 13.7125 22.2875C13.9042 22.4792 14 22.7167 14 23C14 23.2833 13.9042 23.5208 13.7125 23.7125C13.5208 23.9042 13.2833 24 13 24Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
