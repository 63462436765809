import { Link } from "react-router-dom";
import styled from "styled-components";

export const PageNavContainer = styled.div`
  width: 75vw;
  height: 8vh;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 1.2rem;
  font-family: "Inter", sans-serif;

  @media (max-width: 768px) {
    height: 90px;
  }

  @media (max-width: 480px) {
    height: 130px;
  }
`;

export const AlignContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;

  @media (max-width: 480px) {
    height: 60%;
    align-items: end;
    justify-content: center;
  }
`;

export const HomeLink = styled(Link)`
  cursor: pointer;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const LocalServerTime = styled.div``;

export const WrapRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
`;

export const RankWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
`;

export const RankIcon = styled.img`
  width: 2.6vw;
  height: auto;
`;

export const RankText = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 18px);
  color: #9747ff;
  margin-left: 10px;
`;

export const WalletWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
`;

export const WalletIcon = styled.div`
  width: 2vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const WalletAmount = styled.div`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
`;

export const ArrowIcon = styled.div`
  width: 1vw;
  height: auto;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
`;

export const CartButton = styled.button`
  width: 1.6vw;
  height: auto;
  border: none;
  background: none;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
`;

export const LanguageIcon = styled.div`
  width: 1.7vw;
  height: auto;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
`;
