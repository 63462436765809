import { CustomIconProps } from "./types";

export const BackIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.42122 0.208642L7.30165 3.9251C8.65079 3.80098 9.96086 3.95052 11.2319 4.37371C12.5029 4.79689 13.6336 5.50371 14.624 6.49416C15.81 7.68009 16.5898 9.05059 16.9635 10.6057C17.3372 12.1607 17.3304 13.7314 16.943 15.3177C16.7492 14.6514 16.4805 14.0143 16.1369 13.4066C15.7933 12.7989 15.3673 12.2409 14.8591 11.7327C13.8165 10.6901 12.6152 9.98219 11.2551 9.60895C9.89508 9.23571 8.51834 9.17239 7.1249 9.41899L7.00534 13.1354L0.749877 6.87998L7.42122 0.208642Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
