import { CustomIconProps } from "./types";

export const CartIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.75 25.5C7.0625 25.5 6.47396 25.2552 5.98438 24.7656C5.49479 24.276 5.25 23.6875 5.25 23C5.25 22.3125 5.49479 21.724 5.98438 21.2344C6.47396 20.7448 7.0625 20.5 7.75 20.5C8.4375 20.5 9.02604 20.7448 9.51563 21.2344C10.0052 21.724 10.25 22.3125 10.25 23C10.25 23.6875 10.0052 24.276 9.51563 24.7656C9.02604 25.2552 8.4375 25.5 7.75 25.5ZM20.25 25.5C19.5625 25.5 18.974 25.2552 18.4844 24.7656C17.9948 24.276 17.75 23.6875 17.75 23C17.75 22.3125 17.9948 21.724 18.4844 21.2344C18.974 20.7448 19.5625 20.5 20.25 20.5C20.9375 20.5 21.526 20.7448 22.0156 21.2344C22.5052 21.724 22.75 22.3125 22.75 23C22.75 23.6875 22.5052 24.276 22.0156 24.7656C21.526 25.2552 20.9375 25.5 20.25 25.5ZM6.6875 5.5L9.6875 11.75H18.4375L21.875 5.5H6.6875ZM5.5 3H23.9375C24.4167 3 24.7813 3.21354 25.0313 3.64062C25.2813 4.06771 25.2917 4.5 25.0625 4.9375L20.625 12.9375C20.3958 13.3542 20.0885 13.6771 19.7031 13.9062C19.3177 14.1354 18.8958 14.25 18.4375 14.25H9.125L7.75 16.75H22.75V19.25H7.75C6.8125 19.25 6.10417 18.8385 5.625 18.0156C5.14583 17.1927 5.125 16.375 5.5625 15.5625L7.25 12.5L2.75 3H0.25V0.5H4.3125L5.5 3Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
