import styled from "styled-components";
import { Link } from "react-router-dom";

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ResetContainer = styled.div`
  background-color: white;
  width: 85%;
  border-radius: 12px;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4vw;
  padding: 8vh 6vw;
  margin-top: 6vh;

  @media (max-width: 480px) {
    padding: 1vh 4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 480px) {
    padding: 3vh 1vw 6vh 1vw;
  }
`;

export const MainText = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: #a41e4d;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const GreetText = styled.p`
  width: 15rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #737373;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 480px) {
    align-items: center;
    justify-content: space-between;
  }
`;

export const AlignInput = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
    width: 100%;
  }
`;

export const SubWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
  }
`;

export const UserText = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const PasswordText = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vh 0;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1vh 0;
  }
`;

export const InputBox = styled.input`
  background-color: #eeeeee;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  border-radius: 10px;
  height: 6vh;
  border: none;
  outline: none;
  margin: 1vh 0;
  text-indent: 1.5vw;

  @media (max-width: 480px) {
    width: 100%;
    height: 5vh;
    text-indent: 20px;
    font-size: 14px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2vh 0;

  @media (max-width: 480px) {
    padding: 10vh 0 4vh 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MainButton = styled.button`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  width: 220px;
  height: 7vh;
  border-radius: 12px;
  border: none;
  color: white;
  background-color: #a41e4d;

  @media (max-width: 480px) {
    font-size: 16px;
    width: 80%;
    height: 4.5vh;
  }
`;

export const RegisterLink = styled(Link)`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #a41e4d;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;
