import styled from "styled-components";

export const SmallContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 0 2vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    margin: 1vh 0 0 0;
    width: 95%;
    height: calc(90vh - 140px - 1vh);
  }
`;

export const BigContainer = styled.div`
  width: 100%;
  height: 60vh;
  background-color: white;
  border-radius: 10px;
  padding: 0 2vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    margin: 1vh 0 0 0;
    width: 95%;
    height: calc(90vh - 140px - 1vh);
  }
`;

export const BackButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin: 1.5vh 0;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const BackIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  height: auto;
`;

export const BackButtonText = styled.text`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  padding: 0 5px;
`;

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2vh;

  @media (max-width: 480px) {
    width: 90%;
    padding: 1.5vh 0;
  }
`;

export const TitleAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  width: 2.4vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 4px;
  }
`;

export const HeaderTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  padding: 0 5px;
`;

export const SelectedDayTitle = styled.text`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 18px);
`;

export const BonusGridWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 15px;
  margin-bottom: 15%;

  @media (max-width: 480px) {
    width: 85%;
    display: flex;
    flex-direction: column;
  }
`;

export const DisplayedBonusWrap = styled.div``;

export const DisplayBonusDate = styled.text`
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const MonthBonusButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  background: none;
  background-color: #eeeeee;
  border-radius: 10px;
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
  width: 100%;
  height: 5vh;
  padding: 1.5vw;
  cursor: pointer;
`;

export const DisplayedBonus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  background: none;
  background-color: #eeeeee;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 1.5vw;
  margin-bottom: 2vh;

  @media (max-width: 480px) {
    height: 10vh;
    padding: 2vh 5vw;
    margin-bottom: 1vh;
  }
`;

export const AlignBonusInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisplayBonusTitle = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
  padding-bottom: 4px;

  @media (max-width: 480px) {
    font-size: 17px;
    margin-bottom: 1vh;
    padding: 0;
  }
`;

export const DisplayBonusText = styled.text`
  font-weight: 400;
  font-size: clamp(11px, 1vw, 13px);

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const DisplayBonusAmount = styled.text`
  font-weight: 600;
  font-size: clamp(15px, 1vw, 17px);
  color: #27b934;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8vw;
  height: auto;
`;

export const PageNavigator = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  gap: 10px;
`;

export const PrevButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9vw;
  height: auto;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  transform: rotate(180deg);

  &:disabled {
    fill: #aaa;
    cursor: default;
  }

  @media (max-width: 480px) {
    width: 12px;
  }
`;

export const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9vw;
  height: auto;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;

  &:disabled {
    fill: #aaa;
    cursor: default;
  }

  @media (max-width: 480px) {
    width: 12px;
  }
`;

export const CurrentPageCircle = styled.div`
  font-weight: 400;
  font-size: 1vw;
  width: 30px;
  height: 30px;
  padding: 18px;
  margin: 0 1vw;
  border-radius: 50%;
  color: white;
  background-color: #a41e4d;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    padding: 10px;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
`;
