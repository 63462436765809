import styled from "styled-components";

export const FooterBox = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: clamp(50px, 7vh, 100px);
  background-color: white;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    height: 120px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 93%;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const FooterText = styled.h1`
  font-weight: 500;
  font-size: 14px;
`;

export const TncWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 18rem;

  @media (max-width: 480px) {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
`;

export const TNC = styled.button`
  font-size: 13px;
  font-weight: 600;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;
