import styled from "styled-components";
import Icon from "../../core/Icons";

const HeaderContainer = styled.div`
  position: relative;
  display: none;
  width: 100%;
  height: 15vh;
  padding-bottom: 15px;
  background-color: white;
  align-items: end;
  justify-content: center;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackButton = styled.div`
  position: absolute;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: auto;
  transform: rotate(180deg);
`;

const HeaderTitle = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
`;

const HeaderButton = styled.button`
  position: absolute;
  right: 30px;
  width: 30px;
  height: auto;
  background: none;
  border: none;
  cursor: pointer;
`;

interface MobileHeaderProps {
  title: string;
  onBackClick?: () => void;
}

export default function MobileHeader({
  title,
  onBackClick,
}: MobileHeaderProps) {
  return (
    <HeaderContainer>
      <HeaderContent>
        <BackButton onClick={onBackClick}>
          <Icon name="sidearrow" fill="black" />
        </BackButton>

        <HeaderTitle>{title}</HeaderTitle>
        <HeaderButton>
          <Icon name="globe" fill="" />
        </HeaderButton>
      </HeaderContent>
    </HeaderContainer>
  );
}
