import { CustomIconProps } from "./types";

export const BranchIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.5C10.95 1.5 10.0625 1.8625 9.3375 2.5875C8.6125 3.3125 8.25 4.2 8.25 5.25C8.25 6.11667 8.50833 6.87917 9.025 7.5375C9.54167 8.19583 10.2 8.64167 11 8.875V11H5V15H2.5V22H9.5V15H7V13H17V15.125C16.2 15.3583 15.5417 15.8042 15.025 16.4625C14.5083 17.1208 14.25 17.8833 14.25 18.75C14.25 19.8 14.6125 20.6875 15.3375 21.4125C16.0625 22.1375 16.95 22.5 18 22.5C19.05 22.5 19.9375 22.1375 20.6625 21.4125C21.3875 20.6875 21.75 19.8 21.75 18.75C21.75 17.8833 21.4917 17.1208 20.975 16.4625C20.4583 15.8042 19.8 15.3583 19 15.125V11H13V8.875C13.8 8.64167 14.4583 8.19583 14.975 7.5375C15.4917 6.87917 15.75 6.11667 15.75 5.25C15.75 4.2 15.3875 3.3125 14.6625 2.5875C13.9375 1.8625 13.05 1.5 12 1.5ZM18 17C18.4833 17 18.8958 17.1708 19.2375 17.5125C19.5792 17.8542 19.75 18.2667 19.75 18.75C19.75 19.2333 19.5792 19.6458 19.2375 19.9875C18.8958 20.3292 18.4833 20.5 18 20.5C17.5167 20.5 17.1042 20.3292 16.7625 19.9875C16.4208 19.6458 16.25 19.2333 16.25 18.75C16.25 18.2667 16.4208 17.8542 16.7625 17.5125C17.1042 17.1708 17.5167 17 18 17ZM4.5 17H7.5V20H4.5V17ZM12 3.5C12.4833 3.5 12.8958 3.67083 13.2375 4.0125C13.5792 4.35417 13.75 4.76667 13.75 5.25C13.75 5.73333 13.5792 6.14583 13.2375 6.4875C12.8958 6.82917 12.4833 7 12 7C11.5167 7 11.1042 6.82917 10.7625 6.4875C10.4208 6.14583 10.25 5.73333 10.25 5.25C10.25 4.76667 10.4208 4.35417 10.7625 4.0125C11.1042 3.67083 11.5167 3.5 12 3.5Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
