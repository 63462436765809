import Icon from "../../../core/Icons";
import { useNavigate } from "react-router-dom";
import {
  AlignContent,
  RankIcon,
  RankText,
  RankWrap,
  LocalServerTime,
  WrapRight,
  WalletWrap,
  WalletAmount,
  WalletIcon,
  ArrowIcon,
  LanguageIcon,
  PageNavContainer,
  CartButton,
} from "./pageNavStyle";

export default function PageNav() {
  const navigate = useNavigate();
  return (
    <PageNavContainer>
      <AlignContent>
        <LocalServerTime>Server Time: Local Time:</LocalServerTime>

        <WrapRight>
          <RankWrap>
            <RankIcon src="/images/purple-rank.png" />
            <RankText>Business</RankText>
          </RankWrap>

          <WalletWrap>
            <WalletIcon>
              <Icon name="wallet" fill="" />
            </WalletIcon>
            <WalletAmount>USD 4.8</WalletAmount>

            <ArrowIcon>
              <Icon name="arrow" fill="" />
            </ArrowIcon>
          </WalletWrap>

          <CartButton onClick={() => navigate("/cart")}>
            <Icon name="cart" fill="" />
          </CartButton>

          <LanguageIcon>
            <Icon name="globe" fill="" />
          </LanguageIcon>
        </WrapRight>
      </AlignContent>
    </PageNavContainer>
  );
}
