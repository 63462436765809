import {
  InfoBox,
  InfoData,
  InfoTitle,
  InfoTotal,
  InputLabel,
  CartMainButton,
  ShippingContainer,
  ShippingForm,
  ShippingInput,
  ShippingTitle,
  TotalData,
  InfoWallet,
} from "./cartShippingStyle";
import DropdownMenu from "../reusable/dropdown";

interface ShippingCartProps {
  onBackToCart?: () => void;
}

export default function CartShipping({ onBackToCart }: ShippingCartProps) {
  const dropdownOptions = {
    state: ["State 1", "State 2", "State 3"],
    city: ["City 1", "City 2", "City 3"],
    country: ["Country 1", "Country 2", "Country 3"],
  };
  return (
    <ShippingContainer>
      {onBackToCart && <div onClick={onBackToCart}></div>}

      <ShippingTitle>Shipping Info</ShippingTitle>
      <ShippingForm>
        <InputLabel>
          Contact Person
          <ShippingInput placeholder="Enter username"></ShippingInput>
        </InputLabel>
        <InputLabel>
          Contact Number
          <ShippingInput placeholder="Mobile Number"></ShippingInput>
        </InputLabel>
        <InputLabel>
          Address Line 1
          <ShippingInput placeholder="Enter Address Line 1"></ShippingInput>
        </InputLabel>
        <InputLabel>
          Address Line 2
          <ShippingInput placeholder="Enter Address Line 2"></ShippingInput>
        </InputLabel>
        <InputLabel>
          State
          <DropdownMenu
            placeholder="Pick a State"
            options={dropdownOptions.state}
          />
        </InputLabel>
        <InputLabel>
          City
          <DropdownMenu
            placeholder="Pick a City"
            options={dropdownOptions.city}
          />
        </InputLabel>
        <InputLabel>
          Postal Code
          <ShippingInput placeholder="Enter Postal Code"></ShippingInput>
        </InputLabel>
        <InputLabel>
          Country
          <DropdownMenu
            placeholder="Pick a Country"
            options={dropdownOptions.country}
          />
        </InputLabel>
      </ShippingForm>

      <InfoBox>
        <InfoTitle>
          Sub Total
          <InfoData>0 PV</InfoData>
        </InfoTitle>
        <InfoTitle>
          Shipping Fee
          <InfoData>Free</InfoData>
        </InfoTitle>
        <InfoTotal>
          Total Payment
          <TotalData>0 PV</TotalData>
        </InfoTotal>
        <InfoTitle>
          Product Wallet Balance
          <InfoWallet>USD 210.00</InfoWallet>
        </InfoTitle>

        <CartMainButton>Proceed to Payment</CartMainButton>
      </InfoBox>
    </ShippingContainer>
  );
}
