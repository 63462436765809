import styled from "styled-components";

export const ContainerWrap = styled.div`
  font-family: "Inter", sans-serif;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  width: 50vw;
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 480px) {
    border-radius: 0;
    width: 100vw;
    height: calc(100% - 4px - 6vh);
  }
`;

export const HistoryIcon = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    width: 22px;
    height: auto;
    margin-right: 4px;
  }
`;

export const PageTitle = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    background-color: white;
    width: 100vw;
    height: 6vh;
    margin: 2px 0;
  }
`;

export const HistoryCategoryNavigation = styled.div`
  width: 95%;
  height: 25px;
  margin: 20px 0;
  flex-direction: row;
  display: flex;

  @media (max-width: 480px) {
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
`;

export const HistoryCategory = styled.button`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  color: #737373;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  padding-bottom: 5px;

  &:hover {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  &:focus {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  @media (max-width: 480px) {
    margin: 0;
  }
`;

export const HistoryCategoryMobileHidden = styled.button`
  display: flex;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  color: #737373;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  padding-bottom: 5px;

  &:hover {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  &:focus {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  @media (max-width: 480px) {
    display: none;
    margin: 0;
  }
`;

export const HistoryTitleContainer = styled.div`
  width: 95%;
  border-radius: 10px;
  height: auto;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1.5fr;
  align-items: center;
  padding: 20px;

  @media (max-width: 480px) {
    width: 100%;
    border-radius: 0;
    padding: 6px 20px;
  }
`;

export const HistoryLabels = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  text-align: left;
  overflow-wrap: break-word;
`;

export const HistoryStatusLabel = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  padding-right: 10px;
  text-align: right;

  @media (max-width: 480px) {
    padding: 0;
    text-align: center;
  }
`;

export const HistoryDisplayContainer = styled.div`
  width: 95%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1.5fr;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const HistoryDisplayText = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  text-align: left;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const HistoryCurrency = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: clamp(12px, 1vw, 14px);
  text-align: left;
  color: #27b934;
`;

export const AlignStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const HistoryStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  width: 5vw;
  height: 3vh;
  color: #27b934;
  background-color: #cfffd3;
  border: 1px solid #27b934;
  border-radius: 5px;

  @media (max-width: 480px) {
    width: 80px;
  }
`;

export const PageNavigator = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

export const PrevButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9vw;
  height: auto;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  transform: rotate(180deg);

  &:disabled {
    fill: #aaa;
    cursor: default;
  }

  @media (max-width: 480px) {
    width: 12px;
  }
`;

export const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9vw;
  height: auto;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;

  &:disabled {
    fill: #aaa;
    cursor: default;
  }

  @media (max-width: 480px) {
    width: 12px;
  }
`;

export const CurrentPageCircle = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  width: 30px;
  height: 30px;
  padding: 18px;
  margin: 0 1vw;
  border-radius: 50%;
  color: white;
  background-color: #a41e4d;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    padding: 10px;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
`;
