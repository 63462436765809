import NavBar from "../../components/nav/navBar";
import {
  LeftSection,
  ResetContainer,
  MainText,
  PageBody,
  RightSection,
  UserText,
  InputBox,
  GreetText,
  MainButton,
  InputWrap,
  ButtonWrap,
  AlignInput,
} from "./resetStyle";
import FooterBar from "../../components/footer/footer";

export default function ResetPage() {
  return (
    <PageBody>
      <NavBar />

      <ResetContainer>
        <LeftSection>
          <MainText>Reset Password</MainText>
          <GreetText>Please enter Username and Email to continue.</GreetText>
        </LeftSection>
        <RightSection>
          <AlignInput>
            <InputWrap>
              <UserText>Username *</UserText>
              <InputBox type="text" placeholder="Username" />
            </InputWrap>

            <InputWrap>
              <UserText>Password * </UserText>
              <InputBox type="password" placeholder="Password" />
            </InputWrap>
          </AlignInput>

          <ButtonWrap>
            <MainButton>Send</MainButton>
          </ButtonWrap>
        </RightSection>
      </ResetContainer>

      <FooterBar />
    </PageBody>
  );
}
