import styled from "styled-components";
import Icon from "../../core/Icons";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 30vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 480px) {
    width: 80%;
    height: 30%;
    padding: 1vh 2vw;
  }
`;

const ModalHeader = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ModalFlex = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ModalTitle = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.1vw;

  @media (max-width: 480px) {
    font-size: 17px;
  }
`;

const CloseButton = styled.button`
  width: 1vw;
  height: auto;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 4vw;
  }
`;

const ModalLabels = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9vw;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const ModalInfos = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.9vw;
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const BuyButton = styled.button`
  width: 80%;
  height: 5vh;
  color: white;
  background-color: #a41e4d;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;

  @media (max-width: 480px) {
    height: 4vh;
    font-size: 16px;
    border-radius: 5px;
  }
`;

interface Package {
  id: number;
  label: string;
  labelColor: string;
  description: string;
  price: number;
  icon: string;
  backgroundImage: string;
}

interface PackageModalProps {
  pkg: Package;
  onClose: () => void;
}

export default function PackageModal({ pkg, onClose }: PackageModalProps) {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Buy Package Preview</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <ModalFlex>
          <ModalLabels>Package Type</ModalLabels>
          <ModalInfos>{pkg.label}</ModalInfos>
        </ModalFlex>

        <ModalFlex>
          <ModalLabels>Amount</ModalLabels>
          <ModalInfos>{pkg.price} USD</ModalInfos>
        </ModalFlex>

        <ModalFlex>
          <ModalLabels>Pay Amount</ModalLabels>
          <ModalInfos>{pkg.price} USD</ModalInfos>
        </ModalFlex>

        <ModalFlex>
          <ModalLabels>Pay By</ModalLabels>
          <ModalInfos>Register Wallet</ModalInfos>
        </ModalFlex>

        <BuyButton>Buy</BuyButton>
      </ModalContent>
    </ModalOverlay>
  );
}
