import styled from "styled-components";

export const ContainerWrap = styled.div`
  display: none;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
`;

export const ProfileContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 23vw;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 480px) {
    width: 95%;
    padding: 2vh 4vw;
    margin: 5px;
  }
`;

export const ProfilePreviewHeader = styled.text`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 18px);
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
  padding-top: 15px;

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 0;
  }
`;

export const ProfileInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  padding: 20px 0;
`;

export const ProfileImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #edc5d3;
  width: 2.6vw;
  height: auto;
  padding: 6px;

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
    padding: 0;
  }
`;

export const ProfileImage = styled.div`
  width: 2vw;
  height: auto;
  padding: 4px 2px;

  @media (max-width: 480px) {
    width: 40px;
    padding: 0;
  }
`;

export const EmailNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const ProfileEmail = styled.text`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 15px);
  padding-bottom: 4px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const ProfileUsernameLabel = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 15px);

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const ProfileUsername = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 15px);

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const PackageDate = styled.text`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 16px);
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const PackageDateContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  text-indent: 16px;
  background-color: #eeeeee;
  border-radius: 10px;
  height: 8%;
  width: 90%;
  margin: 10px 0;

  @media (max-width: 480px) {
    font-size: 14px;
    height: 4vh;
  }
`;

export const ReferralContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 10px;
  height: 8%;
  width: 90%;
  margin-top: 10px;

  @media (max-width: 480px) {
    height: 4vh;
    margin-bottom: 2vh;
  }
`;

export const ReferralContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  width: 90%;
  font-weight: 600;
  font-size: clamp(12px, 1vw, 16px);

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const CreateReferralButton = styled.button`
  width: 4vw;
  height: auto;
  padding: 6px 0;
  background-color: white;
  border: 1px solid #a41e4d;
  color: #a41e4d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    width: 16vw;
    height: 70%;
    font-size: 13px;
  }
`;
