import { useState } from "react";
import Icon from "../../core/Icons";

import {
  CartCheckoutContainer,
  CartContainer,
  CartHeader,
  CartItemBox,
  CartCheckbox,
  CartItemDetails,
  CartItemHeader,
  CartItemImage,
  CartItemInfo,
  CartItemLabel,
  CartItemPV,
  CartItemTitle,
  CartLabels,
  CartLabelWrap,
  CartTitle,
  CartItemWrap,
  ContainerWrapper,
  IconWrap,
  OrderQuantityLabel,
  CartMainButton,
  OrderQuantityContainer,
  OrderQuantityInputWrap,
  OrderQuantityButtons,
  OrderQuantityInput,
  MathIcon,
  CheckoutAmountWrap,
  CheckoutLabel,
  CheckoutTotal,
  CheckoutHeader,
} from "./shoppingCartStyle";

interface ShoppingCartProps {
  onCheckoutClick?: () => void;
}

export default function ShoppingCart({ onCheckoutClick }: ShoppingCartProps) {
  const [amount, setAmount] = useState(1);
  const [inputValue, setInputValue] = useState("1");

  const handleIncrease = () => {
    const newAmount = amount + 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString());
  };

  const handleDecrease = () => {
    const newAmount = amount > 1 ? amount - 1 : 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 1) {
      setAmount(numericValue);
      setInputValue(value);
    } else {
      setInputValue(value);
    }
  };

  const handleFocus = () => {
    setInputValue("");
  };

  return (
    <ContainerWrapper>
      <CartContainer>
        <CartHeader>
          <IconWrap>
            <Icon name="cart" fill="#a41e4d" />
          </IconWrap>
          <CartTitle>Shopping Cart</CartTitle>
        </CartHeader>

        <CartLabelWrap>
          <CartLabels>Serial No.</CartLabels>
          <CartLabels>Product Name</CartLabels>
          <CartLabels>Image</CartLabels>
          <CartLabels>Type</CartLabels>
          <CartLabels>Order Quantity</CartLabels>
          <CartLabels>Point Value (PV)</CartLabels>
        </CartLabelWrap>

        <CartItemBox>
          <CartItemHeader>
            <CartCheckbox type="checkbox" />
            <CartItemTitle>Eye-K Spray (30ml)</CartItemTitle>
          </CartItemHeader>
          <CartItemWrap>
            <CartItemImage></CartItemImage>
            <CartItemDetails>
              <CartItemLabel>
                Serial No.
                <CartItemInfo>1</CartItemInfo>
              </CartItemLabel>
              <CartItemLabel>
                Product Name
                <CartItemInfo>Eye-K Spray (30ml)</CartItemInfo>
              </CartItemLabel>
              <CartItemLabel>
                Type
                <CartItemInfo>Normal Price</CartItemInfo>
              </CartItemLabel>
              <CartItemLabel>
                Point Value (PV)
                <CartItemPV>500.00 PV</CartItemPV>
              </CartItemLabel>
              <OrderQuantityLabel>
                Order Quantity
                <OrderQuantityContainer>
                  <OrderQuantityInputWrap>
                    <OrderQuantityButtons onClick={handleDecrease}>
                      <MathIcon>
                        <Icon name="minus" />
                      </MathIcon>
                    </OrderQuantityButtons>
                    <OrderQuantityInput
                      type="text"
                      value={inputValue}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    <OrderQuantityButtons onClick={handleIncrease}>
                      <MathIcon>
                        <Icon name="plus" />
                      </MathIcon>
                    </OrderQuantityButtons>
                  </OrderQuantityInputWrap>
                </OrderQuantityContainer>
              </OrderQuantityLabel>
            </CartItemDetails>
          </CartItemWrap>
        </CartItemBox>

        <CartItemBox>
          <CartItemHeader>
            <CartCheckbox type="checkbox" />
            <CartItemTitle>Eye-K Spray (30ml)</CartItemTitle>
          </CartItemHeader>
          <CartItemWrap>
            <CartItemImage></CartItemImage>
            <CartItemDetails>
              <CartItemLabel>
                Serial No.
                <CartItemInfo>1</CartItemInfo>
              </CartItemLabel>
              <CartItemLabel>
                Product Name
                <CartItemInfo>Eye-K Spray (30ml)</CartItemInfo>
              </CartItemLabel>
              <CartItemLabel>
                Type
                <CartItemInfo>Normal Price</CartItemInfo>
              </CartItemLabel>
              <CartItemLabel>
                Point Value (PV)
                <CartItemPV>500.00 PV</CartItemPV>
              </CartItemLabel>
              <OrderQuantityLabel>
                Order Quantity
                <OrderQuantityContainer>
                  <OrderQuantityInputWrap>
                    <OrderQuantityButtons onClick={handleDecrease}>
                      <MathIcon>
                        <Icon name="minus" />
                      </MathIcon>
                    </OrderQuantityButtons>
                    <OrderQuantityInput
                      type="text"
                      value={inputValue}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    <OrderQuantityButtons onClick={handleIncrease}>
                      <MathIcon>
                        <Icon name="plus" />
                      </MathIcon>
                    </OrderQuantityButtons>
                  </OrderQuantityInputWrap>
                </OrderQuantityContainer>
              </OrderQuantityLabel>
            </CartItemDetails>
          </CartItemWrap>
        </CartItemBox>
      </CartContainer>

      <CartCheckoutContainer>
        <CheckoutHeader>
          <CartCheckbox type="checkbox" />
          All
        </CheckoutHeader>

        <CheckoutAmountWrap>
          <CheckoutLabel>Total Amount</CheckoutLabel>
          <CheckoutTotal>500.00 PV</CheckoutTotal>
        </CheckoutAmountWrap>
        {onCheckoutClick && (
          <CartMainButton onClick={onCheckoutClick}>Check Out</CartMainButton>
        )}
      </CartCheckoutContainer>
    </ContainerWrapper>
  );
}
