import { CustomIconProps } from "./types";

export const CashIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3337 15.615C15.3614 15.615 14.535 15.271 13.8545 14.583C13.1739 13.895 12.8337 13.0596 12.8337 12.0767C12.8337 11.0938 13.1739 10.2584 13.8545 9.57036C14.535 8.88235 15.3614 8.53835 16.3337 8.53835C17.3059 8.53835 18.1323 8.88235 18.8128 9.57036C19.4934 10.2584 19.8337 11.0938 19.8337 12.0767C19.8337 13.0596 19.4934 13.895 18.8128 14.583C18.1323 15.271 17.3059 15.615 16.3337 15.615ZM8.16699 19.1534C7.52533 19.1534 6.97602 18.9224 6.51908 18.4605C6.06213 17.9985 5.83366 17.4432 5.83366 16.7945V7.3589C5.83366 6.7102 6.06213 6.15488 6.51908 5.69293C6.97602 5.23098 7.52533 5 8.16699 5H24.5003C25.142 5 25.6913 5.23098 26.1482 5.69293C26.6052 6.15488 26.8337 6.7102 26.8337 7.3589V16.7945C26.8337 17.4432 26.6052 17.9985 26.1482 18.4605C25.6913 18.9224 25.142 19.1534 24.5003 19.1534H8.16699ZM10.5003 16.7945H22.167C22.167 16.1458 22.3955 15.5905 22.8524 15.1285C23.3094 14.6666 23.8587 14.4356 24.5003 14.4356V9.7178C23.8587 9.7178 23.3094 9.48682 22.8524 9.02487C22.3955 8.56292 22.167 8.00759 22.167 7.3589H10.5003C10.5003 8.00759 10.2719 8.56292 9.81491 9.02487C9.35796 9.48682 8.80866 9.7178 8.16699 9.7178V14.4356C8.80866 14.4356 9.35796 14.6666 9.81491 15.1285C10.2719 15.5905 10.5003 16.1458 10.5003 16.7945ZM23.3337 23.8712H3.50033C2.85866 23.8712 2.30935 23.6402 1.85241 23.1783C1.39546 22.7163 1.16699 22.161 1.16699 21.5123V8.53835H3.50033V21.5123H23.3337V23.8712Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
