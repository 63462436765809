import { useState } from "react";
import Icon from "../../core/Icons";
import {
  AlignStatus,
  ContainerWrap,
  HistoryCategory,
  HistoryCategoryNavigation,
  HistoryCurrency,
  HistoryDisplayContainer,
  HistoryStatus,
  HistoryTitleContainer,
  MainContainer,
  PageNavigator,
  PageTitle,
  PrevButton,
  NextButton,
  CurrentPageCircle,
  HistoryCategoryMobileHidden,
  HistoryLabels,
  HistoryStatusLabel,
  HistoryDisplayText,
  HistoryIcon,
} from "./orderHistoryStyle";

export default function OrderHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 5;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <ContainerWrap>
      <PageTitle>
        <HistoryIcon>
          <Icon name="history" />
        </HistoryIcon>
        History
      </PageTitle>

      <MainContainer>
        <HistoryCategoryNavigation>
          <HistoryCategory>All</HistoryCategory>
          <HistoryCategory>Deposit</HistoryCategory>
          <HistoryCategory>Withdrawal</HistoryCategory>
          <HistoryCategory>Convert</HistoryCategory>
          <HistoryCategory>Transfer</HistoryCategory>
          <HistoryCategoryMobileHidden>Purchase</HistoryCategoryMobileHidden>
          <HistoryCategoryMobileHidden>Package</HistoryCategoryMobileHidden>
        </HistoryCategoryNavigation>

        <HistoryTitleContainer>
          <HistoryLabels>Type</HistoryLabels>
          <HistoryLabels>Date & Time</HistoryLabels>
          <HistoryLabels>Wallet Type</HistoryLabels>
          <HistoryLabels>Amount (USD)</HistoryLabels>
          <HistoryStatusLabel>Status</HistoryStatusLabel>
        </HistoryTitleContainer>

        <HistoryDisplayContainer>
          <HistoryDisplayText>Bonus</HistoryDisplayText>
          <HistoryDisplayText>2024-10-17 00:00:02</HistoryDisplayText>
          <HistoryDisplayText>Bonus Wallet</HistoryDisplayText>
          <HistoryCurrency>37.8</HistoryCurrency>
          <AlignStatus>
            <HistoryStatus>Completed</HistoryStatus>
          </AlignStatus>
        </HistoryDisplayContainer>

        <HistoryDisplayContainer>
          <HistoryDisplayText>Bonus</HistoryDisplayText>
          <HistoryDisplayText>2024-10-17 00:00:02</HistoryDisplayText>
          <HistoryDisplayText>Bonus Wallet</HistoryDisplayText>
          <HistoryCurrency>37.8</HistoryCurrency>
          <AlignStatus>
            <HistoryStatus>Completed</HistoryStatus>
          </AlignStatus>
        </HistoryDisplayContainer>

        <PageNavigator>
          <PrevButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            <Icon name="sidearrow" fill="" />
          </PrevButton>
          <CurrentPageCircle>{currentPage}</CurrentPageCircle>
          <NextButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Icon name="sidearrow" fill="" />
          </NextButton>
        </PageNavigator>
      </MainContainer>
    </ContainerWrap>
  );
}
