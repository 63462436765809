import { useState } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  CloseButton,
  InputWrap,
  ModalLabel,
  TitleStar,
  ModalInput,
  SubText,
  MainButton,
  NoteWrap,
  NoteHeader,
  NoteList,
  NotePoints,
} from "../reusable/modalStyle";
import Icon from "../../core/Icons";
import styled from "styled-components";
import ShowPassword from "../reusable/showPassword";

const TransferAmountContainer = styled.div`
  width: 100%;
  height: 5.5vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin: 2px 0;

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const TransferInputWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TransferButtons = styled.button`
  background: none;
  border: none;
  width: 4vw;
  height: 5.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const MathIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2vw;
  height: auto;

  @media (max-width: 480px) {
    width: 15px;
  }
`;

const TransferInput = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  text-align: center;
  background: none;
  border: none;
  outline: none;
  width: 80%;
  height: 5.5vh;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

type TransferProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function TransferModal({ isVisible, onClose }: TransferProps) {
  const [amount, setAmount] = useState(1);
  const [inputValue, setInputValue] = useState("1");

  const handleIncrease = () => {
    const newAmount = amount + 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString());
  };

  const handleDecrease = () => {
    const newAmount = amount > 1 ? amount - 1 : 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 1) {
      setAmount(numericValue);
      setInputValue(value);
    } else {
      setInputValue(value);
    }
  };

  const handleFocus = () => {
    setInputValue("");
  };

  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Transfer</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <InputWrap>
          <ModalLabel>
            Transfer To
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ModalInput type="text" placeholder="User Username" />
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Transfer Amount (USD)
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <TransferAmountContainer>
            <TransferInputWrap>
              <TransferButtons onClick={handleDecrease}>
                <MathIcon>
                  <Icon name="minus" fill="" />
                </MathIcon>
              </TransferButtons>
              <TransferInput
                type="text"
                value={inputValue}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <TransferButtons onClick={handleIncrease}>
                <MathIcon>
                  <Icon name="plus" fill="" />
                </MathIcon>
              </TransferButtons>
            </TransferInputWrap>
          </TransferAmountContainer>
          <SubText>
            Wallet Balance: USD
            <SubText>4.8</SubText>
          </SubText>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Remarks
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ModalInput type="text" placeholder="Remarks" />
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Security Password
            <TitleStar>*</TitleStar>
          </ModalLabel>
          <ShowPassword placeholder="Security Password" type="security" />
        </InputWrap>

        <NoteWrap>
          <NoteHeader>Note:</NoteHeader>
          <NoteList>
            <NotePoints>
              You can transfer to another user within the same group only.
            </NotePoints>
            <NotePoints>Minimum transfer amount is 10 USD.</NotePoints>
          </NoteList>
        </NoteWrap>

        <MainButton>Next</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
