import { CustomIconProps } from "./types";

export const TagIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.15 22C10.9 22 10.65 21.95 10.4 21.85C10.15 21.75 9.925 21.6 9.725 21.4L2.575 14.25C2.375 14.05 2.22917 13.8292 2.1375 13.5875C2.04583 13.3458 2 13.1 2 12.85C2 12.6 2.04583 12.35 2.1375 12.1C2.22917 11.85 2.375 11.625 2.575 11.425L11.375 2.6C11.5583 2.41667 11.775 2.27083 12.025 2.1625C12.275 2.05417 12.5333 2 12.8 2H19.975C20.525 2 20.9958 2.19583 21.3875 2.5875C21.7792 2.97917 21.975 3.45 21.975 4V11.175C21.975 11.4417 21.925 11.6958 21.825 11.9375C21.725 12.1792 21.5833 12.3917 21.4 12.575L12.575 21.4C12.375 21.6 12.15 21.75 11.9 21.85C11.65 21.95 11.4 22 11.15 22ZM11.15 20L19.975 11.15V4H12.825L4 12.85L11.15 20ZM17.475 8C17.8917 8 18.2458 7.85417 18.5375 7.5625C18.8292 7.27083 18.975 6.91667 18.975 6.5C18.975 6.08333 18.8292 5.72917 18.5375 5.4375C18.2458 5.14583 17.8917 5 17.475 5C17.0583 5 16.7042 5.14583 16.4125 5.4375C16.1208 5.72917 15.975 6.08333 15.975 6.5C15.975 6.91667 16.1208 7.27083 16.4125 7.5625C16.7042 7.85417 17.0583 8 17.475 8Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
