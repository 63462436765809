import styled from "styled-components";
import { Link } from "react-router-dom";

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const LoginContainer = styled.div`
  background-color: white;
  width: 85%;
  height: auto;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4vw;
  padding: 8vh 6vw;
  margin-top: 6vh;

  @media (max-width: 480px) {
    padding: 1vh 4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 480px) {
    padding: 3vh 1vw;
  }
`;

export const MainText = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: #a41e4d;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const GreetText = styled.p`
  width: 15rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #737373;
  margin-top: 1vh;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 480px) {
    align-items: center;
    justify-content: space-between;
  }
`;

export const AlignInput = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
    width: 100%;
  }
`;

export const SubWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
  }
`;

export const UserText = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const PasswordText = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ResetButton = styled(Link)`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  color: #a41e4d;

  @media (max-width: 480px) {
    position: absolute;
    bottom: 43%;
    right: 14%;
    font-size: 12px;
  }
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vh 0;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1vh 0;
  }
`;

export const InputBox = styled.input`
  background-color: #eeeeee;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  border-radius: 10px;
  height: 6vh;
  border: none;
  outline: none;
  margin: 1vh 0;
  text-indent: 1.5vw;

  @media (max-width: 480px) {
    width: 100%;
    height: 5vh;
    text-indent: 20px;
    font-size: 14px;
  }
`;

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3vh 0;

  @media (max-width: 480px) {
    width: 100%;
    flex-direction: row;
    justify-content: start;
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const RememberCheckbox = styled.text`
  width: 20px;
  height: 20px;
  border: 2px solid #a41e4d;
  border-radius: 4px;
  display: inline-block;
  position: relative;

  ${HiddenCheckbox}:checked + & {
    background-color: #a41e4d;

    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 7px;
    }
  }
`;

export const RememberLabel = styled.text`
  margin-left: 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #737373;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2vh 0;

  @media (max-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MainButton = styled.button`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  width: 220px;
  height: 7vh;
  border-radius: 12px;
  border: none;
  color: white;
  background-color: #a41e4d;

  @media (max-width: 480px) {
    font-size: 16px;
    width: 80%;
    height: 4.5vh;
  }
`;

export const RegisterLink = styled(Link)`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #a41e4d;
  padding-left: 3vw;

  @media (max-width: 480px) {
    padding-top: 2vh;
    padding-left: 0;
    font-size: 18px;
  }
`;
