import {
  EditInfoButton,
  AccountInfoWrap,
  EditButton,
  InfoData,
  InfoHeader,
  InfoIcon,
  InfoLabels,
  InfoLabelTitle,
  InformationContainer,
  InformationTitle,
  PackageContainer,
  PackageHeader,
  PackageIcon,
  PageBody,
  RankDescription,
  RankIcon,
  RankText,
  SecondaryTitle,
  SubRankIcon,
  UpgradeCost,
  UpgradeInfo,
  UpgradeRankButton,
  UpgradeSection,
  EditButtonWrap,
  TitleAlign,
} from "./mobileProfileStyle";
import MobileNavigation from "../../../components/mobile/mobileNavigation";
import MobileHeader from "../../../components/mobile/mobileHeader";
import DashProfile from "../../../components/dashboard/profile/dashProfile";
import Icon from "../../../core/Icons";
import AccountInfo from "../../../components/profile/accountInfo";
import { useState } from "react";
import AccPassModal from "../../../components/side/accPassModal";
import SecurityPassModal from "../../../components/side/securityPassModal";
import KycModal from "../../../components/side/kycModal";

export default function MobileProfilePage() {
  const [activeComponent, setActiveComponent] = useState(false);
  const [isAccountPasswordModalVisible, setIsAccountPasswordModalVisible] =
    useState(false);
  const [isSecurityPasswordModalVisible, setIsSecurityPasswordModalVisible] =
    useState(false);
  const [isKycModalVisible, setIsKycModalVisible] = useState(false);

  const handleActiveClick = () => {
    setActiveComponent(true);
  };

  return (
    <PageBody>
      <MobileHeader title="Profile" />

      {activeComponent ? (
        <AccountInfo />
      ) : (
        <>
          <MobileNavigation activeSection="Profile" onLinkClick={() => {}} />

          <PackageContainer>
            <PackageHeader>
              <PackageIcon>
                <Icon name="crown" fill="#a41e4d" />
              </PackageIcon>
              <SecondaryTitle>Package Level</SecondaryTitle>
            </PackageHeader>
            <RankIcon src="./images/purple-rank.png" />
            <RankText>BUSINESS</RankText>
            <RankDescription>For growing team</RankDescription>

            <UpgradeSection>
              <SubRankIcon src="./images/gold-rank.png" />
              <UpgradeInfo>
                Upgrade package with only
                <UpgradeCost>USD 2,000</UpgradeCost>
              </UpgradeInfo>
              <UpgradeRankButton>Upgrade</UpgradeRankButton>
            </UpgradeSection>
          </PackageContainer>

          <DashProfile />

          <InformationContainer>
            <InfoHeader>
              <TitleAlign>
                <InfoIcon>
                  <Icon name="profile" fill="#a41e4d" />
                </InfoIcon>
                <InformationTitle>Account Information</InformationTitle>
              </TitleAlign>
              <EditButton onClick={handleActiveClick}>Edit</EditButton>
            </InfoHeader>
            <AccountInfoWrap>
              <InfoLabelTitle>Personal Details</InfoLabelTitle>
              <InfoLabels>
                Username:
                <InfoData>Tester22</InfoData>
              </InfoLabels>
              <InfoLabels>
                Date:
                <InfoData>07 Oct 2024</InfoData>
              </InfoLabels>
            </AccountInfoWrap>
            <EditButtonWrap>
              <EditInfoButton
                onClick={() => setIsAccountPasswordModalVisible(true)}
              >
                Edit Password
              </EditInfoButton>
              <EditInfoButton
                onClick={() => setIsSecurityPasswordModalVisible(true)}
              >
                Edit Security Password
              </EditInfoButton>
              <EditInfoButton onClick={() => setIsKycModalVisible(true)}>
                Verify KYC
              </EditInfoButton>
            </EditButtonWrap>
          </InformationContainer>
        </>
      )}

      <AccPassModal
        isVisible={isAccountPasswordModalVisible}
        onClose={() => setIsAccountPasswordModalVisible(false)}
      />
      <SecurityPassModal
        isVisible={isSecurityPasswordModalVisible}
        onClose={() => setIsSecurityPasswordModalVisible(false)}
      />
      <KycModal
        isVisible={isKycModalVisible}
        onClose={() => setIsKycModalVisible(false)}
      />
    </PageBody>
  );
}
