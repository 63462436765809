import { useState, useEffect } from "react";
import FooterBar from "../../components/footer/footer";
import SideBar from "../../components/side/sideBar";
import MobileHeader from "../../components/mobile/mobileHeader";
import {
  AlignComponents,
  AlignContainers,
  AlignPage,
  NavigationWrap,
  PageWrap,
  SideWrap,
  PageBody,
} from "../../components/reusable/pageStyle";
import PageNav from "../../components/reusable/nav/pageNav";
import BannerCard from "../../components/reusable/banner/BannerCard";
import RewardsCard from "../../components/rewards/rewardsCard";
import WalletCard from "../../components/wallet/walletCard";
import PlacementTreeContainer from "../../components/rewards/genealogy/placementTreeComponent";

export default function RewardsPage() {
  const [activeSection, setActiveSection] = useState<string>("Dashboard");
  const [isMobile, setIsMobile] = useState(false);
  const [showPlacementTree, setShowPlacementTree] = useState(false);
  const [activeRewardComponent, setActiveRewardComponent] = useState<
    string | null
  >(null);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);

      setActiveSection(isMobileView ? "Wallet" : "Dashboard");
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const onGoToPlacementTree = () => {
    setShowPlacementTree(true);
  };

  const handleBackClick = () => {
    setShowPlacementTree(false);
    setActiveRewardComponent(null);
  };

  return (
    <PageBody>
      <AlignPage>
        <PageWrap>
          {isMobile && <MobileHeader title="Rewards" />}

          {!isMobile && (
            <SideWrap>
              <SideBar isMobile={isMobile} activeSection={activeSection} />
            </SideWrap>
          )}

          <AlignComponents>
            {!isMobile && (
              <NavigationWrap>
                <PageNav />
                <BannerCard />
              </NavigationWrap>
            )}

            <AlignContainers>
              {showPlacementTree ? (
                <PlacementTreeContainer onBackClick={handleBackClick} />
              ) : (
                <>
                  <RewardsCard
                    onGoToPlacementTree={onGoToPlacementTree}
                    setActiveRewardComponent={
                      isMobile ? setActiveRewardComponent : undefined
                    }
                  />
                  {!isMobile && <WalletCard />}
                </>
              )}
            </AlignContainers>
          </AlignComponents>
        </PageWrap>
      </AlignPage>

      {!isMobile && <FooterBar />}
      {isMobile && (
        <SideBar isMobile={isMobile} activeSection={activeSection} />
      )}
    </PageBody>
  );
}
