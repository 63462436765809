import { CustomIconProps } from "./types";

export const CloudIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 58 42" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4173 41.6666H14.7923C10.8743 41.6666 7.52669 40.3103 4.74961 37.5978C1.97253 34.8853 0.583984 31.5701 0.583984 27.652C0.583984 24.2937 1.59579 21.3013 3.6194 18.6749C5.64301 16.0485 8.29093 14.3694 11.5632 13.6374C12.6395 9.67631 14.7923 6.46867 18.0215 4.0145C21.2507 1.56034 24.9104 0.333252 29.0007 0.333252C34.0382 0.333252 38.3114 2.08777 41.8204 5.59679C45.3295 9.10582 47.084 13.3791 47.084 18.4166C50.0548 18.761 52.5198 20.0419 54.4788 22.2593C56.4378 24.4767 57.4173 27.0708 57.4173 30.0416C57.4173 33.2708 56.2871 36.0155 54.0267 38.276C51.7663 40.5364 49.0215 41.6666 45.7923 41.6666H31.584V23.1958L35.7173 27.1999L39.334 23.5833L29.0007 13.2499L18.6673 23.5833L22.284 27.1999L26.4173 23.1958V41.6666Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
