import Icon from "../../../core/Icons";
import {
  BackButton,
  BackButtonText,
  BackIconWrap,
  GenealogyActives,
  GenealogyDetails,
  GenealogyHeader,
  GenealogyRank,
  GenealogyLabel,
  GenealogyTitle,
  GenealogyWrap,
  HeaderTitle,
  HeaderWrap,
  SponsorPageContainer,
  TitleAlign,
  VerifyTick,
  GenealogySubLabel,
  GenealogySubInfo,
  GenealogyMainDetails,
  GenealogySubDetails,
  AlignSubGenealogy,
  IconBackground,
  SearchInput,
  SearchWrapper,
  SearchIcon,
} from "./sponsorStyle";

interface SponsorContainerProps {
  setActiveComponent: (component: string | null) => void;
}

export default function SponsorContainer({
  setActiveComponent,
}: SponsorContainerProps) {
  const handleBackClick = () => {
    setActiveComponent(null);
  };
  return (
    <SponsorPageContainer>
      <BackButton onClick={handleBackClick}>
        <BackIconWrap>
          <Icon name="back" fill="#737373" />
        </BackIconWrap>

        <BackButtonText>Back to Rewards</BackButtonText>
      </BackButton>

      <HeaderWrap>
        <TitleAlign>
          <IconBackground>
            <Icon name="branch" fill="#a41e4d" />
          </IconBackground>
          <HeaderTitle>Genealogy</HeaderTitle>
        </TitleAlign>

        <SearchWrapper>
          <SearchIcon>
            <Icon name="search" fill="#737373" />
          </SearchIcon>
          <SearchInput placeholder="Search User" />
        </SearchWrapper>
      </HeaderWrap>

      <GenealogyMainDetails>
        <GenealogyDetails>
          <GenealogyWrap>
            <GenealogyRank src="/images/purple-rank.png" />
            <GenealogyHeader>
              <GenealogyTitle>
                MY82196868
                <VerifyTick>
                  <Icon name="tick" fill="#27B934" />
                </VerifyTick>
              </GenealogyTitle>
              <GenealogyLabel>Joined Date: 07 October 2024</GenealogyLabel>
              <GenealogyLabel>Email Address: testing1@gmail.com</GenealogyLabel>
            </GenealogyHeader>
          </GenealogyWrap>

          <GenealogyActives>3 Active</GenealogyActives>
        </GenealogyDetails>

        <GenealogySubLabel>
          Personal Investment:
          <GenealogySubInfo>USD 1,000.00</GenealogySubInfo>
        </GenealogySubLabel>
        <GenealogySubLabel>
          Group Referral:
          <GenealogySubInfo>3</GenealogySubInfo>
        </GenealogySubLabel>
        <GenealogySubLabel>
          Total Product Purcahsed:
          <GenealogySubInfo>0</GenealogySubInfo>
        </GenealogySubLabel>
      </GenealogyMainDetails>

      <AlignSubGenealogy>
        <GenealogySubDetails>
          <GenealogyDetails>
            <GenealogyWrap>
              <GenealogyRank src="/images/purple-rank.png" />
              <GenealogyHeader>
                <GenealogyTitle>
                  MY861364641
                  <VerifyTick>
                    <Icon name="tick" fill="#27B934" />
                  </VerifyTick>
                </GenealogyTitle>
                <GenealogyLabel>Joined Date: 07 October 2024</GenealogyLabel>
                <GenealogyLabel>
                  Email Address: tester22@gmail.com
                </GenealogyLabel>
              </GenealogyHeader>
            </GenealogyWrap>

            <GenealogyActives>0 Active</GenealogyActives>
          </GenealogyDetails>

          <GenealogySubLabel>
            Personal Investment:
            <GenealogySubInfo>USD 1,000.00</GenealogySubInfo>
          </GenealogySubLabel>
          <GenealogySubLabel>
            Group Referral:
            <GenealogySubInfo>0</GenealogySubInfo>
          </GenealogySubLabel>
          <GenealogySubLabel>
            Total Product Purcahsed:
            <GenealogySubInfo>0</GenealogySubInfo>
          </GenealogySubLabel>
        </GenealogySubDetails>

        <GenealogySubDetails>
          <GenealogyDetails>
            <GenealogyWrap>
              <GenealogyRank src="/images/silver-rank.png" />
              <GenealogyHeader>
                <GenealogyTitle>
                  MY861364641
                  <VerifyTick>
                    <Icon name="tick" fill="#27B934" />
                  </VerifyTick>
                </GenealogyTitle>
                <GenealogyLabel>Joined Date: 07 October 2024</GenealogyLabel>
                <GenealogyLabel>
                  Email Address: tester22@gmail.com
                </GenealogyLabel>
              </GenealogyHeader>
            </GenealogyWrap>

            <GenealogyActives>0 Active</GenealogyActives>
          </GenealogyDetails>

          <GenealogySubLabel>
            Personal Investment:
            <GenealogySubInfo>USD 1,000.00</GenealogySubInfo>
          </GenealogySubLabel>
          <GenealogySubLabel>
            Group Referral:
            <GenealogySubInfo>0</GenealogySubInfo>
          </GenealogySubLabel>
          <GenealogySubLabel>
            Total Product Purcahsed:
            <GenealogySubInfo>0</GenealogySubInfo>
          </GenealogySubLabel>
        </GenealogySubDetails>

        <GenealogySubDetails>
          <GenealogyDetails>
            <GenealogyWrap>
              <GenealogyRank src="/images/silver-rank.png" />
              <GenealogyHeader>
                <GenealogyTitle>
                  MY861364641
                  <VerifyTick>
                    <Icon name="tick" fill="#27B934" />
                  </VerifyTick>
                </GenealogyTitle>
                <GenealogyLabel>Joined Date: 07 October 2024</GenealogyLabel>
                <GenealogyLabel>
                  Email Address: tester22@gmail.com
                </GenealogyLabel>
              </GenealogyHeader>
            </GenealogyWrap>

            <GenealogyActives>0 Active</GenealogyActives>
          </GenealogyDetails>

          <GenealogySubLabel>
            Personal Investment:
            <GenealogySubInfo>USD 1,000.00</GenealogySubInfo>
          </GenealogySubLabel>
          <GenealogySubLabel>
            Group Referral:
            <GenealogySubInfo>0</GenealogySubInfo>
          </GenealogySubLabel>
          <GenealogySubLabel>
            Total Product Purcahsed:
            <GenealogySubInfo>0</GenealogySubInfo>
          </GenealogySubLabel>
        </GenealogySubDetails>
      </AlignSubGenealogy>
    </SponsorPageContainer>
  );
}
