import { useState, useEffect } from "react";
import Icon from "../../../core/Icons";
import {
  BackButton,
  BackButtonText,
  BackIconWrap,
  SmallContainer,
  HeaderWrap,
  IconBackground,
  HeaderTitle,
  BonusGridWrap,
  MonthBonusButton,
  ArrowIcon,
  DisplayedBonus,
  AlignBonusInfo,
  DisplayBonusTitle,
  DisplayBonusText,
  DisplayBonusAmount,
  TitleAlign,
  SelectedDayTitle,
  PageNavigator,
  PrevButton,
  CurrentPageCircle,
  NextButton,
  DisplayedBonusWrap,
  DisplayBonusDate,
} from "./commissionStyle";

interface CommissionProps {
  setActiveComponent: (component: string | null) => void;
}

export default function ProductPointBonus({
  setActiveComponent,
}: CommissionProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const totalPages = 5;

  const monthData = [
    {
      month: "October 2024",
      days: [
        {
          date: "10 October 2024",
          bonuses: [{ email: "user1@example.com", bonus: 120.0 }],
        },
        {
          date: "20 October 2024",
          bonuses: [
            { email: "user2@example.com", bonus: 85.0 },
            { email: "user3@example.com", bonus: 60.0 },
          ],
        },
      ],
    },
  ];

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleBackClick = () => {
    if (selectedDay) {
      setSelectedDay(null);
    } else if (selectedMonth) {
      setSelectedMonth(null);
    } else {
      setActiveComponent(null);
    }
  };

  const renderMonthContent = () => {
    const selectedMonthData = monthData.find(
      (month) => month.month === selectedMonth
    );

    if (!selectedMonthData) return null;

    return (
      <BonusGridWrap>
        {selectedMonthData.days.map((day) => (
          <MonthBonusButton
            key={day.date}
            onClick={() => setSelectedDay(day.date)}
          >
            {day.date}
            <ArrowIcon>
              <Icon name="sidearrow" fill="" />
            </ArrowIcon>
          </MonthBonusButton>
        ))}
      </BonusGridWrap>
    );
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderDayDetails = () => {
    const selectedMonthData = monthData.find(
      (month) => month.month === selectedMonth
    );
    const selectedDayData = selectedMonthData?.days.find(
      (day) => day.date === selectedDay
    );

    if (!selectedDayData) return null;

    return (
      <BonusGridWrap>
        {selectedDayData.bonuses.map((bonus, index) => (
          <DisplayedBonus key={index}>
            <AlignBonusInfo>
              <DisplayBonusTitle>Distributed</DisplayBonusTitle>
              <DisplayBonusText>Email Address:</DisplayBonusText>
              <DisplayBonusText>{bonus.email}</DisplayBonusText>
            </AlignBonusInfo>
            <DisplayBonusAmount>{bonus.bonus.toFixed(2)}</DisplayBonusAmount>
          </DisplayedBonus>
        ))}
      </BonusGridWrap>
    );
  };

  const renderMobileData = () => {
    const groupedBonuses = monthData
      .flatMap((month) => month.days)
      .reduce((acc, day) => {
        acc[day.date] = day.bonuses;
        return acc;
      }, {} as Record<string, { email: string; bonus: number }[]>);

    if (!Object.keys(groupedBonuses).length) {
      return <p>No bonuses available.</p>;
    }

    return (
      <BonusGridWrap>
        {Object.entries(groupedBonuses).map(([date, bonuses]) => (
          <DisplayedBonusWrap key={date}>
            <DisplayBonusDate>{date}</DisplayBonusDate>

            {bonuses.map((bonus, index) => (
              <DisplayedBonus key={index}>
                <AlignBonusInfo>
                  <DisplayBonusTitle>Distributed</DisplayBonusTitle>
                  <DisplayBonusText>Email Address:</DisplayBonusText>
                  <DisplayBonusText>{bonus.email}</DisplayBonusText>
                </AlignBonusInfo>
                <DisplayBonusAmount>
                  {bonus.bonus.toFixed(2)}
                </DisplayBonusAmount>
              </DisplayedBonus>
            ))}
          </DisplayedBonusWrap>
        ))}
      </BonusGridWrap>
    );
  };

  if (isMobile) {
    return (
      <SmallContainer>
        <BackButton onClick={() => setActiveComponent(null)}>
          <BackIconWrap>
            <Icon name="back" fill="#737373" />
          </BackIconWrap>
          <BackButtonText>Back</BackButtonText>
        </BackButton>
        <HeaderWrap>
          <TitleAlign>
            <IconBackground>
              <Icon name="cash" fill="#a41e4d" />
            </IconBackground>
            <HeaderTitle>Product Point Bonus</HeaderTitle>
          </TitleAlign>
        </HeaderWrap>
        {renderMobileData()}

        <PageNavigator>
          <PrevButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            <Icon name="sidearrow" fill="" />
          </PrevButton>
          <CurrentPageCircle>{currentPage}</CurrentPageCircle>
          <NextButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Icon name="sidearrow" fill="" />
          </NextButton>
        </PageNavigator>
      </SmallContainer>
    );
  }

  return (
    <SmallContainer>
      <BackButton onClick={handleBackClick}>
        <BackIconWrap>
          <Icon name="back" fill="#737373" />
        </BackIconWrap>
        <BackButtonText>Back</BackButtonText>
      </BackButton>

      <HeaderWrap>
        <TitleAlign>
          <IconBackground>
            <Icon name="cash" fill="#a41e4d" />
          </IconBackground>
          <HeaderTitle>Product Point Bonus</HeaderTitle>
        </TitleAlign>
        {selectedDay && <SelectedDayTitle>{selectedDay}</SelectedDayTitle>}
      </HeaderWrap>

      {selectedDay ? (
        renderDayDetails()
      ) : selectedMonth ? (
        renderMonthContent()
      ) : (
        <BonusGridWrap>
          {monthData.map((month) => (
            <MonthBonusButton
              key={month.month}
              onClick={() => setSelectedMonth(month.month)}
            >
              {month.month}
              <ArrowIcon>
                <Icon name="sidearrow" fill="" />
              </ArrowIcon>
            </MonthBonusButton>
          ))}
        </BonusGridWrap>
      )}

      <PageNavigator>
        <PrevButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          <Icon name="sidearrow" fill="" />
        </PrevButton>
        <CurrentPageCircle>{currentPage}</CurrentPageCircle>
        <NextButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <Icon name="sidearrow" fill="" />
        </NextButton>
      </PageNavigator>
    </SmallContainer>
  );
}
