import Icon from "../../core/Icons";
import {
  CategoryWrap,
  ContainerNavigation,
  IconWrap,
  SupportCategory,
  SupportContainer,
  TicketButton,
  TicketLabels,
  TicketLabelWrap,
} from "./customerSupportStyle";

export default function CustomerSupport() {
  return (
    <SupportContainer>
      <CategoryWrap>
        <ContainerNavigation>
          <SupportCategory>All</SupportCategory>
          <SupportCategory>Active Tickets</SupportCategory>
          <SupportCategory>Closed Tickets</SupportCategory>
        </ContainerNavigation>
        <TicketButton>
          <IconWrap>
            <Icon name="add" fill="white" />
          </IconWrap>
          Create Ticket
        </TicketButton>
      </CategoryWrap>

      <TicketLabelWrap>
        <TicketLabels>Ticket ID</TicketLabels>
        <TicketLabels>Title</TicketLabels>
        <TicketLabels>Date & Time</TicketLabels>
        <TicketLabels>Status</TicketLabels>
      </TicketLabelWrap>
    </SupportContainer>
  );
}
