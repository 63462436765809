import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FooterBar from "../../components/footer/footer";
import SideBar from "../../components/side/sideBar";
import MobileHeader from "../../components/mobile/mobileHeader";
import {
  AlignComponents,
  AlignContainers,
  AlignPage,
  NavigationWrap,
  PageWrap,
  SideWrap,
  PageBody,
  MobilePageLayout,
} from "../../components/reusable/pageStyle";
import WalletCard from "../../components/wallet/walletCard";
import PageNav from "../../components/reusable/nav/pageNav";
import BannerCard from "../../components/reusable/banner/BannerCard";
import OrderHistory from "../../components/history/orderHistory";
import MobileNavigation from "../../components/mobile/mobileNavigation";

export default function HistoryPage() {
  const [activeSection, setActiveSection] = useState<string>("Order");
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      console.log("Checking mobile view. isMobileView:", isMobileView);
      setIsMobile(isMobileView);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/history") {
      setActiveSection("Order");
    }
  }, [location]);

  useEffect(() => {
    console.log("isMobile state changed:", isMobile);
  }, [isMobile]);

  const handleMobileNavClick = (linkName: string) => {
    setActiveSection(linkName);
  };

  const renderContent = () => {
    console.log(
      "Rendering content. Active Section:",
      activeSection,
      "isMobile:",
      isMobile
    );
    switch (activeSection) {
      case "Order":
        return (
          <>
            <OrderHistory />
            {!isMobile && <WalletCard />}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <PageBody>
      <AlignPage>
        <PageWrap>
          {isMobile && <MobileHeader title="History" />}

          {!isMobile ? (
            <>
              <SideWrap>
                <SideBar isMobile={isMobile} activeSection={activeSection} />
              </SideWrap>
              <AlignComponents>
                <NavigationWrap>
                  <PageNav />
                  <BannerCard />
                </NavigationWrap>
                <AlignContainers>{renderContent()}</AlignContainers>
              </AlignComponents>
            </>
          ) : (
            <MobilePageLayout>
              {renderContent()}
              <MobileNavigation
                activeSection={activeSection}
                onLinkClick={handleMobileNavClick}
              />
            </MobilePageLayout>
          )}
        </PageWrap>
      </AlignPage>

      {!isMobile && <FooterBar />}
    </PageBody>
  );
}
