import { CustomIconProps } from "./types";

export const TransferIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.2058 26.5627C22.6072 25.7835 22.9141 24.9631 23.1266 24.1013C23.3391 23.2394 23.4454 22.3481 23.4454 21.4273C23.4454 19.5384 23.0322 17.8089 22.2058 16.2388C21.3794 14.6686 20.2579 13.3641 18.8412 12.3252L15.5829 15.5835V4.25021H26.9162L23.6579 7.50855C24.8857 8.61827 25.8655 9.94049 26.5975 11.4752C27.3294 13.0099 27.6954 14.6745 27.6954 16.469C27.6954 18.6176 27.1936 20.5655 26.1902 22.3127C25.1867 24.0599 23.8586 25.4766 22.2058 26.5627ZM7.08288 29.7502L10.3412 26.4919C9.08982 25.3822 8.10406 24.0599 7.38392 22.5252C6.66378 20.9905 6.30371 19.3259 6.30371 17.5315C6.30371 15.3829 6.80545 13.4349 7.80892 11.6877C8.81239 9.94049 10.1523 8.52382 11.8287 7.43771C11.4273 8.21688 11.1145 9.03737 10.8902 9.89917C10.6659 10.761 10.5537 11.6523 10.5537 12.5731C10.5537 14.462 10.9728 16.1915 11.811 17.7617C12.6492 19.3318 13.7648 20.6363 15.1579 21.6752L18.4162 18.4169V29.7502H7.08288Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
