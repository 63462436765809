import Icon from "../../../core/Icons";

import { AlignContent, IconWrap, BannerContainer } from "./BannerCardStyle";

export default function BannerCard() {
  return (
    <BannerContainer>
      <AlignContent>
        <IconWrap>
          <Icon name="speaker" fill="" />
        </IconWrap>
      </AlignContent>
    </BannerContainer>
  );
}
