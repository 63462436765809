import { useState } from "react";
import Icon from "../../core/Icons";
import {
  IconWrap,
  ContainerTitle,
  PackagesContainer,
  HeaderWrap,
  CardWrap,
  PackageIcon,
  BuyButton,
  PriceTag,
  PriceLabel,
  PackageDescription,
  PackageCard,
  PackageLabel,
} from "./packageSelectionStyle";
import PackageModal from "./packageModal";

export interface Package {
  id: number;
  label: string;
  labelColor: string;
  description: string;
  price: number;
  icon: string;
  backgroundImage: string;
}

export default function PackageSelection() {
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

  const packages: Package[] = [
    {
      id: 1,
      label: "ENTREPRENEURS",
      labelColor: "#FFD700",
      description: "For larger team",
      price: 2000,
      icon: "/images/gold-rank.png",
      backgroundImage: "/images/golden-background.png",
    },
    {
      id: 2,
      label: "BUSINESS",
      labelColor: "#7C3BFF",
      description: "For growing team",
      price: 1000,
      icon: "/images/purple-rank.png",
      backgroundImage: "",
    },
    {
      id: 3,
      label: "FAMILY",
      labelColor: "#6C757D",
      description: "For small team",
      price: 500,
      icon: "/images/silver-rank.png",
      backgroundImage: "",
    },
    {
      id: 4,
      label: "PREMIUM",
      labelColor: "#CD7F32",
      description: "A simple way to get started",
      price: 100,
      icon: "/images/bronze-rank.png",
      backgroundImage: "",
    },
  ];

  const handleBuyClick = (pkg: Package) => {
    setSelectedPackage(pkg);
  };

  const closeModal = () => {
    setSelectedPackage(null);
  };

  return (
    <PackagesContainer>
      <HeaderWrap>
        <IconWrap>
          <Icon name="crown" fill="#a41e4d" />
        </IconWrap>
        <ContainerTitle>Package</ContainerTitle>
      </HeaderWrap>

      <CardWrap>
        {packages.map((pkg) => (
          <PackageCard
            key={pkg.id}
            backgroundImage={
              pkg.label === "ENTREPRENEURS" ? pkg.backgroundImage : ""
            }
          >
            <PackageIcon src={pkg.icon} />
            <PackageLabel color={pkg.labelColor}>{pkg.label}</PackageLabel>
            <PackageDescription>{pkg.description}</PackageDescription>
            <PriceLabel>
              USD
              <PriceTag>{pkg.price}</PriceTag>
            </PriceLabel>
            <BuyButton onClick={() => handleBuyClick(pkg)}>Buy Now</BuyButton>
          </PackageCard>
        ))}
      </CardWrap>

      {selectedPackage && (
        <PackageModal pkg={selectedPackage} onClose={closeModal} />
      )}
    </PackagesContainer>
  );
}
