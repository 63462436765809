import styled from "styled-components";

export const ContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    align-items: center;
  }
`;

export const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 60vh;
  border-radius: 10px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    width: 100vw;
    margin: 1vh 0 0 0;
    padding: 0 2vw;
    border-radius: 0;
    background: none;
  }
`;

export const CartCheckoutContainer = styled.div`
  display: none;

  @media (max-width: 480px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 80px;
    padding: 10px 20px 15px 20px;
    background-color: white;
    box-shadow: 0 -6px 6px -1px rgba(0, 0, 0, 0.2),
      0 -3px 4px -1px rgba(0, 0, 0, 0.1);
  }
`;

export const CheckoutHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
`;

export const CheckoutAmountWrap = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
  }
`;

export const CheckoutLabel = styled.text`
  font-weight: 400;
  font-size: 13px;
  padding-bottom: 2px;
`;

export const CheckoutTotal = styled.text`
  font-weight: 600;
  font-size: 20px;
  color: #a41e4d;
  padding-top: 2px;

  @media (max-width: 480px) {
  }
`;

export const CartHeader = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  width: 2vw;
  height: auto;
  padding: 8px;
  border-radius: 100%;
  background-color: #edc5d3;
`;

export const CartTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1vw, 20px);
  margin-left: 10px;
`;

export const CartLabelWrap = styled.div`
  width: 95%;
  border-radius: 10px;
  height: auto;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 20px;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const CartLabels = styled.div`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  text-align: center;
`;

export const CartItemBox = styled.div`
  display: none;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    height: 20vh;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 1vh;
  }
`;

export const CartItemHeader = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 2vh 4vw 1vh 4vw;
  }
`;

export const CartItemWrap = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
  }
`;

export const CartCheckbox = styled.input`
  margin-right: 2vw;
`;

export const CartItemTitle = styled.text`
  @media (max-width: 480px) {
    margin-left: 2vw;
  }
`;

export const CartItemImage = styled.div`
  @media (max-width: 480px) {
    width: 28vw;
    height: 12vh;
    background-color: #eeeeee;
  }
`;

export const CartItemDetails = styled.div`
  @media (max-width: 480px) {
    padding: 0 4vw 0 2vw;
  }
`;

export const CartItemLabel = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
    padding: 0.4vw 0;

    font-weight: 600;
    font-size: 12px;
    color: #737373;
  }
`;

export const OrderQuantityLabel = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
    padding: 2vh 0;

    font-weight: 600;
    font-size: 12px;
    color: #737373;
  }
`;

export const CartItemInfo = styled.text`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);

  @media (max-width: 480px) {
    font-size: 13px;
    color: black;
  }
`;

export const CartItemPV = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
  color: #a41e4d;

  @media (max-width: 480px) {
  }
`;

export const OrderQuantityContainer = styled.div`
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    width: 18vw;
    height: 3vh;
  }
`;
export const OrderQuantityInputWrap = styled.div`
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const OrderQuantityButtons = styled.button`
  @media (max-width: 480px) {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
export const MathIcon = styled.div`
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: auto;
  }
`;
export const OrderQuantityInput = styled.input`
  @media (max-width: 480px) {
    background-color: #eeeeee;
    width: 10vw;
    height: 100%;

    font-weight: 400;
    font-size: 14px;
    text-align: center;
    border: none;
    outline: none;
  }
`;

export const CartMainButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 18px);
  width: 11vw;
  height: 5vh;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #a41e4d;
  cursor: pointer;
  margin: 20px 0;

  @media (max-width: 480px) {
    font-size: 16px;
    width: 40vw;
  }
`;
