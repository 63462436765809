import { CustomIconProps } from "./types";

export const PlusIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 18V10H0V8H8V0H10V8H18V10H10V18H8Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
