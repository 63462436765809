import styled from "styled-components";

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 66vh;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
`;

export const CategoryWrap = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerNavigation = styled.div`
  width: 95%;
  height: 25px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  width: 1.2vw;
  height: auto;
  margin-right: 10px;
`;

export const TicketButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  width: 10vw;
  height: auto;
  padding: 10px 5px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #a41e4d;
  cursor: pointer;
`;

export const SupportCategory = styled.button`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  color: #737373;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  padding-bottom: 5px;

  &:hover {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  &:focus {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }
`;

export const TicketLabelWrap = styled.div`
  width: 95%;
  border-radius: 10px;
  height: auto;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 20px;
`;

export const TicketLabels = styled.div`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  text-align: center;
`;
