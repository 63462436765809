import styled from "styled-components";

export const PageBody = styled.div`
  background-color: silver;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;

export const MobilePageLayout = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 15vh - 80px);
    overflow-y: auto;
  }
`;

export const AlignPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageWrap = styled.div`
  padding-top: 30px;
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3vh;

  @media (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
`;

export const SideWrap = styled.div`
  width: 100%;
`;

export const AlignComponents = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const NavigationWrap = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const AlignContainers = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
`;

export const AlignColumnContainers = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
`;
