import Icon from "../../core/Icons";
import {
  AlignHistories,
  AlignItems,
  ButtonWrap,
  CapWrap,
  ConvertIcon,
  DepositIcon,
  DollarsLabel,
  ExpandButton,
  HistoryBox,
  HistoryContainer,
  HistoryIconBox,
  HistoryIconWrap,
  HistoryInfo,
  HistoryTitle,
  MobileWalletContainer,
  ProductWalletLabel,
  ProgressCap,
  RedeemButton,
  RedeemWrap,
  SubWalletAmount,
  SubWalletLabel,
  TransactionStatus,
  TransactionTime,
  TransferIcon,
  WalletAmount,
  WalletAmountLabel,
  WalletButtons,
  WalletButtonsWrap,
  WalletContainer,
  WalletHeader,
  WalletInfoWrap,
  WalletMainLabel,
  WithdrawIcon,
} from "./walletCardStyle";
import { useState } from "react";
import ConversionModal from "./conversionModal";
import DepositModal from "./depositModal";
import TransferModal from "./transferModal";
import WithdrawModal from "./withdrawModal";

export default function WalletCard() {
  const [activeModal, setActiveModal] = useState<
    "deposit" | "withdraw" | "transfer" | "convert" | null
  >(null);

  const openModal = (
    modalName: "deposit" | "withdraw" | "transfer" | "convert"
  ) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <WalletContainer>
      <MobileWalletContainer>
        <WalletInfoWrap>
          <WalletHeader>
            <WalletMainLabel>Register Wallet</WalletMainLabel>
            <WalletAmountLabel>
              USD
              <WalletAmount>4.8</WalletAmount>
            </WalletAmountLabel>
          </WalletHeader>

          <AlignItems>
            <RedeemWrap>
              <ProductWalletLabel>Product Wallet</ProductWalletLabel>
              <RedeemButton>Redeem</RedeemButton>
            </RedeemWrap>
            <DollarsLabel>
              USD
              <SubWalletAmount>210.00</SubWalletAmount>
            </DollarsLabel>
          </AlignItems>

          <AlignItems>
            <SubWalletLabel>Bonus Wallet</SubWalletLabel>
            <DollarsLabel>
              USD
              <SubWalletAmount>38.2</SubWalletAmount>
            </DollarsLabel>
          </AlignItems>

          <AlignItems>
            <SubWalletLabel>Max Cap</SubWalletLabel>
            <CapWrap>
              <DollarsLabel>
                USD
                <SubWalletAmount>168.00</SubWalletAmount>
              </DollarsLabel>
              <DollarsLabel>/</DollarsLabel>
              <DollarsLabel>
                USD
                <SubWalletAmount>5,000.00</SubWalletAmount>
              </DollarsLabel>
            </CapWrap>
          </AlignItems>
          <ProgressCap></ProgressCap>
        </WalletInfoWrap>

        <WalletButtonsWrap>
          <ButtonWrap onClick={() => openModal("deposit")}>
            <WalletButtons>
              <DepositIcon>
                <Icon name="cash" fill="white" />
              </DepositIcon>
            </WalletButtons>
            Deposit
          </ButtonWrap>
          <ButtonWrap onClick={() => openModal("withdraw")}>
            <WalletButtons>
              <WithdrawIcon>
                <Icon name="withdraw" fill="white" />
              </WithdrawIcon>
            </WalletButtons>
            Withdrawal
          </ButtonWrap>
          <ButtonWrap onClick={() => openModal("transfer")}>
            <WalletButtons>
              <TransferIcon>
                <Icon name="transfer" fill="white" />
              </TransferIcon>
            </WalletButtons>
            Transfer
          </ButtonWrap>
          <ButtonWrap onClick={() => openModal("convert")}>
            <WalletButtons>
              <ConvertIcon>
                <Icon name="convert" fill="white" />
              </ConvertIcon>
            </WalletButtons>
            Convert
          </ButtonWrap>
        </WalletButtonsWrap>
      </MobileWalletContainer>

      {activeModal === "deposit" && (
        <DepositModal isVisible={true} onClose={closeModal} />
      )}
      {activeModal === "withdraw" && (
        <WithdrawModal isVisible={true} onClose={closeModal} />
      )}
      {activeModal === "transfer" && (
        <TransferModal isVisible={true} onClose={closeModal} />
      )}
      {activeModal === "convert" && (
        <ConversionModal isVisible={true} onClose={closeModal} />
      )}

      <HistoryContainer>
        <AlignHistories>
          <WalletMainLabel>Transaction History</WalletMainLabel>
          <ExpandButton>See All</ExpandButton>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>

        <AlignHistories>
          <HistoryIconBox>
            <HistoryIconWrap>
              <Icon name="box" fill="white" />
            </HistoryIconWrap>
          </HistoryIconBox>

          <HistoryBox>
            <HistoryInfo>
              <HistoryTitle>Bonus</HistoryTitle>
              <DollarsLabel>
                USD
                <SubWalletAmount>37.8</SubWalletAmount>
              </DollarsLabel>
            </HistoryInfo>
            <HistoryInfo>
              <TransactionTime>17 Oct 2024 12:00:02 AM</TransactionTime>
              <TransactionStatus>Completed</TransactionStatus>
            </HistoryInfo>
          </HistoryBox>
        </AlignHistories>
      </HistoryContainer>
    </WalletContainer>
  );
}
