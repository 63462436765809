import { Route, Routes } from "react-router-dom";
import "./App.css";
import LoginPage from "./screens/login/loginPage";
import RegisterPage from "./screens/signup/signupPage";
import ResetPage from "./screens/forgot password/resetPage";
import HomePage from "./screens/home/homePage";
import PackagePage from "./screens/package/packagePage";
import ProductPage from "./screens/product/productPage";
import StatusPage from "./screens/status/statusPage";
import RewardsPage from "./screens/rewards/rewardsPage";
import HistoryPage from "./screens/history/historyPage";
import ProfilePage from "./screens/profile/profilePage";
import SupportPage from "./screens/support/supportPage";
import CartPage from "./screens/cart/cartPage";
import MobileOrderPage from "./screens/mobile/order/mobileOrderPage";
import MobileProductPage from "./screens/mobile/product/mobileProductPage";
import MobileProfilePage from "./screens/mobile/profile/mobileProfilePage";
import MobilePackagePage from "./screens/mobile/package/mobilePackagePage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset" element={<ResetPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/package" element={<PackagePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/status" element={<StatusPage />} />
        <Route path="/rewards" element={<RewardsPage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/mobileOrder" element={<MobileOrderPage />} />
        <Route path="/mobileProduct" element={<MobileProductPage />} />
        <Route path="/mobileProfile" element={<MobileProfilePage />} />
        <Route path="/mobilePackage" element={<MobilePackagePage />} />
      </Routes>
    </>
  );
}

export default App;
