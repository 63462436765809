import React, { useState } from "react";
import styled from "styled-components";
import Icon from "../../core/Icons";

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;
  font-family: "Inter", sans-serif;
`;

interface CustomDropdownProps {
  isOpen: boolean;
}

const CustomDropdown = styled.div<CustomDropdownProps>`
  position: absolute;
  top: 100%;
  left: 0;
  background: #eeeeee;
  border-radius: 10px;
  width: 100%;
  padding: 0;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 1000;
`;

const CustomDropdownItem = styled.div`
  font-weight: 400;
  font-size: clamp(16px, 1vw, 20px);
  padding: 1vh 20px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const IconWrap = styled.div`
  position: absolute;
  right: 4vw;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-30%);
  pointer-events: none;
  width: 1vw;
  height: auto;

  @media (max-width: 480px) {
    width: 4vw;
  }
`;

const CustomDropdownToggle = styled.div<{ selected: boolean }>`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 60px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#000" : "#757575")};

  @media (max-width: 480px) {
    height: 5vh;
    font-size: 14px;
  }
`;

type DropdownProps = {
  placeholder: string;
  options: string[];
};

export default function DropdownMenu({ placeholder, options }: DropdownProps) {
  const [selectedValue, setSelectedValue] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleOptionClick = (option: string) => {
    setSelectedValue(option);
    setDropdownOpen(false);
  };

  return (
    <DropdownWrap>
      <CustomDropdownToggle
        onClick={() => setDropdownOpen((prev) => !prev)}
        selected={!!selectedValue}
      >
        {selectedValue || placeholder}
        <IconWrap>
          <Icon name="arrow" />
        </IconWrap>
      </CustomDropdownToggle>

      <CustomDropdown isOpen={isDropdownOpen}>
        {options.map((option, index) => (
          <CustomDropdownItem
            key={index}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </CustomDropdownItem>
        ))}
      </CustomDropdown>
    </DropdownWrap>
  );
}
