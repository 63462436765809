import Icon from "../../core/Icons";
import {
  CheckoutButton,
  CheckoutContainer,
  ContainerNavigation,
  ContainerWrap,
  ProductCategory,
  ProductCheckbox,
  ProductContainer,
  ProductDisplayBox,
  ProductHeaderLabel,
  PvAmount,
  PvLabel,
  PvPlaceholder,
  QuantityInput,
  TotalAmount,
  TotalText,
  WrapTotal,
  PageNavigator,
  CurrentPageCircle,
  PrevButton,
  NextButton,
  PageTitle,
  ImageDisplay,
  ImageLabel,
  HeaderLabel,
  QuantityLabel,
  ProductName,
  ProductInfo,
} from "./productDisplayStyle";
import { useState } from "react";

export default function ProductDisplay() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 5;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <ContainerWrap>
      <PageTitle>All Product</PageTitle>
      <ProductContainer>
        <ContainerNavigation>
          <ProductCategory>All</ProductCategory>
          <ProductCategory>Normal Price</ProductCategory>
          <ProductCategory>Promotion</ProductCategory>
          <ProductCategory>Bundle</ProductCategory>
        </ContainerNavigation>

        <ProductHeaderLabel>
          <ProductCheckbox type="checkbox"></ProductCheckbox>
          <HeaderLabel>Serial No.</HeaderLabel>
          <HeaderLabel>Product Name</HeaderLabel>
          <ImageLabel>Image</ImageLabel>
          <HeaderLabel>Type</HeaderLabel>
          <QuantityLabel>Order Quantity</QuantityLabel>
          <PvLabel>Point Value (PV)</PvLabel>
        </ProductHeaderLabel>

        <ProductDisplayBox>
          <ProductCheckbox type="checkbox"></ProductCheckbox>
          <ProductInfo>10</ProductInfo>
          <ProductName>Quantum 19+ (850 ml)</ProductName>
          <ImageDisplay>Image</ImageDisplay>
          <ProductInfo>Normal Price</ProductInfo>
          <QuantityLabel>
            <QuantityInput></QuantityInput>
          </QuantityLabel>
          <PvAmount>750.00</PvAmount>
        </ProductDisplayBox>

        <ProductDisplayBox>
          <ProductCheckbox type="checkbox"></ProductCheckbox>
          <ProductInfo>10</ProductInfo>
          <ProductName>Quantum 19+ (850 ml)</ProductName>
          <ImageDisplay>Image</ImageDisplay>
          <ProductInfo>Normal Price</ProductInfo>
          <QuantityLabel>
            <QuantityInput></QuantityInput>
          </QuantityLabel>
          <PvAmount>750.00</PvAmount>
        </ProductDisplayBox>

        <ProductDisplayBox>
          <ProductCheckbox type="checkbox"></ProductCheckbox>
          <ProductInfo>10</ProductInfo>
          <ProductName>Quantum 19+ (850 ml)</ProductName>
          <ImageDisplay>Image</ImageDisplay>
          <ProductInfo>Normal Price</ProductInfo>
          <QuantityLabel>
            <QuantityInput></QuantityInput>
          </QuantityLabel>
          <PvAmount>750.00</PvAmount>
        </ProductDisplayBox>

        <PageNavigator>
          <PrevButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            <Icon name="sidearrow" fill="" />
          </PrevButton>
          <CurrentPageCircle>{currentPage}</CurrentPageCircle>
          <NextButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Icon name="sidearrow" fill="" />
          </NextButton>
        </PageNavigator>
      </ProductContainer>

      <CheckoutContainer>
        <WrapTotal>
          <TotalText>Total Amount</TotalText>
          <TotalAmount>
            0<PvPlaceholder>PV</PvPlaceholder>
          </TotalAmount>
        </WrapTotal>

        <CheckoutButton>Proceed to Cart</CheckoutButton>
      </CheckoutContainer>
    </ContainerWrap>
  );
}
