import {
  FooterBox,
  FooterText,
  FooterContent,
  TNC,
  TncWrap,
} from "./footerStyle";

export default function FooterBar() {
  return (
    <FooterBox>
      <FooterContent>
        <FooterText>Copyright 2024 Artech. All rights reserved.</FooterText>

        <TncWrap>
          <TNC>Terms and Conditions</TNC>
          <TNC>Privacy Policy</TNC>
        </TncWrap>
      </FooterContent>
    </FooterBox>
  );
}
