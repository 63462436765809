import Icon from "../../core/Icons";
import MainLogo from "../logo/logo";
import { AlignContent, HomeLink, IconWrap, NavBox } from "./navStyle";

export default function NavBar() {
  return (
    <NavBox>
      <AlignContent>
        <HomeLink to="/home">
          <MainLogo />
        </HomeLink>

        <IconWrap to="/">
          <Icon name="globe" />
        </IconWrap>
      </AlignContent>
    </NavBox>
  );
}
