import {
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  CloseButton,
  MainButton,
} from "../reusable/modalStyle";
import Icon from "../../core/Icons";
import styled from "styled-components";

const KYCTitle = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.4vw;
  color: #e3b41c;
  padding: 1vh 0;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const KycText = styled.text`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8vw;

  @media (max-width: 480px) {
    width: 80%;
    text-align: center;
    font-size: 11px;
  }
`;

const KYCIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vw;
  height: auto;
  padding: 1vh 0;

  @media (max-width: 480px) {
    width: 90px;
  }
`;

type TransferProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function WithdrawModal({ isVisible, onClose }: TransferProps) {
  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle></ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <KYCIconWrap>
          <Icon name="kyc" fill="#e3b41c" />
        </KYCIconWrap>

        <KYCTitle>Verify KYC</KYCTitle>

        <KycText>
          Please complete your KYC verification before proceeding with
          withdrawals.
        </KycText>

        <MainButton>Verify KYC Now</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
