import { CustomIconProps } from "./types";

export const SpeakerIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 13V11H20V13H16ZM17.2 20L14 17.6L15.2 16L18.4 18.4L17.2 20ZM15.2 8L14 6.4L17.2 4L18.4 5.6L15.2 8ZM3 15V9H7L12 4V20L7 15H3ZM10 8.85L7.85 11H5V13H7.85L10 15.15V8.85Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
