import { useState, useEffect } from "react";
import FooterBar from "../../components/footer/footer";
import SideBar from "../../components/side/sideBar";
import MobileHeader from "../../components/mobile/mobileHeader";
import {
  AlignComponents,
  AlignContainers,
  AlignPage,
  NavigationWrap,
  PageWrap,
  SideWrap,
  PageBody,
  MobilePageLayout,
} from "../../components/reusable/pageStyle";
import PageNav from "../../components/reusable/nav/pageNav";
import BannerCard from "../../components/reusable/banner/BannerCard";
import OrderStatus from "../../components/status/orderStatus";
import MobileNavigation from "../../components/mobile/mobileNavigation";

export default function StatusPage() {
  const [activeSection, setActiveSection] = useState<string>("Order");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleMobileNavClick = (linkName: string) => {
    setActiveSection(linkName);
  };

  return (
    <PageBody>
      <AlignPage>
        <PageWrap>
          {isMobile && <MobileHeader title="Status" />}

          {!isMobile ? (
            <>
              <SideWrap>
                <SideBar isMobile={isMobile} activeSection={activeSection} />
              </SideWrap>
              <AlignComponents>
                <NavigationWrap>
                  <PageNav />
                  <BannerCard />
                </NavigationWrap>
                <AlignContainers>
                  <OrderStatus />
                </AlignContainers>
              </AlignComponents>
            </>
          ) : (
            <MobilePageLayout>
              <OrderStatus />
              <MobileNavigation
                activeSection={activeSection}
                onLinkClick={handleMobileNavClick}
              />
            </MobilePageLayout>
          )}
        </PageWrap>
      </AlignPage>

      {!isMobile && <FooterBar />}
      {isMobile && (
        <SideBar isMobile={isMobile} activeSection={activeSection} />
      )}
    </PageBody>
  );
}
