import { BoxIcon } from "./BoxIcon";
import { CartIcon } from "./CartIcon";
import { CopyIcon } from "./CopyIcon";
import { CrownIcon } from "./CrownIcon";
import { EventListIcon } from "./EventList";
import { GiftIcon } from "./GiftIcon";
import { GlobeIcon } from "./GlobeIcon";
import { GridIcon } from "./GridIcon";
import { HistoryIcon } from "./HistoryIcon";
import { BorderListIcon } from "./BorderList";
import { LogoutIcon } from "./LogoutIcon";
import { ProfileIcon } from "./ProfileIcon";
import { SpeakerIcon } from "./SpeakerIcon";
import { SupportIcon } from "./SupportIcon";
import { TagIcon } from "./TagIcon";
import { CustomIconProps, IconParams, IconType } from "./types";
import { WalletIcon } from "./WalletIcon";
import { CashIcon } from "./CashIcon";
import { WithdrawIcon } from "./WithdrawIcon";
import { TransferIcon } from "./TransferIcon";
import { ConvertIcon } from "./ConvertIcon";
import { BranchIcon } from "./BranchIcon";
import { ArrowIcon } from "./ArrowIcon";
import { ArIcon } from "./ArIcon";
import { CloseIcon } from "./CloseIcon";
import { AddIcon } from "./AddIcon";
import { CloudIcon } from "./CloudIcon";
import { ShowIcon } from "./ShowIcon";
import { SideArrow } from "./SideArrow";
import { MinusIcon } from "./MinusIcon";
import { PlusIcon } from "./PlusIcon";
import { CalendarIcon } from "./CalendarIcon";
import { ReferralIcon } from "./ReferralIcon";
import { TickIcon } from "./TickIcon";
import { KYCIcon } from "./KYCIcon";
import { BackIcon } from "./BackIcon";
import { SearchIcon } from "./SearchIcon";

const Icon = ({ name, width = 10, height = 10, ...iconParams }: IconParams) => {
  return iconSelector(name, { ...iconParams, width, height });
};

const iconSelector = (name: IconType, iconParams: CustomIconProps) => {
  switch (name) {
    case "globe":
      return <GlobeIcon {...iconParams} />;
    case "cart":
      return <CartIcon {...iconParams} />;
    case "profile":
      return <ProfileIcon {...iconParams} />;
    case "box":
      return <BoxIcon {...iconParams} />;
    case "grid":
      return <GridIcon {...iconParams} />;
    case "copy":
      return <CopyIcon {...iconParams} />;
    case "crown":
      return <CrownIcon {...iconParams} />;
    case "gift":
      return <GiftIcon {...iconParams} />;
    case "history":
      return <HistoryIcon {...iconParams} />;
    case "support":
      return <SupportIcon {...iconParams} />;
    case "logout":
      return <LogoutIcon {...iconParams} />;
    case "borderlist":
      return <BorderListIcon {...iconParams} />;
    case "speaker":
      return <SpeakerIcon {...iconParams} />;
    case "wallet":
      return <WalletIcon {...iconParams} />;
    case "tag":
      return <TagIcon {...iconParams} />;
    case "eventlist":
      return <EventListIcon {...iconParams} />;
    case "cash":
      return <CashIcon {...iconParams} />;
    case "withdraw":
      return <WithdrawIcon {...iconParams} />;
    case "transfer":
      return <TransferIcon {...iconParams} />;
    case "convert":
      return <ConvertIcon {...iconParams} />;
    case "branch":
      return <BranchIcon {...iconParams} />;
    case "arrow":
      return <ArrowIcon {...iconParams} />;
    case "ar":
      return <ArIcon {...iconParams} />;
    case "close":
      return <CloseIcon {...iconParams} />;
    case "add":
      return <AddIcon {...iconParams} />;
    case "cloud":
      return <CloudIcon {...iconParams} />;
    case "eye":
      return <ShowIcon {...iconParams} />;
    case "sidearrow":
      return <SideArrow {...iconParams} />;
    case "minus":
      return <MinusIcon {...iconParams} />;
    case "plus":
      return <PlusIcon {...iconParams} />;
    case "calendar":
      return <CalendarIcon {...iconParams} />;
    case "referral":
      return <ReferralIcon {...iconParams} />;
    case "tick":
      return <TickIcon {...iconParams} />;
    case "kyc":
      return <KYCIcon {...iconParams} />;
    case "back":
      return <BackIcon {...iconParams} />;
    case "search":
      return <SearchIcon {...iconParams} />;
  }
};

export default Icon;
