import { CustomIconProps } from "./types";

export const EventListIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 21C15.45 21 14.9792 20.8042 14.5875 20.4125C14.1958 20.0208 14 19.55 14 19V15C14 14.45 14.1958 13.9792 14.5875 13.5875C14.9792 13.1958 15.45 13 16 13H20C20.55 13 21.0208 13.1958 21.4125 13.5875C21.8042 13.9792 22 14.45 22 15V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H16ZM16 19H20V15H16V19ZM2 18V16H11V18H2ZM16 11C15.45 11 14.9792 10.8042 14.5875 10.4125C14.1958 10.0208 14 9.55 14 9V5C14 4.45 14.1958 3.97917 14.5875 3.5875C14.9792 3.19583 15.45 3 16 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V9C22 9.55 21.8042 10.0208 21.4125 10.4125C21.0208 10.8042 20.55 11 20 11H16ZM16 9H20V5H16V9ZM2 8V6H11V8H2Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
