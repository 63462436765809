import { CustomIconProps } from "./types";

export const WithdrawIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 28 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9997 9.43567C13.3553 9.43567 12.833 9.96372 12.833 10.6151V17.203L11.3246 15.6783C10.869 15.2178 10.1303 15.2178 9.67475 15.6783C9.21909 16.1389 9.21909 16.8857 9.67475 17.3464L13.1737 20.8838C13.629 21.344 14.3671 21.3443 14.8228 20.8846L18.3222 17.3541C18.7778 16.8937 18.7778 16.1467 18.3222 15.6863C17.8666 15.2256 17.128 15.2256 16.6723 15.6863L15.1663 17.2086V10.6151C15.1663 9.96372 14.644 9.43567 13.9997 9.43567Z"
      fill={props?.fill || "black"}
      {...props}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.5 6.86646e-05C26.433 6.86646e-05 28 1.58425 28 3.53842V8.25621C28 10.2104 26.433 11.7946 24.5 11.7946H22.1667V22.4096C22.1667 24.3638 20.5997 25.9479 18.6667 25.9479H9.33333C7.40034 25.9479 5.83333 24.3638 5.83333 22.4096V11.7946H3.5C1.56701 11.7946 0 10.2104 0 8.25621V3.53842C0 1.58425 1.56701 6.86646e-05 3.5 6.86646e-05H24.5ZM25.6667 8.25621C25.6667 8.9076 25.1444 9.43566 24.5 9.43566H22.1667V7.07676H23.3333C23.9777 7.07676 24.5 6.54871 24.5 5.89731C24.5 5.24593 23.9777 4.71786 23.3333 4.71786H4.66667C4.02234 4.71786 3.5 5.24593 3.5 5.89731C3.5 6.54871 4.02234 7.07676 4.66667 7.07676H5.83333V9.43566H3.5C2.85567 9.43566 2.33333 8.9076 2.33333 8.25621V3.53842C2.33333 2.88703 2.85567 2.35897 3.5 2.35897H24.5C25.1444 2.35897 25.6667 2.88703 25.6667 3.53842V8.25621ZM8.16667 7.07676V22.4096C8.16667 23.061 8.68901 23.589 9.33333 23.589H18.6667C19.311 23.589 19.8333 23.061 19.8333 22.4096V7.07676H8.16667Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
