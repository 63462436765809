import { CustomIconProps } from "./types";

export const TickIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 97 98" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.5667 71.2333L75.6417 37.1583L68.875 30.3917L41.5667 57.7L27.7917 43.925L21.025 50.6917L41.5667 71.2333ZM48.3333 97.3333C41.6472 97.3333 35.3639 96.0646 29.4833 93.5271C23.6028 90.9896 18.4875 87.5458 14.1375 83.1958C9.7875 78.8458 6.34375 73.7306 3.80625 67.85C1.26875 61.9694 0 55.6861 0 49C0 42.3139 1.26875 36.0305 3.80625 30.15C6.34375 24.2694 9.7875 19.1542 14.1375 14.8042C18.4875 10.4542 23.6028 7.01041 29.4833 4.47291C35.3639 1.93541 41.6472 0.666656 48.3333 0.666656C55.0194 0.666656 61.3028 1.93541 67.1833 4.47291C73.0639 7.01041 78.1792 10.4542 82.5292 14.8042C86.8792 19.1542 90.3229 24.2694 92.8604 30.15C95.3979 36.0305 96.6667 42.3139 96.6667 49C96.6667 55.6861 95.3979 61.9694 92.8604 67.85C90.3229 73.7306 86.8792 78.8458 82.5292 83.1958C78.1792 87.5458 73.0639 90.9896 67.1833 93.5271C61.3028 96.0646 55.0194 97.3333 48.3333 97.3333Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
