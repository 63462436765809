import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FooterBar from "../../components/footer/footer";
import SideBar from "../../components/side/sideBar";
import {
  AlignComponents,
  AlignContainers,
  AlignPage,
  NavigationWrap,
  PageWrap,
  SideWrap,
  PageBody,
} from "../../components/reusable/pageStyle";

import HomeHeader from "../../components/mobile/mobileHeader";
import PageNav from "../../components/reusable/nav/pageNav";
import BannerCard from "../../components/reusable/banner/BannerCard";
import MobileNavigation from "../../components/mobile/mobileNavigation";
import DashProfile from "../../components/dashboard/profile/dashProfile";
import DashProduct from "../../components/dashboard/product/dashProduct";
import WalletCard from "../../components/wallet/walletCard";

export default function HomePage() {
  const [activeSection, setActiveSection] = useState<string>("Dashboard");
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/home") {
      setActiveSection("Home");
    } else if (path === "/MobileOrder") {
      setActiveSection("Order");
    } else if (path === "/Product") {
      setActiveSection("Product");
    } else if (path === "/Package") {
      setActiveSection("Package");
    } else if (path === "/Profile") {
      setActiveSection("Profile");
    }
  }, [location]);

  const handleMobileNavClick = (linkName: string) => {
    setActiveSection(linkName);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "Home":
        return isMobile ? (
          <WalletCard />
        ) : (
          <>
            <DashProduct />
            <DashProfile />
          </>
        );
    }
  };

  return (
    <PageBody>
      <AlignPage>
        <PageWrap>
          {isMobile && <HomeHeader title="Home" />}

          {!isMobile ? (
            <SideWrap>
              <SideBar isMobile={isMobile} activeSection={activeSection} />
            </SideWrap>
          ) : (
            <MobileNavigation
              activeSection={activeSection}
              onLinkClick={handleMobileNavClick}
            />
          )}

          <AlignComponents>
            {!isMobile && (
              <NavigationWrap>
                <PageNav />
                <BannerCard />
              </NavigationWrap>
            )}

            <AlignContainers>{renderContent()}</AlignContainers>
          </AlignComponents>
        </PageWrap>
      </AlignPage>

      {!isMobile && <FooterBar />}
    </PageBody>
  );
}
