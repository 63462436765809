import styled from "styled-components";

export const AlignContainers = styled.div`
  font-family: "Inter", sans-serif;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`;

export const ProductsContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 50vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;

  @media (max-width: 480px) {
    margin: 1vh 0 0 0;
    border-radius: 0;
    width: 100vw;
    height: calc(100% - 1vh);
  }
`;

export const NewProductContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 50vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const BundleContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 50vw;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const ContainerHeader = styled.div`
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 0;

  @media (max-width: 480px) {
    width: 90%;
    padding: 3vh 0 0 0;
  }
`;

export const AlignLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  margin-left: 10px;

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  width: 2.4vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 1vw;
  }
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #a41e4d;
  font-weight: 600;
  font-size: clamp(12px, 1vw, 16px);
  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ProductGrid = styled.div`
  width: 100%;
  padding: 2vh 3vw 3vh 3vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3vw;
  row-gap: 3vh;

  @media (max-width: 480px) {
    padding: 2vh 2vw;
  }
`;

export const ProductCards = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  background-color: #eeeeee;
  padding: 20px 20px 0 20px;

  @media (max-width: 480px) {
    padding: 2vh 2vw;
  }
`;

export const ProductThumbnail = styled.div`
  width: 100%;
  height: 16vh;
  background-color: #d9d9d9;

  @media (max-width: 480px) {
    height: 14vh;
  }
`;

export const ProductTitle = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 18px);
  padding: 1vh 0.5vw 0.5vh 0.5vw;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ProductDescription = styled.text`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  padding: 0 0.5vw;
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const ProductPricing = styled.text`
  font-weight: 600;
  font-size: clamp(20px, 2vw, 28px);
  color: #a41e4d;
  padding: 2vh 0.5vw;

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;
