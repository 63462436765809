import { CustomIconProps } from "./types";

export const SideArrow = (props: CustomIconProps) => (
  <svg viewBox="0 0 11 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.37489 16.3411L0.964844 14.9311L7.49873 8.3972L0.964844 1.86331L2.37489 0.453262L10.3188 8.3972L2.37489 16.3411Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
