import styled from "styled-components";

export const ArtechLogo = styled.img`
  width: 12vw;
  height: auto;

  @media (max-width: 480px) {
    width: 160px;
    height: 32px;
  }
`;
