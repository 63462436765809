import { useState, useEffect } from "react";
import FooterBar from "../../components/footer/footer";
import SideBar from "../../components/side/sideBar";
import MobileHeader from "../../components/mobile/mobileHeader";
import {
  AlignComponents,
  AlignContainers,
  AlignPage,
  NavigationWrap,
  PageWrap,
  SideWrap,
  PageBody,
} from "../../components/reusable/pageStyle";
import PackageSelection from "../../components/package/packageSelection";
import WalletCard from "../../components/wallet/walletCard";
import PageNav from "../../components/reusable/nav/pageNav";
import BannerCard from "../../components/reusable/banner/BannerCard";

export default function PackagePage() {
  const [activeSection, setActiveSection] = useState<string>("Dashboard");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);

      setActiveSection(isMobileView ? "Wallet" : "Dashboard");
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <PageBody>
      <AlignPage>
        <PageWrap>
          {isMobile && <MobileHeader title="Package" />}

          {!isMobile && (
            <SideWrap>
              <SideBar isMobile={isMobile} activeSection={activeSection} />
            </SideWrap>
          )}

          <AlignComponents>
            {!isMobile && (
              <NavigationWrap>
                <PageNav />
                <BannerCard />
              </NavigationWrap>
            )}

            <AlignContainers>
              <PackageSelection />
              <WalletCard />
            </AlignContainers>
          </AlignComponents>
        </PageWrap>
      </AlignPage>

      {!isMobile && <FooterBar />}
      {isMobile && (
        <SideBar isMobile={isMobile} activeSection={activeSection} />
      )}
    </PageBody>
  );
}
