import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from "../../core/Icons";
import MainLogo from "../logo/logo";
import {
  SideContainer,
  SideLinks,
  AlignSide,
  LogOut,
  IconWrap,
  LinksBox,
  StyledLogo,
  DropdownMenu,
  DropdownItem,
  ExtraIconWrap,
  OptionText,
} from "./sideStyle";
import AccPassModal from "./accPassModal";
import SecurityPassModal from "./securityPassModal";
import KycModal from "./kycModal";

export default function SideBar({
  isMobile,
  activeSection,
}: {
  isMobile: boolean;
  activeSection: string;
}) {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  if (isMobile) {
    return null;
  }

  return (
    <SideContainer>
      <AlignSide>
        <StyledLogo>
          <MainLogo />
        </StyledLogo>

        <LinksBox>
          <SideLinks
            as="button"
            onClick={() => navigate("/home")}
            isActive={location.pathname === "/home" || activeSection === "home"}
          >
            <IconWrap>
              <Icon name="grid" fill="" />
            </IconWrap>
            Dashboard
          </SideLinks>

          <SideLinks
            as="button"
            onClick={() => navigate("/package")}
            isActive={location.pathname === "/package"}
          >
            <IconWrap>
              <Icon name="crown" fill="" />
            </IconWrap>
            Package
          </SideLinks>

          <SideLinks
            as="button"
            onClick={() =>
              setOpenDropdown((prev) => (prev === "Product" ? null : "Product"))
            }
          >
            <IconWrap>
              <Icon name="box" fill="" />
            </IconWrap>
            Product
            <ExtraIconWrap isOpen={openDropdown === "Product"}>
              <Icon name="arrow" fill="" />
            </ExtraIconWrap>
          </SideLinks>
          {openDropdown === "Product" && (
            <DropdownMenu>
              <DropdownItem
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/product");
                }}
              >
                <OptionText>All Product</OptionText>
              </DropdownItem>
              <DropdownItem
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/status");
                }}
              >
                <OptionText>Order Status</OptionText>
              </DropdownItem>
            </DropdownMenu>
          )}

          <SideLinks
            as="button"
            onClick={() => navigate("/rewards")}
            isActive={location.pathname === "/rewards"}
          >
            <IconWrap>
              <Icon name="gift" fill="" />
            </IconWrap>
            Rewards
          </SideLinks>

          <SideLinks
            as="button"
            onClick={() => navigate("/history")}
            isActive={location.pathname === "/history"}
          >
            <IconWrap>
              <Icon name="history" fill="" />
            </IconWrap>
            History
          </SideLinks>

          <SideLinks
            as="button"
            onClick={() =>
              setOpenDropdown((prev) => (prev === "Profile" ? null : "Profile"))
            }
          >
            <IconWrap>
              <Icon name="profile" fill="" />
            </IconWrap>
            Profile
            <ExtraIconWrap isOpen={openDropdown === "Profile"}>
              <Icon name="arrow" fill="" />
            </ExtraIconWrap>
          </SideLinks>

          {openDropdown === "Profile" && (
            <DropdownMenu>
              <DropdownItem
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/profile");
                }}
              >
                <OptionText>Account Information</OptionText>
              </DropdownItem>
              <DropdownItem
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveModal("Account Password");
                }}
              >
                <OptionText>Account Password</OptionText>
              </DropdownItem>
              <DropdownItem
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveModal("Security Password");
                }}
              >
                <OptionText>Security Password</OptionText>
              </DropdownItem>
              <DropdownItem
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveModal("KYC");
                }}
              >
                <OptionText>KYC</OptionText>
              </DropdownItem>
            </DropdownMenu>
          )}

          <SideLinks
            as="button"
            onClick={() => navigate("/support")}
            isActive={location.pathname === "/support"}
          >
            <IconWrap>
              <Icon name="support" fill="" />
            </IconWrap>
            Customer Support
          </SideLinks>
        </LinksBox>

        <LogOut as="button" onClick={() => console.log("Logging out")}>
          <IconWrap>
            <Icon name="logout" fill="" />
          </IconWrap>
          Logout
        </LogOut>
      </AlignSide>

      {activeModal === "Account Password" && (
        <AccPassModal isVisible={true} onClose={() => setActiveModal(null)} />
      )}
      {activeModal === "Security Password" && (
        <SecurityPassModal
          isVisible={true}
          onClose={() => setActiveModal(null)}
        />
      )}
      {activeModal === "KYC" && (
        <KycModal isVisible={true} onClose={() => setActiveModal(null)} />
      )}
    </SideContainer>
  );
}
