import Icon from "../../core/Icons";
import {
  CloseButton,
  InputWrap,
  MainButton,
  ModalContent,
  ModalHeader,
  ModalLabel,
  ModalOverlay,
  ModalTitle,
  TitleStar,
} from "../reusable/modalStyle";
import ShowPassword from "../reusable/showPassword";

type SecurityPassProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function SecurityPassModal({
  isVisible,
  onClose,
}: SecurityPassProps) {
  if (!isVisible) return null;
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader onClick={onClose}>
          <ModalTitle>Account Password</ModalTitle>
          <CloseButton>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <InputWrap>
          <ModalLabel>
            Current Security Password
            <TitleStar>*</TitleStar>
            <ShowPassword
              placeholder="Enter Current Security Password"
              type="security"
            />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            New Security Password
            <TitleStar>*</TitleStar>
            <ShowPassword
              placeholder="Enter New Security Password"
              type="security"
            />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Confirm New Security Password
            <TitleStar>*</TitleStar>
            <ShowPassword
              placeholder="Enter Confirm Current Security Password"
              type="security"
            />
          </ModalLabel>
        </InputWrap>

        <MainButton>Submit</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
