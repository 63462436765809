import Icon from "../../../core/Icons";
import {
  AlignContainers,
  NewProductContainer,
  ProductsContainer,
  ContainerHeader,
  IconWrap,
  ContainerTitle,
  ExpandButton,
  AlignLeft,
  BundleContainer,
  ProductGrid,
  ProductCards,
  ProductThumbnail,
  ProductTitle,
  ProductDescription,
  ProductPricing,
} from "./dashProductStyle";

export default function DashProduct() {
  return (
    <AlignContainers>
      <ProductsContainer>
        <ContainerHeader>
          <AlignLeft>
            <IconWrap>
              <Icon name="box" fill="#a41e4d" />
            </IconWrap>
            <ContainerTitle>Products</ContainerTitle>
          </AlignLeft>
          <ExpandButton>See All</ExpandButton>
        </ContainerHeader>

        <ProductGrid>
          <ProductCards>
            <ProductThumbnail></ProductThumbnail>
            <ProductTitle>Quantum 18+</ProductTitle>
            <ProductDescription>Quantum 18+ (850ml)</ProductDescription>
            <ProductPricing>750.00 PV</ProductPricing>
          </ProductCards>

          <ProductCards>
            <ProductThumbnail></ProductThumbnail>
            <ProductTitle>Quantum 19+</ProductTitle>
            <ProductDescription>Quantum 19+ (500ml)</ProductDescription>
            <ProductPricing>700.00 PV</ProductPricing>
          </ProductCards>

          <ProductCards>
            <ProductThumbnail></ProductThumbnail>
            <ProductTitle>D10's 30g</ProductTitle>
            <ProductDescription>D10's 30g (Buy 1 free 1)</ProductDescription>
            <ProductPricing>120.00 PV</ProductPricing>
          </ProductCards>

          <ProductCards>
            <ProductThumbnail></ProductThumbnail>
            <ProductTitle>Heel Guard 35ml</ProductTitle>
            <ProductDescription>Heel Guard 35ml</ProductDescription>
            <ProductPricing>50.00 PV</ProductPricing>
          </ProductCards>
        </ProductGrid>
      </ProductsContainer>

      <NewProductContainer>
        <ContainerHeader>
          <AlignLeft>
            <IconWrap>
              <Icon name="tag" fill="#a41e4d" />
            </IconWrap>
            <ContainerTitle>New Products</ContainerTitle>
          </AlignLeft>
          <ExpandButton>See All</ExpandButton>
        </ContainerHeader>

        <ProductGrid>
          <ProductCards>
            <ProductThumbnail></ProductThumbnail>
            <ProductTitle>Quantum 19+</ProductTitle>
            <ProductDescription>Quantum 19+ (500ml)</ProductDescription>
            <ProductPricing>700.00 PV</ProductPricing>
          </ProductCards>
        </ProductGrid>
      </NewProductContainer>

      <BundleContainer>
        <ContainerHeader>
          <AlignLeft>
            <IconWrap>
              <Icon name="eventlist" fill="#a41e4d" />
            </IconWrap>
            <ContainerTitle>Bundle Pack</ContainerTitle>
          </AlignLeft>
          <ExpandButton>See All</ExpandButton>
        </ContainerHeader>
      </BundleContainer>
    </AlignContainers>
  );
}
