import React, { useState } from "react";
import Icon from "../../core/Icons";
import {
  CommissionBox,
  CommissionButtons,
  CommissionHeaderWrap,
  CreateReferralButton,
  GenealogyActive,
  GenealogyBox,
  GenealogyButton,
  GenealogyCounter,
  GenealogyInnerWrap,
  GenealogyReferrals,
  GenealogyText,
  HeaderText,
  HeaderWrap,
  IconBackground,
  MainContainer,
  RedirectButtons,
  ReferralBox,
  ReferralIcon,
  SideArrow,
} from "./rewardsCardStyle";
import ReferralModal from "../reusable/referralModal";
import DirectSponsorBonus from "./commission/directSponsorBonus";
import RollUpBonus from "./commission/rollUpBonus";
import ProductPointBonus from "./commission/productPointBonus";
import LevelPairingBonus from "./commission/levelPairingBonus";
import PairingBonus from "./commission/pairingBonus";
import MatchingBonus from "./commission/matchingBonus";
import MatrixBonus from "./commission/matrixBonus";
import SponsorContainer from "./genealogy/sponsorComponent";

interface RewardContainerProps {
  onGoToPlacementTree: () => void;
  setActiveRewardComponent?: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  activeRewardComponent?: string | null;
}

export default function RewardsCard({
  onGoToPlacementTree,
  setActiveRewardComponent,
  activeRewardComponent,
}: RewardContainerProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [localActiveComponent, setLocalActiveComponent] = useState<
    string | null
  >(null);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleRedirectLink = (screen: string) => {
    if (setActiveRewardComponent) {
      setActiveRewardComponent(screen);
    } else {
      setLocalActiveComponent(screen);
    }
  };

  const renderActiveComponent = () => {
    const setActiveComponent =
      setActiveRewardComponent || setLocalActiveComponent;
    const activeComponent = setActiveRewardComponent
      ? activeRewardComponent
      : localActiveComponent;
    switch (activeComponent) {
      case "DirectSponsorBonus":
        return <DirectSponsorBonus setActiveComponent={setActiveComponent} />;
      case "RollUpBonus":
        return <RollUpBonus setActiveComponent={setActiveComponent} />;
      case "ProductPointBonus":
        return <ProductPointBonus setActiveComponent={setActiveComponent} />;
      case "LevelPairingBonus":
        return <LevelPairingBonus setActiveComponent={setActiveComponent} />;
      case "PairingBonus":
        return <PairingBonus setActiveComponent={setActiveComponent} />;
      case "MatchingBonus":
        return <MatchingBonus setActiveComponent={setActiveComponent} />;
      case "MatrixBonus":
        return <MatrixBonus setActiveComponent={setActiveComponent} />;
      case "Sponsor":
        return <SponsorContainer setActiveComponent={setActiveComponent} />;
      default:
        return null;
    }
  };

  return (
    <MainContainer>
      {(
        setActiveRewardComponent ? activeRewardComponent : localActiveComponent
      ) ? (
        renderActiveComponent()
      ) : (
        <>
          <ReferralBox>
            <HeaderWrap>
              <ReferralIcon>
                <Icon name="referral" fill="#a41e4d" />
              </ReferralIcon>
              <HeaderText>Referral</HeaderText>
            </HeaderWrap>
            <CreateReferralButton onClick={openModal}>
              Create
            </CreateReferralButton>
          </ReferralBox>
          <CommissionBox>
            <CommissionHeaderWrap>
              <IconBackground>
                <Icon name="cash" fill="#a41e4d" />
              </IconBackground>
              <HeaderText>Commission</HeaderText>
            </CommissionHeaderWrap>

            <CommissionButtons>
              <RedirectButtons
                onClick={() => handleRedirectLink("DirectSponsorBonus")}
              >
                Direct Sponsor Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>

              <RedirectButtons
                onClick={() => handleRedirectLink("RollUpBonus")}
              >
                Roll Up Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>

              <RedirectButtons
                onClick={() => handleRedirectLink("ProductPointBonus")}
              >
                Product Point Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>

              <RedirectButtons
                onClick={() => handleRedirectLink("LevelPairingBonus")}
              >
                Level Pairing Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>

              <RedirectButtons
                onClick={() => handleRedirectLink("PairingBonus")}
              >
                Pairing Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>

              <RedirectButtons
                onClick={() => handleRedirectLink("MatchingBonus")}
              >
                Matching Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>

              <RedirectButtons
                onClick={() => handleRedirectLink("MatrixBonus")}
              >
                Matrix Bonus
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>
            </CommissionButtons>
          </CommissionBox>
          <GenealogyBox>
            <GenealogyInnerWrap>
              <HeaderWrap>
                <IconBackground>
                  <Icon name="branch" fill="#a41e4d" />
                </IconBackground>
                <HeaderText>Genealogy</HeaderText>
              </HeaderWrap>

              <GenealogyCounter>
                <GenealogyText>
                  Referrals
                  <GenealogyReferrals>3</GenealogyReferrals>
                </GenealogyText>
                <GenealogyText>
                  Active
                  <GenealogyActive>3</GenealogyActive>
                </GenealogyText>
              </GenealogyCounter>
            </GenealogyInnerWrap>

            <GenealogyButton>
              <RedirectButtons onClick={() => handleRedirectLink("Sponsor")}>
                Sponsor
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>
              <RedirectButtons onClick={onGoToPlacementTree}>
                Placement Tree
                <SideArrow>
                  <Icon name="sidearrow" fill="" />
                </SideArrow>
              </RedirectButtons>
            </GenealogyButton>
          </GenealogyBox>
        </>
      )}
      <ReferralModal isVisible={isModalVisible} onClose={closeModal} />
    </MainContainer>
  );
}
