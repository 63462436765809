import { CustomIconProps } from "./types";

export const ArrowIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 5.2L10.6 0.6L12 2L6 8L5.24537e-07 2L1.4 0.599999L6 5.2Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
