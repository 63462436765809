import React, { useState } from "react";
import styled from "styled-components";
import Icon from "../../core/Icons";

const PasswordWrap = styled.div`
  position: relative;
  width: 100%;
`;

const PasswordInput = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 5vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 2px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ShowIcon = styled.button`
  width: 1.2vw;
  height: auto;
  position: absolute;
  top: 60%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 20px;
  }
`;

type ShowPasswordProps = {
  placeholder?: string;
  type: "account" | "security";
};

export default function ShowPassword({ placeholder, type }: ShowPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <PasswordWrap>
      <PasswordInput
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        aria-label={`${type} password`}
      />
      <ShowIcon onClick={togglePasswordVisibility}>
        <Icon name="eye" fill="black" />
      </ShowIcon>
    </PasswordWrap>
  );
}
