import { PageBody } from "./mobileProductStyle";
import MobileNavigation from "../../../components/mobile/mobileNavigation";

import DashProduct from "../../../components/dashboard/product/dashProduct";
import { MobilePageLayout } from "../../../components/reusable/pageStyle";
import MobileHeader from "../../../components/mobile/mobileHeader";

export default function MobileProductPage() {
  return (
    <PageBody>
      <MobileHeader title="Product" />

      <MobilePageLayout>
        <MobileNavigation activeSection="Product" onLinkClick={() => {}} />
        <DashProduct />
      </MobilePageLayout>
    </PageBody>
  );
}
