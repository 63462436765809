import styled from "styled-components";
import { TreeRowProps } from "./placementTreeComponent";

export const TreePageContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 2vh 2vw 4vh 2vw;
  position: relative;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vh 0 2vh 0;
    padding: 1vh 4vw 2vh 4vw;
    border-radius: 0;
  }
`;

export const BackButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const BackIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  height: auto;
`;

export const BackButtonText = styled.text`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  padding: 0 5px;
`;

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 0;

  @media (max-width: 480px) {
    width: 90%;
    padding: 1.5vh 0;
    flex-direction: column;
  }
`;

export const TitleAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 2vh;
  }
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  width: 2.4vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 4px;
  }
`;

export const HeaderTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  padding: 0 5px;
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled.div`
  width: 0.9vw;
  height: auto;
  position: absolute;
  left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    width: 4vw;
    left: 2;
  }
`;

export const SearchInput = styled.input`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  border: none;
  outline: none;
  border-radius: 5px;
  color: #737373;
  background-color: #d9d9d9;
  width: 10vw;
  height: 4vh;
  text-indent: 3vw;

  @media (max-width: 480px) {
    width: 60vw;
    text-indent: 10vw;
  }
`;

export const AlignTotalBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 2vw;
  padding: 6vh 0;

  @media (max-width: 480px) {
    column-gap: 4vw;
    padding: 1vh 0;
  }
`;

export const TotalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 14vw;
  height: auto;
  padding: 20px 0;
  background-color: #eeeeee;
  border-radius: 10px;

  @media (max-width: 480px) {
    width: 35vw;
    padding: 1.5vh 0;
  }
`;

export const TotalBoxTitle = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  padding-bottom: 4px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const TotalBoxAmount = styled.text`
  font-weight: 600;
  font-size: clamp(26px, 2vw, 30px);

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const PlacementTree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TreeRow = styled.div<TreeRowProps>`
  display: flex;
  justify-content: center;
  column-gap: 4vw;
  width: 100%;
  position: relative;
  padding: 2vh 0;

  &::before {
    content: "";
    position: absolute;
    top: -0.2vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 2px;
    width: ${({ numberOfCards }) =>
      numberOfCards > 1
        ? `calc(${numberOfCards} * 14vw + (${numberOfCards - 1} * 4vw) - 14vw)`
        : "0vw"};
    background-color: #eeeeee;
    z-index: 10;
  }

  @media (max-width: 480px) {
    column-gap: 2vw;
  }
`;

export const FirstTreeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
  height: auto;
  padding-bottom: 2vh;
  border-radius: 10px;
  background-color: #eeeeee;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -2vh;
    left: 50%;
    width: 2px;
    height: 2vh;
    background-color: #eeeeee;
    transform: translateX(-50%);
  }

  @media (max-width: 480px) {
    width: 48vw;
    padding-bottom: 1vh;
  }
`;

export const TreeCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
  height: auto;
  padding-bottom: 2vh;
  border-radius: 10px;
  background-color: #eeeeee;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -2.2vh;
    left: 50%;
    width: 2px;
    height: 2.2vh;
    background-color: #eeeeee;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2vh;
    left: 50%;
    width: 2px;
    height: 2vh;
    background-color: #eeeeee;
    transform: translateX(-50%);
  }

  @media (max-width: 480px) {
    width: 48vw;
    padding-bottom: 1vh;
  }
`;

export const CardTitle = styled.text`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: clamp(16px, 1vw, 20px);
  background-color: #edc5d3;
  width: 100%;
  height: auto;
  padding: 10px 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const GenealogyRank = styled.img`
  width: 4vw;
  height: auto;
  padding: 2vh 0;

  @media (max-width: 480px) {
    width: 18vw;
    padding: 1vh 0;
  }
`;

export const CardInfo = styled.div`
  width: 100%;
  padding: 4px 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 3px 2vw;
  }
`;

export const CardLabel = styled.text`
  font-weight: 400;
  font-size: clamp(11px, 1vw, 13px);
  color: #737373;

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

export const CardData = styled.text`
  font-weight: 400;
  font-size: clamp(10px, 1vw, 12px);

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const RegisterButton = styled.button`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  width: 6vw;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin: 0 4vw;
  color: white;
  background-color: #a41e4d;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -2.2vh;
    left: 50%;
    width: 2px;
    height: 2.2vh;
    background-color: #eeeeee;
    transform: translateX(-50%);
  }

  @media (max-width: 480px) {
    width: 25vw;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`;
