import { useState } from "react";
import Icon from "../../../core/Icons";
import {
  CreateReferralButton,
  EmailNameWrap,
  PackageDate,
  PackageDateContainer,
  ProfileContainer,
  ProfileEmail,
  ProfileImage,
  ProfileImageWrapper,
  ProfileInfoWrap,
  ProfilePreviewHeader,
  ProfileUsername,
  ProfileUsernameLabel,
  ReferralContainer,
  ReferralContent,
} from "./dashProfileStyle";
import ReferralModal from "../../reusable/referralModal";

export default function DashProfile() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  return (
    <ProfileContainer>
      <ProfilePreviewHeader>Profile Overview</ProfilePreviewHeader>

      <ProfileInfoWrap>
        <ProfileImageWrapper>
          <ProfileImage>
            <Icon name="ar" fill="#a41e4d" />
          </ProfileImage>
        </ProfileImageWrapper>

        <EmailNameWrap>
          <ProfileEmail>username@gmail.com</ProfileEmail>
          <ProfileUsernameLabel>
            Username: <ProfileUsername>Bob Robert</ProfileUsername>
          </ProfileUsernameLabel>
        </EmailNameWrap>
      </ProfileInfoWrap>

      <PackageDate>Package Date</PackageDate>
      <PackageDateContainer>07 Oct 2024 - 07 Oct 2025</PackageDateContainer>
      <ReferralContainer>
        <ReferralContent>
          Referral Link
          <CreateReferralButton onClick={openModal}>
            Create
          </CreateReferralButton>
        </ReferralContent>
      </ReferralContainer>

      <ReferralModal isVisible={isModalVisible} onClose={closeModal} />
    </ProfileContainer>
  );
}
