import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavBox = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;

  @media (max-width: 768px) {
    height: 90px;
  }

  @media (max-width: 480px) {
    height: 130px;
  }
`;

export const AlignContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;

  @media (max-width: 480px) {
    height: 60%;
    align-items: end;
    justify-content: center;
  }
`;

export const HomeLink = styled(Link)`
  cursor: pointer;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const IconWrap = styled(Link)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 480px) {
    width: 30px;
    margin-bottom: -6px;
    position: absolute;
    right: 10%;
  }
`;
