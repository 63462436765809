import styled from "styled-components";

export const WalletContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 23vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    width: 100vw;
    background: none;
    margin: 10px 0 90px 0;
  }
`;

export const WalletInfoWrap = styled.div`
  background-color: #eeeeee;
  width: 90%;
  height: auto;
  padding: 20px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 480px) {
    height: 25vh;
  }
`;

export const MobileWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0 10px 0;

  @media (max-width: 480px) {
    background-color: white;
    border-radius: 12px;
    height: fit-content;
    margin-bottom: 10px;
  }
`;

export const WalletHeader = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    height: auto;
    justify-content: center;
  }
`;

export const WalletMainLabel = styled.text`
  font-weight: 600;
  font-size: 1vw;

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 4px 0;
  }
`;

export const WalletAmountLabel = styled.text`
  font-weight: 600;
  font-size: 1.8vw;
  color: #a41e4d;

  @media (max-width: 480px) {
    font-size: 28px;
    padding: 4px 0;
  }
`;

export const WalletAmount = styled.text`
  font-weight: 600;
  font-size: 1.8vw;
  margin-left: 10px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

export const AlignItems = styled.div`
  width: 90%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RedeemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProductWalletLabel = styled.text`
  font-weight: 600;
  font-size: 0.9vw;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const RedeemButton = styled.button`
  font-weight: 600;
  font-size: 0.8vw;
  padding: 2px 0;
  margin-left: 10px;
  width: 5vw;
  background-color: white;
  border: 1px solid #a41e4d;
  color: #a41e4d;
  border-radius: 20px;
  background: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 70px;
    font-size: 12px;
    padding: 4px 0;
  }
`;

export const SubWalletLabel = styled.text`
  font-weight: 400;
  font-size: 0.9vw;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const SubWalletAmount = styled.text`
  font-weight: 600;
  font-size: 0.8vw;
  margin-left: 3px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const DollarsLabel = styled.text`
  font-weight: 600;
  font-size: 0.8vw;
  padding: 0 1px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const CapWrap = styled.div`
  font-weight: 400;
  font-size: 1.1vw;
`;

export const ProgressCap = styled.div`
  background-color: white;
  width: 90%;
  height: 1vh;
  border-radius: 10px;
  border-left: 20px solid greenyellow;
`;

export const WalletButtonsWrap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px;
`;

export const ButtonWrap = styled.div`
  font-weight: 600;
  font-size: 0.8vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const WalletButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  width: 3vw;
  height: auto;
  padding: 6px;
  margin-bottom: 5px;
  background-color: #a41e4d;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 70px;
    height: 35px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
    padding: 6px 2px 2px 2px;
  }
`;

export const DepositIcon = styled.div`
  width: 2vw;
  height: auto;

  @media (max-width: 480px) {
    width: 35px;
  }
`;

export const WithdrawIcon = styled.div`
  width: 2vw;
  height: auto;
  padding: 2px 0 0 0;

  @media (max-width: 480px) {
    width: 35px;
  }
`;

export const TransferIcon = styled.div`
  width: 2vw;
  height: auto;
  padding: 2px 0 0 0;

  @media (max-width: 480px) {
    width: 35px;
  }
`;

export const ConvertIcon = styled.div`
  width: 2vw;
  height: auto;
  padding: 4px 1px 1px 1px;

  @media (max-width: 480px) {
    width: 35px;
  }
`;

export const HistoryContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    border-radius: 10px;
    width: 100%;
    padding: 0 20px;
    background-color: white;
  }
`;

export const AlignHistories = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`;

export const ExpandButton = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 0.9vw;
  color: #a41e4d;
  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const HistoryIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a41e4d;
  width: 2.2vw;
  height: auto;
  border-radius: 5px;
  padding: 4px 4px 0px 4px;

  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
  }
`;

export const HistoryIconWrap = styled.div`
  width: 2vw;
  height: auto;

  @media (max-width: 480px) {
    width: 25px;
  }
`;

export const HistoryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
`;

export const HistoryInfo = styled.div`
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HistoryTitle = styled.text`
  font-weight: 600;
  font-size: 0.8vw;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const TransactionTime = styled.text`
  font-weight: 400;
  font-size: 0.7vw;
  color: #737373;
  padding-top: 4px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const TransactionStatus = styled.text`
  font-weight: 600;
  font-size: 0.7vw;
  color: #27b934;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
