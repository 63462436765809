import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 3vh 0;
  border-radius: 10px;
  width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    width: 80%;
  }
`;

export const ModalHeader = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.text`
  font-weight: bold;
  font-weight: 600;
  font-size: clamp(16px, 1vw, 20px);

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const CloseButton = styled.button`
  width: 1vw;
  height: auto;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 15px;
  }
`;

export const ModalLabel = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const TitleStar = styled.text`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 16px);
  color: red;
  margin-left: 5px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const InputWrap = styled.div`
  width: 84%;
  padding: 1vh 0;
`;

export const ModalInput = styled.input`
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  height: 5vh;
  background-color: #eeeeee;
  border: none;
  outline: none;
  border-radius: 10px;
  text-indent: 20px;
  margin-top: 2px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const SubText = styled.text`
  font-weight: 400;
  font-size: clamp(10px, 1vw, 12px);

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const FileWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  width: 100%;
  background-color: #eeeeee;
  border-radius: 10px;
  margin-top: 2px;
`;

export const FileInput = styled.input`
  display: none;
  height: 8vh;
  width: 100%;
  background-color: #eeeeee;
`;

export const FileButton = styled.button`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 14px);
  color: #a41e4d;
  border: none;
  cursor: pointer;
  margin-left: 5px;

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const FileDescription = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const SelectedFileName = styled.div`
  padding: 4px;
  font-weight: 400;
  font-size: clamp(10px, 1vw, 12px);
  color: #555;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const CloudIcon = styled.div`
  width: 2vw;
  height: auto;

  @media (max-width: 480px) {
    width: 35px;
  }
`;

export const NoteWrap = styled.div`
  padding: 2vh 0;
  width: 80%;
`;

export const NoteHeader = styled.text`
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const NoteList = styled.ul`
  margin-left: 20px;
`;

export const NotePoints = styled.li`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const MainButton = styled.button`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 18px);
  height: 6vh;
  width: 84%;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #a41e4d;
  margin-top: 4vh;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
