import { CustomIconProps } from "./types";

export const WalletIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 26.25C5.5625 26.25 4.97396 26.0052 4.48438 25.5156C3.99479 25.026 3.75 24.4375 3.75 23.75V6.25C3.75 5.5625 3.99479 4.97396 4.48438 4.48438C4.97396 3.99479 5.5625 3.75 6.25 3.75H23.75C24.4375 3.75 25.026 3.99479 25.5156 4.48438C26.0052 4.97396 26.25 5.5625 26.25 6.25V9.375H23.75V6.25H6.25V23.75H23.75V20.625H26.25V23.75C26.25 24.4375 26.0052 25.026 25.5156 25.5156C25.026 26.0052 24.4375 26.25 23.75 26.25H6.25ZM16.25 21.25C15.5625 21.25 14.974 21.0052 14.4844 20.5156C13.9948 20.026 13.75 19.4375 13.75 18.75V11.25C13.75 10.5625 13.9948 9.97396 14.4844 9.48438C14.974 8.99479 15.5625 8.75 16.25 8.75H25C25.6875 8.75 26.276 8.99479 26.7656 9.48438C27.2552 9.97396 27.5 10.5625 27.5 11.25V18.75C27.5 19.4375 27.2552 20.026 26.7656 20.5156C26.276 21.0052 25.6875 21.25 25 21.25H16.25ZM25 18.75V11.25H16.25V18.75H25ZM20 16.875C20.5208 16.875 20.9635 16.6927 21.3281 16.3281C21.6927 15.9635 21.875 15.5208 21.875 15C21.875 14.4792 21.6927 14.0365 21.3281 13.6719C20.9635 13.3073 20.5208 13.125 20 13.125C19.4792 13.125 19.0365 13.3073 18.6719 13.6719C18.3073 14.0365 18.125 14.4792 18.125 15C18.125 15.5208 18.3073 15.9635 18.6719 16.3281C19.0365 16.6927 19.4792 16.875 20 16.875Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
