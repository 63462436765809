import Icon from "../../core/Icons";
import {
  CloseButton,
  InputWrap,
  MainButton,
  ModalContent,
  ModalHeader,
  ModalLabel,
  ModalOverlay,
  ModalTitle,
  TitleStar,
} from "../reusable/modalStyle";
import ShowPassword from "../reusable/showPassword";

type AccPassProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function AccPassModal({ isVisible, onClose }: AccPassProps) {
  if (!isVisible) return null;
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Account Password</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name="close" fill="black" />
          </CloseButton>
        </ModalHeader>

        <InputWrap>
          <ModalLabel>
            Current Password
            <TitleStar>*</TitleStar>
            <ShowPassword placeholder="Enter Current Password" type="account" />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            New Password
            <TitleStar>*</TitleStar>
            <ShowPassword placeholder="Enter New Password" type="account" />
          </ModalLabel>
        </InputWrap>

        <InputWrap>
          <ModalLabel>
            Confirm New Password
            <TitleStar>*</TitleStar>
            <ShowPassword
              placeholder="Enter Confirm New Password"
              type="account"
            />
          </ModalLabel>
        </InputWrap>

        <MainButton>Submit</MainButton>
      </ModalContent>
    </ModalOverlay>
  );
}
