import { CustomIconProps } from "./types";

export const ConvertIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 28 27" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.730469 8.22579L2.35094 6.75407L4.44405 8.90824L4.42318 8.33735C4.38472 7.0943 4.63065 5.9739 5.16099 4.97613C5.69133 3.97837 6.36161 3.11156 7.17185 2.3757C8.67079 1.01436 10.4261 0.23735 12.4378 0.0446808C14.4495 -0.147988 16.323 0.282451 18.0585 1.336L16.357 2.8813C15.0925 2.26017 13.7592 2.04565 12.3571 2.23772C10.9549 2.4298 9.73392 2.99801 8.69411 3.94237C8.08643 4.49426 7.60346 5.1418 7.2452 5.88498C6.88694 6.62816 6.69307 7.45551 6.66361 8.36703L6.65133 8.74683L8.63641 6.94397L10.1587 8.51064L5.29726 12.9258L0.730469 8.22579Z"
      fill={props?.fill || "black"}
      {...props}
    />
    <path
      d="M28 17.681L26.3795 19.2393L24.2864 16.9584L24.3073 17.5628C24.3458 18.879 24.0998 20.0653 23.5695 21.1218C23.0391 22.1782 22.3689 23.096 21.5586 23.8752C20.0597 25.3166 18.3044 26.1393 16.2927 26.3433C14.281 26.5473 12.4074 26.0916 10.672 24.976L12.3735 23.3398C13.6379 23.9975 14.9713 24.2246 16.3734 24.0213C17.7756 23.8179 18.9966 23.2163 20.0364 22.2164C20.644 21.632 21.127 20.9464 21.4853 20.1595C21.8435 19.3726 22.0374 18.4966 22.0669 17.5314L22.0791 17.1293L20.0941 19.0382L18.5718 17.3794L23.4332 12.7045L28 17.681Z"
      fill={props?.fill || "black"}
      {...props}
    />
    <ellipse
      cx="22.4513"
      cy="6.31893"
      rx="4.14366"
      ry="3.92831"
      fill={props?.fill || "black"}
      {...props}
    />
    <rect
      y="15.3906"
      width="9.0407"
      height="7.85661"
      rx="2"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
