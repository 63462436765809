import styled from "styled-components";

export const SponsorPageContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 2vh 2vw 4vh 2vw;
  position: relative;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vh 0 2vh 0;
    padding: 1vh 4vw 2vh 4vw;
    width: 95%;
  }
`;

export const BackButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const BackIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  height: auto;
`;

export const BackButtonText = styled.text`
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  color: #737373;
  padding: 0 5px;
`;

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 0;

  @media (max-width: 480px) {
    width: 90%;
    padding: 1.5vh 0;
    flex-direction: column;
  }
`;

export const TitleAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 2vh;
  }
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  width: 2.4vw;
  height: auto;
  padding: 6px;
  border-radius: 100%;
  background-color: #edc5d3;

  @media (max-width: 480px) {
    width: 8vw;
    padding: 4px;
  }
`;

export const HeaderTitle = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1.2vw, 20px);
  padding: 0 5px;
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled.div`
  width: 0.9vw;
  height: auto;
  position: absolute;
  left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    width: 4vw;
    left: 2;
  }
`;

export const SearchInput = styled.input`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  border: none;
  outline: none;
  border-radius: 5px;
  color: #737373;
  background-color: #eeeeee;
  width: 10vw;
  height: 4vh;
  text-indent: 3vw;

  @media (max-width: 480px) {
    width: 60vw;
    text-indent: 10vw;
  }
`;

export const VerifyTick = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2vw;
  height: auto;
  margin-left: 10px;

  @media (max-width: 480px) {
    width: 4vw;
  }
`;

export const GenealogyMainDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4vh 0 2vh 0;
  border-bottom: 1px solid #d9d9d9;
`;

export const AlignSubGenealogy = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const GenealogySubDetails = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;
  border-left: 2px solid #a41e4d;
  padding: 2vh 0 2vh 1vw;

  @media (max-width: 480px) {
    padding: 2vh 0 2vh 2vw;
  }
`;

export const GenealogyDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4vh;

  @media (max-width: 480px) {
    padding-bottom: 2vh;
  }
`;

export const GenealogyWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GenealogyRank = styled.img`
  width: 5vw;
  height: auto;

  @media (max-width: 480px) {
    width: 20vw;
    margin: 0 2vw;
  }
`;

export const GenealogyHeader = styled.text`
  display: flex;
  flex-direction: column;
  padding: 0 2vw;
`;

export const GenealogyTitle = styled.text`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: clamp(16px, 1vw, 18px);
  padding-bottom: 5px;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const GenealogyLabel = styled.text`
  font-weight: 400;
  font-size: clamp(11px, 1vw, 13px);
  padding: 2px 0;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const GenealogyActives = styled.text`
  font-weight: 600;
  font-size: clamp(16px, 1vw, 18px);

  @media (max-width: 480px) {
    display: none;
  }
`;

export const GenealogySubLabel = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  padding: 2px 0;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const GenealogySubInfo = styled.text`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 14px);
  padding-left: 5px;

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;
