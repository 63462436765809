import { CustomIconProps } from "./types";

export const CrownIcon = (props: CustomIconProps) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0068 17.1115V19.499C21.0073 19.8273 20.9431 20.1525 20.8176 20.456C20.6922 20.7595 20.5082 21.0353 20.276 21.2676C20.0438 21.4999 19.768 21.6842 19.4644 21.8098C19.1609 21.9355 18.8355 22.0001 18.5069 22H5.49298C5.16443 22 4.83911 21.9353 4.53561 21.8096C4.23211 21.6838 3.95639 21.4996 3.72422 21.2673C3.49204 21.035 3.30796 20.7593 3.1825 20.4558C3.05705 20.1524 2.99268 19.8273 2.99307 19.499V17.1115L1.04959 8.49637C0.711833 6.73435 2.15944 5.83984 3.56851 6.89135L7.26283 9.65038C7.44397 9.78538 7.58858 9.75138 7.68815 9.55388L10.4182 4.11732C11.1668 2.62681 12.8336 2.62831 13.5811 4.11732L16.3117 9.55388C16.4118 9.75188 16.5554 9.78538 16.7371 9.65038L20.4314 6.89135C21.8404 5.83934 23.2885 6.73235 22.9503 8.49637L21.0068 17.111V17.1115ZM4.79445 15.9999H19.2054L20.7516 9.14887L17.9345 11.2524C16.7571 12.1319 15.1824 11.7639 14.5229 10.4509L11.9999 5.42734L9.47652 10.4509C8.81801 11.7624 7.24281 12.1319 6.06491 11.2524L3.24877 9.14937L4.79395 15.9999H4.79445ZM4.9946 19.499C4.9942 19.5646 5.0068 19.6297 5.03167 19.6905C5.05654 19.7512 5.09319 19.8065 5.1395 19.8531C5.18582 19.8996 5.2409 19.9366 5.30156 19.9618C5.36222 19.987 5.42728 20 5.49298 20H18.5069C18.5726 20.0001 18.6378 19.9872 18.6985 19.9621C18.7592 19.9369 18.8143 19.8999 18.8607 19.8534C18.907 19.8068 18.9437 19.7515 18.9685 19.6906C18.9933 19.6298 19.0058 19.5647 19.0053 19.499V18H4.9946V19.499Z"
      fill={props?.fill || "black"}
      {...props}
    />
  </svg>
);
