import { useState, useEffect } from "react";
import FooterBar from "../../components/footer/footer";
import SideBar from "../../components/side/sideBar";
import {
  AlignComponents,
  AlignColumnContainers,
  AlignPage,
  NavigationWrap,
  PageWrap,
  SideWrap,
  PageBody,
} from "../../components/reusable/pageStyle";

import HomeHeader from "../../components/mobile/mobileHeader";
import PageNav from "../../components/reusable/nav/pageNav";
import BannerCard from "../../components/reusable/banner/BannerCard";
import ShoppingCart from "../../components/cart/shoppingCart";
import CartShipping from "../../components/cart/cartShipping";

export default function CartPage() {
  const [activeSection, setActiveSection] = useState<string>("Dashboard");
  const [isMobile, setIsMobile] = useState(false);
  const [isShippingView, setIsShippingView] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);

      setActiveSection(isMobileView ? "Wallet" : "Dashboard");
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleCheckoutClick = () => {
    setIsShippingView(true);
  };

  const handleBackToCartClick = () => {
    setIsShippingView(false);
  };

  return (
    <PageBody>
      <AlignPage>
        <PageWrap>
          {isMobile && <HomeHeader title="Cart" />}

          {!isMobile && (
            <SideWrap>
              <SideBar isMobile={isMobile} activeSection={activeSection} />
            </SideWrap>
          )}

          <AlignComponents>
            {!isMobile && (
              <NavigationWrap>
                <PageNav />
                <BannerCard />
              </NavigationWrap>
            )}

            <AlignColumnContainers>
              {isMobile ? (
                isShippingView ? (
                  <CartShipping onBackToCart={handleBackToCartClick} />
                ) : (
                  <ShoppingCart onCheckoutClick={handleCheckoutClick} />
                )
              ) : (
                <>
                  <ShoppingCart />
                  <CartShipping />
                </>
              )}
            </AlignColumnContainers>
          </AlignComponents>
        </PageWrap>
      </AlignPage>

      {!isMobile && <FooterBar />}
      {isMobile && (
        <SideBar isMobile={isMobile} activeSection={activeSection} />
      )}
    </PageBody>
  );
}
