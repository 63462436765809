import {
  AlignButton,
  DetailInput,
  DetailLabel,
  DetailPart,
  DetailsGrid,
  DetailTitle,
  HolderInput,
  HolderName,
  BankDetailGrid,
  InformationContainer,
  UpdateButton,
  GenderWrap,
  GenderOption,
  GenderInput,
  GenderCircle,
  CircleInner,
  PhoneContainer,
  CountryCodeSelector,
  CountryCodeOption,
  CountryCodeWrap,
  DropdownIcon,
  MobileInput,
  TitleStar,
  PersonalDetailContainer,
  AddressDetailContainer,
  BankDetailContainer,
} from "./accountInfoStyle";
import DropdownMenu from "../reusable/dropdown";
import { useState } from "react";
import Icon from "../../core/Icons";

export default function AccountInfo() {
  const [gender, setGender] = useState("");

  const dropdownOptions = {
    state: ["State 1", "State 2", "State 3"],
    city: ["City 1", "City 2", "City 3"],
    country: ["Country 1", "Country 2", "Country 3"],
    bankAccountType: ["Savings", "Current"],
    bankName: ["Bank A", "Bank B", "Bank C"],
    bankBranch: ["Branch X", "Branch Y", "Branch Z"],
  };

  return (
    <InformationContainer>
      <PersonalDetailContainer>
        <DetailPart>
          <DetailTitle>Personal Details</DetailTitle>
          <DetailsGrid>
            <DetailLabel>
              Username
              <TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter username"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              Full Name
              <TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter full name"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              Email Address
              <TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter email address"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              IC Name
              <TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter IC name"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              Mobile Number
              <TitleStar>*</TitleStar>
              <PhoneContainer>
                <CountryCodeWrap>
                  <CountryCodeSelector>
                    <CountryCodeOption value="+60">+60</CountryCodeOption>
                    <CountryCodeOption value="+1">+1</CountryCodeOption>
                    <CountryCodeOption value="+44">+44</CountryCodeOption>
                    <CountryCodeOption value="+91">+91</CountryCodeOption>
                  </CountryCodeSelector>
                  <DropdownIcon>
                    <Icon name="arrow" fill="" />
                  </DropdownIcon>
                </CountryCodeWrap>
                <MobileInput type="tel" placeholder="Mobile Number" />
              </PhoneContainer>
            </DetailLabel>
            <DetailLabel>
              Gender
              <TitleStar>*</TitleStar>
              <GenderWrap>
                <GenderOption>
                  <GenderInput
                    name="gender"
                    value="male"
                    checked={gender === "male"}
                    onChange={() => setGender("male")}
                  />
                  <GenderCircle>
                    <CircleInner />
                  </GenderCircle>
                  Male
                </GenderOption>
                <GenderOption>
                  <GenderInput
                    name="gender"
                    value="female"
                    checked={gender === "female"}
                    onChange={() => setGender("female")}
                  />
                  <GenderCircle>
                    <CircleInner />
                  </GenderCircle>
                  Female
                </GenderOption>
              </GenderWrap>
            </DetailLabel>
          </DetailsGrid>
        </DetailPart>
      </PersonalDetailContainer>

      <AddressDetailContainer>
        <DetailPart>
          <DetailTitle>Address Details</DetailTitle>
          <DetailsGrid>
            <DetailLabel>
              Address Line 1<TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter Address Line 1"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              Address Line 2<TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter Address Line 2"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              State
              <TitleStar>*</TitleStar>
              <DropdownMenu
                placeholder="Select State"
                options={dropdownOptions.state}
              />
            </DetailLabel>
            <DetailLabel>
              City
              <TitleStar>*</TitleStar>
              <DropdownMenu
                placeholder="Select City"
                options={dropdownOptions.city}
              />
            </DetailLabel>
            <DetailLabel>
              Postal Code
              <TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter Postal Code"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              Country
              <TitleStar>*</TitleStar>
              <DropdownMenu
                placeholder="Select Country"
                options={dropdownOptions.country}
              />
            </DetailLabel>
          </DetailsGrid>
        </DetailPart>
      </AddressDetailContainer>

      <BankDetailContainer>
        <DetailPart>
          <DetailTitle>Bank Details</DetailTitle>
          <BankDetailGrid>
            <DetailLabel>
              Bank Account
              <TitleStar>*</TitleStar>
              <DetailInput placeholder="Enter Bank Account"></DetailInput>
            </DetailLabel>
            <DetailLabel>
              Bank Account Type
              <TitleStar>*</TitleStar>
              <DropdownMenu
                placeholder="Select Account Type"
                options={dropdownOptions.bankAccountType}
              />
            </DetailLabel>
            <DetailLabel>
              Bank Name
              <TitleStar>*</TitleStar>
              <DropdownMenu
                placeholder="Select Bank Name"
                options={dropdownOptions.bankName}
              />
            </DetailLabel>
            <DetailLabel>
              Bank Branch
              <TitleStar>*</TitleStar>
              <DropdownMenu
                placeholder="Select Bank Branch"
                options={dropdownOptions.bankBranch}
              />
            </DetailLabel>
          </BankDetailGrid>
          <HolderName>
            Bank Holder Name
            <TitleStar>*</TitleStar>
            <HolderInput placeholder="Enter Bank Holder Name"></HolderInput>
          </HolderName>
        </DetailPart>

        <AlignButton>
          <UpdateButton>Update</UpdateButton>
        </AlignButton>
      </BankDetailContainer>
    </InformationContainer>
  );
}
