import styled from "styled-components";

export const ContainerWrap = styled.div`
  width: 100%;
  font-family: "Inter", sans-serif;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
  }
`;

export const PageTitle = styled.div`
  visibility: hidden;
  height: 0;
  padding: 0;
  margin: 0;
  @media (max-width: 480px) {
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 16px;
    background-color: white;
    width: 100vw;
    height: 6vh;
    margin: 2px 0;
  }
`;

export const ProductContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 56vh;
  border-radius: 10px;
  margin-bottom: 15px;

  @media (max-width: 480px) {
    border-radius: 0;
    width: 100vw;
    height: calc(100% - 140px);
  }
`;

export const ContainerNavigation = styled.div`
  width: 95%;
  height: 25px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 480px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const ProductCategory = styled.button`
  font-weight: 400;
  font-size: clamp(14px, 1vw, 16px);
  color: #737373;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  padding-bottom: 5px;

  &:hover {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }

  &:focus {
    font-weight: 600;
    color: black;
    border-bottom: 4px solid #a41e4d;
  }
`;

export const ProductHeaderLabel = styled.div`
  width: 95%;
  border-radius: 10px;
  height: auto;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 2fr;
  align-items: center;
  padding: 10px 20px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr;
  }
`;

export const HeaderLabel = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;
  text-align: left;
`;

export const QuantityLabel = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductInfo = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;
  text-align: left;
`;

export const ProductName = styled.div`
  font-weight: 600;
  font-size: clamp(12px, 1vw, 14px);
  color: #a41e4d;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const ImageLabel = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const ImageDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const PvLabel = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  color: #737373;
  text-align: right;
`;

export const PvAmount = styled.div`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  text-align: right;
`;

export const ProductCheckbox = styled.input`
  width: 2vw;
  height: 3vh;

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
  }
`;

export const ProductDisplayBox = styled.div`
  width: 95%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 2fr;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;

  @media (max-width: 480px) {
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr;
  }
`;

export const QuantityInput = styled.input`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: auto;
  padding: 10px 0;
  background-color: #d9d9d9;
  border-radius: 5px;
  border: none;
  text-align: center;

  @media (max-width: 480px) {
    width: 50px;
  }
`;

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 10px 40px;
  background-color: white;
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 480px) {
    padding: 0 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    height: 80px;
    box-shadow: 0 -6px 6px -1px rgba(0, 0, 0, 0.2),
      0 -3px 4px -1px rgba(0, 0, 0, 0.1);
  }
`;

export const WrapTotal = styled.div`
  height: 80%;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 10px 30px;

  @media (max-width: 480px) {
    text-align: right;
  }
`;

export const TotalText = styled.text`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  text-align: left;
  @media (max-width: 480px) {
    padding-bottom: 5px;
  }
`;

export const TotalAmount = styled.text`
  font-weight: 600;
  font-size: clamp(18px, 1.2vw, 20px);
  color: #a41e4d;
`;

export const PvPlaceholder = styled.text`
  font-weight: 600;
  font-size: clamp(18px, 1.2vw, 20px);
  color: #a41e4d;
  margin-left: 6px;
`;

export const CheckoutButton = styled.button`
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  width: 9vw;
  padding: 15px 0;
  height: auto;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #a41e4d;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 160px;
    font-size: 14px;
  }
`;

export const PageNavigator = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

export const PrevButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9vw;
  height: auto;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  transform: rotate(180deg);

  &:disabled {
    fill: #aaa;
    cursor: default;
  }

  @media (max-width: 480px) {
    width: 12px;
  }
`;

export const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.9vw;
  height: auto;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;

  &:disabled {
    fill: #aaa;
    cursor: default;
  }

  @media (max-width: 480px) {
    width: 12px;
  }
`;

export const CurrentPageCircle = styled.div`
  font-weight: 400;
  font-size: 1vw;
  width: 30px;
  height: 30px;
  padding: 18px;
  margin: 0 1vw;
  border-radius: 50%;
  color: white;
  background-color: #a41e4d;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    padding: 10px;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
`;
