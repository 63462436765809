import React from "react";
import Icon from "../../../core/Icons";
import {
  ButtonArrowWrap,
  ButtonLabel,
  MobileOrderContainer,
  OrderButtons,
  OrderIconWrap,
  OrderProductHeader,
  OrderProductWrap,
  PageBody,
  SubButtonLabel,
  SubOrderButtons,
} from "./mobileOrderStyle";
import MobileNavigation from "../../../components/mobile/mobileNavigation";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../../components/mobile/mobileHeader";

export default function MobileOrderPage() {
  const navigate = useNavigate();

  return (
    <PageBody>
      <MobileHeader title="Order" />
      <MobileNavigation activeSection="Order" onLinkClick={() => {}} />{" "}
      <MobileOrderContainer>
        <OrderButtons onClick={() => navigate("/cart")}>
          <OrderIconWrap>
            <Icon name="cart" fill="" />
          </OrderIconWrap>
          <ButtonLabel>Shopping Cart</ButtonLabel>
          <ButtonArrowWrap>
            <Icon name="sidearrow" fill="" />
          </ButtonArrowWrap>
        </OrderButtons>

        <OrderProductWrap>
          <OrderProductHeader>
            <OrderIconWrap>
              <Icon name="box" fill="" />
            </OrderIconWrap>
            <ButtonLabel>Product</ButtonLabel>
          </OrderProductHeader>

          <SubOrderButtons onClick={() => navigate("/product")}>
            <SubButtonLabel>All Product</SubButtonLabel>
            <ButtonArrowWrap>
              <Icon name="sidearrow" fill="" />
            </ButtonArrowWrap>
          </SubOrderButtons>

          <SubOrderButtons onClick={() => navigate("/status")}>
            <SubButtonLabel>Order Status</SubButtonLabel>
            <ButtonArrowWrap>
              <Icon name="sidearrow" fill="" />
            </ButtonArrowWrap>
          </SubOrderButtons>
        </OrderProductWrap>

        <OrderButtons onClick={() => navigate("/history")}>
          <OrderIconWrap>
            <Icon name="history" fill="" />
          </OrderIconWrap>
          <ButtonLabel>History</ButtonLabel>
          <ButtonArrowWrap>
            <Icon name="sidearrow" fill="" />
          </ButtonArrowWrap>
        </OrderButtons>
      </MobileOrderContainer>
    </PageBody>
  );
}
